import { createReducer } from '@reduxjs/toolkit';
import { LapLocation, LapTotal } from 'store/lap/types';
import { resetLap, setLap, setLapCurrentCash } from 'store/lap/actions';

const initialState: {
    total: LapTotal | null;
    locations: LapLocation[] | null;
} = {
    total: null,
    locations: null,
};

export const lapReducer = createReducer(initialState, builder => {
    builder.addCase(setLap, (state, { payload }) => {
        return { ...state, ...payload };
    });
    builder.addCase(resetLap, () => {
        return { ...initialState };
    });
    builder.addCase(setLapCurrentCash, (state, { payload }) => {
        if (state.locations) {
            const location = state.locations.find(
                location => location.id === payload.locationId
            );
            if (location) {
                location.amounts = payload.amounts;
            }
        }
    });
});
