import { EditModal } from 'components/common/editModal';
import { Text } from 'components/text';
import { useNewLocation } from 'store/locations/selectors';
import { locationActions } from 'store/locations/slice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNewLocationMutation, useUpdateLocationMutation } from '../../../../api/locations/endpoints';

export const NewLocationModal = () => {
    const dispatch = useDispatch();
    const newLocation = useNewLocation();
    const [trigger, { isLoading, isSuccess, reset, error: errors }] = useUpdateLocationMutation();
    const [newTrigger, { isLoading: isNewLoading, isSuccess: isNewSuccess, reset: newReset, error: newError }] =
        useNewLocationMutation();

    useEffect(() => {
        if (isSuccess || isNewSuccess) {
            dispatch(locationActions.resetNewLocation());
            reset();
            newReset();
        }
    }, [isSuccess, dispatch, reset, isNewSuccess, newReset]);

    const handleSave = () => {
        if ('id' in newLocation && newLocation?.id) {
            trigger(newLocation);
        } else {
            newTrigger(newLocation);
        }
    };

    return (
        <EditModal
            title={<Text k={`New Location`} />}
            reset={locationActions.resetNewLocation}
            update={locationActions.updateNewLocation}
            //@ts-ignore
            interceptSave={handleSave}
            loading={isLoading || isNewLoading}
            //@ts-ignore
            errors={newError?.data?.errors || errors?.data?.errors }
            item={newLocation}
            formId={'new-location-form'}
            url={'/locations'}
            fieldIdPrefix={'location'}
            fields={[
                {
                    type: 'input',
                    key: 'title',
                    label: <Text k={`Location Title`} />,
                },
                {
                    type: 'input',
                    key: 'address',
                    label: <Text k={`Location Address`} />,
                },
                {
                    type: 'input',
                    key: 'contact_name',
                    label: <Text k={`Contact Name`} />,
                },
                {
                    type: 'input',
                    key: 'contact_email',
                    label: <Text k={`Contact Email`} />,
                    props: {
                        type: 'email',
                    },
                },
                {
                    type: 'input',
                    key: 'contact_phone',
                    label: <Text k={`Contact Phone`} />,
                    props: {
                        type: 'tel',
                    },
                },
                {
                    type: 'checkbox',
                    key: 'full_subsidy',
                    label: <Text k={`Full Subsidy`} />,
                },
                {
                    type: 'input',
                    key: 'subsidy',
                    label: <Text k={`Subsidy (€)`} />,
                    props: {
                        type: 'number',
                        step: 0.01,
                    },
                    // @ts-ignore
                    disabled: item => item.full_subsidy,
                },
                {
                    type: 'checkbox',
                    key: 'profit',
                    label: <Text k={`Profit`} />,
                },
                {
                    type: 'checkbox',
                    key: 'status',
                    label: <Text k={`Active`} />,
                },
            ]}
        />
    );
};
