import styles from './orderCardItem.module.css';
import { PriceTag } from 'components/common/priceTag/priceTag';
import { useTranslator } from 'hooks/useTranslator';
import { Text } from 'components/text';
import { useModifiers } from 'hooks/useModifiers';
import { usePriceFormatter } from 'hooks/useFormattedPrice';

export const OrderDishCard = ({
    dish,
    complex,
    quantity,
    price,
    tiny,
    stripped,
}) => {
    const translator = useTranslator();
    const mods = useModifiers(styles, 'orderCardItem', { tiny, stripped });
    const priceFormatter = usePriceFormatter();

    if (!dish)
        return (
            <div className={`${styles.orderCardItem} ${mods} shadedBg`}>
                <div className={styles.orderCardItem__header}>
                    <div>
                        <Text k={`Dish was removed`} />
                    </div>
                </div>
            </div>
        );
    const { title, type } = dish;
    return (
        <div className={`${styles.orderCardItem} ${mods} shadedBg`}>
            <div className={styles.orderCardItem__header}>
                <div>
                    {stripped ? (
                        <span>
                            <span
                                className={`${styles.orderCardItem__quantity} ${
                                    quantity > 1
                                        ? styles.orderCardItem__quantity_accent
                                        : ''
                                }`}
                            >
                                {quantity}
                            </span>{' '}
                            x{' '}
                        </span>
                    ) : (
                        ''
                    )}
                    {translator(title)}
                </div>

                {stripped ? (
                    <div className={styles.orderCardItem__headerPrice}>
                        &nbsp;{priceFormatter(quantity * price)}
                    </div>
                ) : null}
            </div>
            {type === 'complex' && complex ? (
                <ul className={styles.orderCardItem__list}>
                    {complex.map(({ dish, id, price }) => {
                        if (!dish) return '';
                        return (
                            <li
                                key={id}
                                className={styles.orderCardItem__listItem}
                            >
                                <div
                                    className={
                                        styles.orderCardItem__listItemTitle
                                    }
                                >
                                    {translator(dish.title)}
                                </div>
                                {!stripped ? (
                                    <div
                                        className={
                                            styles.orderCardItem__listItemPrice
                                        }
                                    >
                                        {price} €
                                    </div>
                                ) : null}
                            </li>
                        );
                    })}
                </ul>
            ) : (
                ''
            )}
            {!stripped ? (
                <div className={styles.orderCardItem__actions}>
                    <div className={styles.orderCardItem__counter}>
                        {quantity}
                    </div>
                    <PriceTag secondary price={price} />
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
