import { FunctionComponent, ReactNode, useMemo } from 'react';
import { useDishesByCategory } from 'hooks/useDishesByCategory';
import styles from './dishesByCategory.module.css';
import { useTranslator } from 'hooks/useTranslator';
import { Dish } from 'store/dishes/types';
import { NewMenuDish } from 'store/newMenu';
import { Category } from 'store/categories/types';
import { useGetDishesByCategoryQuery } from '../../../api/dishes/endpoints';
import { Loader } from 'components/common/loader';

const CategoryGroup: FunctionComponent<{
    category: Category;
    renderDish: (dish: Dish) => ReactNode;
    list: NewMenuDish[];
}> = ({ category, renderDish, list }) => {
    const translator = useTranslator();

    const { data, isFetching } = useGetDishesByCategoryQuery({ category: category.id, activeOnly: true });

    const dishes = useMemo(() => {
        if (!data) return null;
        return data.filter(dish => list.some(item => item.id === dish.id));
    }, [data, list]);

    return (
        <div key={category.id} className={styles.dishesByCategory__categoryBlock}>
            <div className={styles.dishesByCategory__categoryTitle}>{translator(category.title)}</div>
            <div className={styles.dishesByCategory__categoryGrid}>
                {isFetching || !dishes ? <Loader /> : dishes.map(renderDish)}
            </div>
        </div>
    );
};

export const DishesByCategory: FunctionComponent<{
    list: NewMenuDish[];
    renderDish: (dish: Dish) => ReactNode;
}> = ({ list, renderDish }) => {
    const dishesByCategory = useDishesByCategory(list);

    return (
        <>
            {dishesByCategory.map(({ category, dishes: categoryDishes }) => {
                return (
                    <CategoryGroup
                        category={category}
                        renderDish={renderDish}
                        list={categoryDishes}
                        key={category.id}
                    />
                );
            })}
        </>
    );
};
