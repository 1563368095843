import styles from './pageTitle.module.css';

export const PageTitle = ({ title, actions }) => {
    return (
        <div className={`${styles.pageTitle} shaded`}>
            <div>
                <h1 className={`h1 h1_noMargin`}>{title}</h1>
            </div>
            <div>{actions}</div>
        </div>
    );
};
