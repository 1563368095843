import styles from './editBalanceList.module.css';
import { useBalanceList, useEditedBalanceList } from 'store/balance/selectors';
import { DishesByCategory } from 'components/menu/dishesByCategory';
import { DishBalanceCard } from 'components/pages/editBalance/dishBalanceCard';
import { Button } from 'components/common/button';
import { Text } from 'components/text';
import { useApi } from 'hooks/useApi';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBalance, wipeEdited } from 'store/balance/actions';
import { useParams } from 'react-router-dom';

export const EditBalanceList = () => {
    const dispatch = useDispatch();
    const balanceList = useBalanceList();
    const editedBalanceList = useEditedBalanceList();
    const { menuId } = useParams();
    const [loading, setLoading] = useState(false);
    const saveBalance = useApi(`/dishlists/${menuId}/balance`, {
        method: 'post',
    });
    if (!balanceList) return null;

    const handleSave = async () => {
        setLoading(true);
        const balance = balanceList.map(balanceItem => {
            const editedItem = editedBalanceList.find(
                item => item.id == balanceItem.id
            );
            if (editedItem) return editedItem;
            return balanceItem;
        });
        try {
            const res = await saveBalance({
                body: JSON.stringify({ balance }),
            });
            if (res.ok) {
                const data = await res.json();
                dispatch(setBalance(data));
                dispatch(wipeEdited());
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    return (
        <div>
            <DishesByCategory
                list={balanceList}
                renderDish={dish => (
                    <DishBalanceCard key={dish.id} dish={dish} />
                )}
            />
            <div className={styles.editedBalanceList__action}>
                <Button
                    disabled={!editedBalanceList.length}
                    loading={loading}
                    onClick={handleSave}
                >
                    <Text k={`Save`} />
                </Button>
            </div>
        </div>
    );
};
