import { useCallback } from 'react';
import { useActiveLang, useRequestMode, useToken } from 'store/common/selectors';
import { constants } from '../constants';
import { setMaintenance, setToken } from 'store/common/actions';
import { useDispatch } from 'react-redux';
import { useActiveProvider } from 'store/providers/selectors';

export const useApi = (url, options) => {
    const dispatch = useDispatch();
    const token = useToken();
    const lang = useActiveLang();
    const requestMode = useRequestMode();
    const Authorization = `Bearer ${token}`;
    const providerId = useActiveProvider();

    return useCallback(
        async (additionalOptions = {}) => {
            const {
                headers = {},
                params = {},
                parsed,
                callback,
                route: defaultRoute = '',
                defaultContentType = false,
                ...rest
            } = options || {};
            const {
                route = defaultRoute,
                params: additionalParams = {},
                url: overrideUrl,
                ...restOfAdditionalOptions
            } = additionalOptions;

            const paramsObject = {
                ...params,
                ...additionalParams,
            };
            if (providerId) {
                paramsObject.provider_id = providerId;
            }
            if (lang) {
                paramsObject.lang = lang;
            }
            if (requestMode) {
                paramsObject[requestMode] = '';
            }
            const searchParams = new URLSearchParams(paramsObject);

            const defaultHeaders = {
                Authorization,
                Accept: 'application/json',
            };

            if (!defaultContentType) {
                defaultHeaders['Content-Type'] = 'application/json';
            }

            const res = await fetch(
                `${constants.apiPath}/app${overrideUrl || url}${route}?${searchParams.toString()}`,
                {
                    headers: {
                        ...defaultHeaders,
                        ...headers,
                    },
                    ...rest,
                    ...restOfAdditionalOptions,
                }
            );
            if (typeof callback === 'function') {
                callback();
            }
            if (res.status === 503) {
                dispatch(setMaintenance(true));
            } else {
                dispatch(setMaintenance(false));
            }
            if (res.status === 401) {
                dispatch(setToken(null));
            }
            if (res.status === 429) {
                return;
                // dispatch(setToken(null));
            }
            if (res.ok) {
                if (parsed) {
                    return await res.json();
                }
            }
            return res;
        },
        [options, providerId, lang, requestMode, Authorization, url, dispatch]
    );
};
