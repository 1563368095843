import { createReducer } from '@reduxjs/toolkit';
import { setMenus, setMenusUpdating } from 'store/menus/actions';

const initialState = {
    data: [],
    updating: false,
    loading: true,
};

export const menusReducer = createReducer(initialState, builder => {
    builder.addCase(setMenus, (state, { payload }) => {
        state.loading = false;
        state.updating = false;
        state.data = payload;
    });
    builder.addCase(setMenusUpdating, (state, { payload }) => {
        state.updating = payload;
    });
});
