import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocationType } from 'store/locations/types';

export type NewLocationType = Omit<
    LocationType,
    'lap' | 'lap_id' | 'lap_position' | 'id' | 'provider_id' | 'token' | 'unconfirmed'
> & { active?: boolean };

const initialState: {
    searchQuery: string,
    new: NewLocationType;
} = {
    searchQuery: '',
    new: {
        title: '',
        address: '',
        profit: true,
        contact_name: '',
        contact_email: '',
        contact_phone: '',
        full_subsidy: false,
        subsidy: 0,
        status: true,
    },
};

export const locationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        setNewLocation: (state, action: PayloadAction<NewLocationType>) => {
            state.new = action.payload;
        },
        updateNewLocation: (state, action: PayloadAction<Partial<NewLocationType>>) => {
            state.new = { ...state.new, ...action.payload };
        },
        resetNewLocation: state => {
            state.new = initialState.new;
        },
        setSearchQuery: (state, action: PayloadAction<string>) => {
            state.searchQuery = action.payload;
        }
    },
});


export const locationActions = locationsSlice.actions;
