import { createAction } from '@reduxjs/toolkit';

export const setMenus = createAction(`menus/SET_MENUS`, menus => {
    return {
        payload: menus,
    };
});

export const setMenusUpdating = createAction(
    'menus/SET_UPDATING',
    (status: boolean) => {
        return {
            payload: status,
        };
    }
);
