import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';
import { RootState } from 'store/index';
import { constants } from '../constants';

const rawBaseQuery = fetchBaseQuery({
    baseUrl: '',
});

export type ApiBaseQueryType = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

const dynamicBaseQuery: ApiBaseQueryType = async (args, api, extraOptions) => {
    const state = api.getState() as RootState;
    const token = state.common.token;
    const locale = state.common.lang;
    const provider = state.providers.active;
    const requestMode = state.common.requestMode;
    const baseUrl = constants.apiPath;

    const params: Record<string, any> = {};
    if (provider) {
        params.provider_id = provider;
    }
    if (locale) {
        params.lang = locale;
    }
    if (requestMode) {
        params[requestMode] = '';
    }

    const urlEnd = typeof args === 'string' ? args : args.url;

    const defaultHeaders: Record<string, string | undefined> = {
        Accept: 'application/json',
    };

    //@ts-ignore
    if (!args?.defaultHeaders) {
        defaultHeaders['Content-Type'] = 'application/json';
    }

    if (token) {
        defaultHeaders.Authorization = `Bearer ${token}`;
    }

    const base = new URL(baseUrl);

    const adjustedUrl = base.origin + (base.pathname !== '/' ? base.pathname : '') + urlEnd;

    const adjustedArgs =
        typeof args === 'string'
            ? { url: adjustedUrl, headers: { ...defaultHeaders }, params }
            : {
                  ...args,
                  params: { ...args.params, ...params },
                  body: args.body,
                  url: adjustedUrl,
                  headers: { ...defaultHeaders, ...args.headers },
              };

    return rawBaseQuery(adjustedArgs, api, extraOptions);
};

export const api = createApi({
    reducerPath: 'api',
    tagTypes: ['Dishlists', 'Dishes', 'Locations', 'Categories'],
    baseQuery: dynamicBaseQuery,
    endpoints: () => ({}),
});
