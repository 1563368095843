import { api } from '../api';
import { Paginatable } from '../types';
import { AllergenType } from './types';

const allergensApi = api.injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
        getAllergens: builder.query<Paginatable<AllergenType[]>, void>({
            query: () => {
                return {
                    url: '/app/allergens',
                };
            },
        }),
    }),
});

export const { useGetAllergensQuery } = allergensApi;
