import { useAppSelector } from 'store/index';
import { Dish } from 'store/dishes/types';

export const useBalance = () => {
    return useAppSelector(state => state.balance.balance);
};

export const useBalanceList = () => {
    return useAppSelector(state => state.balance.balance?.balance);
};

export const useEditedBalanceList = () => {
    return useAppSelector(state => state.balance.edited);
};

export const useDishBalanceById = (id: Dish['id']) => {
    return useAppSelector(state => {
        const balance = state.balance.balance?.balance;
        if (!balance) return null;
        const edited = state.balance.edited.find(item => item.id == id);
        if (edited) return edited;
        return balance.find(dishBalance => dishBalance.id == id) || null;
    });
};
