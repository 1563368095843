import styles from './radioGroup.module.css';
import { ErrorContainer } from 'components/common/errorContainer';
import { Checkbox } from 'components/common/checkbox';
import { ReactNode } from 'react';

export const RadioGroup = <
    Value extends string,
    T extends Record<string, any>
>({
    legend,
    label,
    errors,
    options = [],
    value,
    handleChange,
    id,
}: {
    legend?: string | ReactNode;
    label?: string | ReactNode;
    errors?: string[];
    options: ({ key: Value; label: string | ReactNode } & T)[];
    value: Value;
    handleChange: (
        value: Value,
        option: { key: Value; label: string | ReactNode } & T
    ) => void;
    id: string;
}) => {
    return (
        <fieldset className={styles.radioGroup}>
            <legend className={styles.radioGroup__legend}>
                {legend || label}
            </legend>
            <div>
                <div className={styles.radioGroup__grid}>
                    {options.map(option => {
                        const { key, label } = option;
                        return (
                            <Checkbox
                                id={`${id}-${key}`}
                                checked={value === key}
                                name={id}
                                type="radio"
                                onChange={() => handleChange(key, option)}
                                key={key}
                                center={option?.center}
                            >
                                {label}
                            </Checkbox>
                        );
                    })}
                </div>
                <ErrorContainer errors={errors} />
            </div>
        </fieldset>
    );
};
