import styles from './orderTable.module.css';
import { Text } from 'components/text';
import { PriceTag } from 'components/common/priceTag/priceTag';
import { ActionGroup } from 'components/common/actionGroup';
import { Bonus, Cash, CreditCard, Disabled, Paid } from 'components/icons';
import { OrderStatus } from 'constants/orderStatus';

export const OrderTable = ({ order, shiftOnly = false }) => {
    const { amount, subsidy, bonus, amount_to_pay, payment_type, status, shift } = order;

    const getPaymentIcon = () => {
        switch (payment_type) {
            case 'cash': {
                return <Cash />;
            }
            case 'card': {
                return <CreditCard />;
            }
            case 'subsidy': {
                return <Paid />;
            }
            case 'bonus': {
                return <Bonus />;
            }
            default: {
                return '';
            }
        }
    };

    const getPaymentText = () => {
        switch (payment_type) {
            case 'cash': {
                return (
                    <span>
                        <Text k={`Cash`} />
                    </span>
                );
            }
            case 'card': {
                return (
                    <span>
                        <Text k={`Card`} />
                    </span>
                );
            }
            case 'subsidy': {
                return (
                    <span>
                        <Text k={`Subsidy`} />
                    </span>
                );
            }
            case 'bonus': {
                return (
                    <span>
                        <Text k={`Bonus`} />
                    </span>
                );
            }
            default: {
                return '';
            }
        }
    };

    const getStatus = () => {
        switch (status) {
            case OrderStatus.accepted: {
                return (
                    <span className={`${styles.orderTable__status} ${styles.orderTable__status_accepted}`}>
                        <Text k={`Accepted`} />
                    </span>
                );
            }
            default: {
                return (
                    <span className={`${styles.orderTable__status} ${styles.orderTable__status_failed}`}>
                        <Text k={`Failed`} />
                    </span>
                );
            }
        }
    };

    return (
        <div className={styles.orderTable}>
            <table className={styles.orderTable__table}>
                <tbody>
                    {shift ? (
                        <tr>
                            <th className={styles.orderTable__header}>
                                <Text k={`Shift`} />
                            </th>
                            <td className={styles.orderTable__content}>{shift}</td>
                        </tr>
                    ) : null}
                    {!shiftOnly ? (
                        <>
                            <tr>
                                <th className={styles.orderTable__header}>
                                    <Text k={`Payment`} />
                                </th>
                                <td className={styles.orderTable__content}>
                                    <ActionGroup>
                                        {getPaymentIcon()}
                                        {getPaymentText()}
                                    </ActionGroup>
                                </td>
                            </tr>
                            <tr>
                                <th className={styles.orderTable__header}>
                                    <Text k={`Total`} />
                                </th>
                                <td className={styles.orderTable__content}>
                                    <PriceTag price={amount} secondary />
                                </td>
                            </tr>
                            {subsidy ? (
                                <tr>
                                    <th className={styles.orderTable__header}>
                                        <Text k={`Subsidy`} />
                                    </th>
                                    <td className={styles.orderTable__content}>
                                        <PriceTag price={subsidy} secondary />
                                    </td>
                                </tr>
                            ) : null}
                            {bonus ? (
                                <tr>
                                    <th className={styles.orderTable__header}>
                                        <Text k={`Bonus`} />
                                    </th>
                                    <td className={styles.orderTable__content}>
                                        {bonus ? <PriceTag price={bonus} secondary /> : <Disabled />}
                                    </td>
                                </tr>
                            ) : null}
                            <tr>
                                <th className={styles.orderTable__header}>
                                    <Text k={`Payed`} />
                                </th>
                                <td className={styles.orderTable__content}>
                                    <PriceTag price={amount_to_pay} />
                                </td>
                            </tr>
                            <tr>
                                <th className={styles.orderTable__header}>
                                    <Text k={`Status`} />
                                </th>
                                <td className={styles.orderTable__content}>{getStatus()}</td>
                            </tr>
                        </>
                    ) : null}
                </tbody>
            </table>
        </div>
    );
};
