import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Text } from 'components/text';
import { Button } from 'components/common/button';
import { useDispatch } from 'react-redux';
import { ActionGroup } from 'components/common/actionGroup';
import { LocationsTable } from 'components/pages/locations/locationsTable';
import { NewLocationModal } from 'components/pages/locations/newLocationModal';
import { LocationsFilterCard } from 'components/pages/locations/filterCard';
import { ErrorBoundary } from 'components/common/errorBoudry';
import { locationActions } from 'store/locations/slice';

export const LocationsPage = () => {
    const dispatch = useDispatch();

    return (
        <ErrorBoundary boundary="locations">
            <ContentGrid>
                <ContentBlock>
                    <h1 className={`h1 h1_noMargin`}>
                        <Text k={`Locations`} />
                    </h1>
                </ContentBlock>
                <ContentBlock>
                    <ContentCard tinyPadding>
                        <ActionGroup>
                            <Button
                                secondary
                                onClick={() => dispatch(locationActions.updateNewLocation({ active: true }))}
                            >
                                <Text k={`New Location`} />
                            </Button>
                        </ActionGroup>
                    </ContentCard>
                </ContentBlock>
                <ContentBlock>
                    <LocationsFilterCard />
                </ContentBlock>
                <ContentBlock>
                    <ContentCard>
                        <LocationsTable />
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
            <NewLocationModal />
        </ErrorBoundary>
    );
};
