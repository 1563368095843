import { useCart } from 'store/menu/selectors';
import { useMemo } from 'react';

export const useSubtotalPrice = () => {
    const cart = useCart();

    return useMemo(
        () =>
            cart.reduce((total, { price, quantity }) => {
                return total + price * quantity;
            }, 0),
        [cart]
    );
};
