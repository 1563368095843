import { api } from '../api';
import { DishlistResponseType, DishlistType } from 'store/menu/types';

const dishlistsApi = api.injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
        getDishlists: builder.query<{ dishlists: DishlistType[] }, void>({
            query: () => ({
                url: '/app/order/dishlists',
            }),
        }),
        getDishlist: builder.query<DishlistResponseType, { id: string }>({
            query: ({ id }) => ({
                url: `/app/order/create/${id}`,
            }),
        }),
    }),
});

export const { useGetDishlistsQuery, useGetDishlistQuery } = dishlistsApi;
