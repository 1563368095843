import { ErrorBoundary } from 'components/common/errorBoudry';
import { PublicCard } from 'components/layout/publicCard';
import { Text } from 'components/text';
import { usePublicMenuApi } from 'hooks/api/usePublicMenuApi';
import { PublicCategoryMenu } from 'components/pages/publicMenu/publicCategoryMenu';
import { Button } from 'components/common/button';
import { setAllExpanded } from 'store/expandedMenu/actions';
import { useDispatch } from 'react-redux';
import { useIsAllExpanded } from 'store/expandedMenu/selectors';

export const PublicMenuPage = () => {
    const dispatch = useDispatch();
    const allExpanded = useIsAllExpanded();
    usePublicMenuApi();

    return (
        <ErrorBoundary boundary={'public-menu'}>
            <PublicCard
                title={<Text k={"Today's menu"} />}
                action={
                    <Button
                        secondary
                        small
                        onClick={() => dispatch(setAllExpanded())}
                    >
                        {!allExpanded ? (
                            <Text k={`Expand all`} />
                        ) : (
                            <Text k={`Collapse all`} />
                        )}
                    </Button>
                }
                wide
            >
                <PublicCategoryMenu />
            </PublicCard>
        </ErrorBoundary>
    );
};
