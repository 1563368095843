import { useDispatch } from 'react-redux';
import { DataTable } from 'components/common/dataTable';
import { useProviders } from 'store/providers/selectors';
import { Disabled, Edit, TickFilled } from 'components/icons';
import { setNewProvider } from 'store/providers/actions';
import { Text } from 'components/text';

export const ProvidersTable = () => {
    const dispatch = useDispatch();
    const providers = useProviders();

    return (
        <DataTable
            fields={[
                {
                    label: <Text k={`Provider Title`} />,
                    key: 'title',
                },
                {
                    label: <Text k={`Phone`} />,
                    key: 'phone',
                    processor: value => <a href={`tel:${value}`}>{value}</a>,
                },
                {
                    label: <Text k={`Email`} />,
                    key: 'email',
                    processor: value => <a href={`mailto:${value}`}>{value}</a>,
                },
                {
                    label: <Text k={`Active`} />,
                    key: 'status',
                    processor: value => (value ? <TickFilled /> : <Disabled />),
                },
            ]}
            itemActions={[
                {
                    icon: <Edit />,
                    onClick: item =>
                        dispatch(
                            setNewProvider({
                                ...item,
                                active: true,
                            })
                        ),
                },
            ]}
            data={providers}
        />
    );
};
