import { useHistory } from 'store/history/selectors';
import { DataTable } from 'components/common/dataTable';
import { Text } from 'components/text';
import { View } from 'components/icons';
import { HistoryItem } from 'store/history/types';
import { usePriceFormatter } from 'hooks/useFormattedPrice';

export const HistoryTable = () => {
    const history = useHistory();
    const formatPrice = usePriceFormatter();

    if (!history) return null;
    return (
        <DataTable
            fields={[
                {
                    label: <Text k={`Menu`} />,
                    key: 'title',
                },
                {
                    label: <Text k={`Cash Total`} />,
                    key: 'cash_total',
                    processor: value => formatPrice(value),
                },
                {
                    label: <Text k={`Card Total`} />,
                    key: 'cards_total',
                    processor: value => formatPrice(value),
                },
                {
                    label: <Text k={`Subsidy Total`} />,
                    key: 'subsidy_total',
                    processor: value => formatPrice(value),
                },
                {
                    label: <Text k={`Total`} />,
                    key: 'total',
                    processor: value => <b>{formatPrice(value)}</b>,
                },
            ]}
            itemActions={[
                {
                    icon: <View />,
                    to: (item: HistoryItem) => `/history/${item.id}`,
                },
            ]}
            data={history}
        />
    );
};
