import { useDispatch } from 'react-redux';
import { useApiData } from '../useApiData';
import { useApi } from 'hooks/useApi';
import { setProviders } from 'store/providers/actions';

export const useProvidersApi = () => {
    useApiData('/providers', setProviders);
};

export const useProvidersRefresh = () => {
    const dispatch = useDispatch();
    const getProviders = useApi('/providers');

    return async () => {
        const res = await getProviders();
        dispatch(setProviders(await res.json()));
    };
};
