import { useState } from 'react';
import { FormItem } from 'components/common/formItem';
import { Input } from 'components/common/input';
import { Text } from 'components/text';
import { Button } from 'components/common/button';
import { useApi } from 'hooks/useApi';
import { InfoTag } from 'components/common/infoTag';
import { Form } from 'components/layout/form';

export const PasswordForm = () => {
    const save = useApi('/me/password/change', { method: 'post' });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const initialState = {
        old_password: '',
        password: '',
        password_confirmation: '',
    };

    const [fields, setFields] = useState(initialState);

    const disabled = !(
        fields.old_password &&
        fields.password &&
        fields.password_confirmation
    );

    const handleChange = event => {
        setFields({ ...fields, [event.target.name]: event.target.value });
        setSuccess(false);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const send = async () => {
            setLoading(true);
            const res = await save({ body: JSON.stringify(fields) });
            const data = await res.json();
            if (!data.errors) {
                setFields(initialState);
                setErrors({});
                setSuccess(true);
            } else {
                setErrors(data.errors);
            }
            setLoading(false);
        };
        send();
    };

    return (
        <Form onSubmit={handleSubmit}>
            {success ? (
                <InfoTag>
                    <Text k={`Password successfully changed`} />
                </InfoTag>
            ) : null}
            <Input
                id={'old_password'}
                value={fields.old_password}
                name={`old_password`}
                onChange={handleChange}
                errors={errors['old_password']}
                label={<Text k={`Current Password`} />}
                type="password"
                autoComplete="password"
            />
            <Input
                id={'password'}
                value={fields.password}
                name={`password`}
                onChange={handleChange}
                errors={errors['password']}
                label={<Text k={`New Password`} />}
                type="password"
                autoComplete="password"
            />
            <Input
                id={'password_confirmation'}
                value={fields.password_confirmation}
                name={`password_confirmation`}
                onChange={handleChange}
                errors={errors['password_confirmation']}
                label={<Text k={`Confirm New Password`} />}
                type="password"
                autoComplete="password"
            />
            <FormItem action>
                <Button disabled={disabled} type={`submit`} loading={loading}>
                    Update
                </Button>
            </FormItem>
        </Form>
    );
};
