import { useAppSelector } from 'store/index';

export const useKDSDishGroups = () => {
    return useAppSelector(({ kds }) => kds.data?.dishes);
};

export const useKDSInitialGroups = () => {
    return useAppSelector(({ kds }) => kds.data?.initials);
};

export const useKDSUpdating = () => {
    return useAppSelector(({ kds }) => kds.updating);
};
