import React, { FunctionComponent, MouseEventHandler, useCallback, useState } from 'react';
import styles from './dishCard.module.css';
import { useTranslator } from 'hooks/useTranslator';
import { useDispatch } from 'react-redux';
import { addDishToCart } from 'store/menu/actions';
import { Text } from 'components/text';
import { Close, Collapse, Expand } from 'components/icons';
import { Button } from 'components/common/button';
import { MenuComplexGroup } from 'components/pages/newOrder/menuComplexGroup';
import { PriceTag } from 'components/common/priceTag/priceTag';
import { useModifiers } from 'hooks/useModifiers';
import { QuantityTag } from 'components/common/quantityTag';
import { useDishAvailableQuantity } from 'store/menu/selectors';
import { Category } from 'store/categories/types';
import { MenuDish } from 'store/menu/types';
import { Picture } from 'components/common/picture';
import { Allergens } from 'components/pages/newOrder/allergens';

export const DishCard: FunctionComponent<{
    dish: MenuDish;
    category: Category;
    display?: boolean;
}> = ({
    dish: { id, title, price, summary, priceFrom, complex, type, qty, has_image, allergens },
    category,
    display,
}) => {
    const dispatch = useDispatch();
    const translator = useTranslator();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<{ [x: string]: string }>({});
    const [showedOnce, setShowedOnce] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const mods = useModifiers(styles, 'dishCard', {
        complex: type === 'complex',
        showImage,
        open,
        display,
    });
    const available = useDishAvailableQuantity(id, selected);

    const handleGroupSelect = useCallback((dishId: string, groupId: string) => {
        setSelected(selected => ({ ...selected, [groupId]: dishId }));
    }, []);

    const wipeSelected = useCallback(() => {
        const newSelected: { [x: string]: string } = {};
        Object.keys(selected).forEach(groupId => {
            if (complex) {
                const group = complex.find(group => groupId === group.id);
                if (group && !group.optional && group.dishes.length === 1) {
                    newSelected[groupId] = group.dishes[0].id;
                }
            }
        });
        setSelected(newSelected);
    }, [selected, complex]);

    if (qty !== null && qty <= 0) return null;

    const handleDishSelect = () => {
        if (display) return;
        if (type === 'complex') {
            setOpen(true);
        } else {
            dispatch(addDishToCart({ id, category, price: price }));
        }
    };

    const handleToggle = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        event.stopPropagation();
        setOpen(!open);
    };

    const isCompleted = () => {
        return !complex?.some(group => !group.optional && !selected[group.id]);
    };

    const getTotalPrice = () => {
        return (
            complex?.reduce((total, group) => {
                const dish = group.dishes.find(dish => dish.id === selected[group.id]);
                if (dish) {
                    return total + dish.price;
                }
                return total;
            }, 0) || 0
        );
    };

    const isAvailable = available === null || available > 0;

    const toggleImage: MouseEventHandler = event => {
        event.preventDefault();
        event.stopPropagation();
        setShowImage(!showImage);
        if (!showedOnce) {
            setShowedOnce(true);
        }
    };

    return (
        <div className={`${styles.dishCard} ${mods} shadedBg`} onClick={handleDishSelect}>
            <div className={styles.dishCard__image}>
                <div className={styles.dishCard__imageWrapper}>
                    <div className={styles.dishCard__imageContainer}>
                        <Button
                            className={styles.dishCard__imageClose}
                            icon={<Close />}
                            iconOnly
                            onClick={toggleImage}
                            disabled={!showImage}
                        />
                        {showedOnce ? (
                            <Picture
                                apiUrl={`/dishes/${id}/image`}
                                size={{ width: 760 }}
                                alt=""
                                onClick={toggleImage}
                                onLoad={() => console.log('image loaded')}
                                onError={() => console.log('image error')}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
            <div
                onClick={event => display && type === 'complex' && handleToggle(event)}
                className={styles.dishCard__preview}
            >
                <div className={styles.dishCard__titleGroup}>
                    {has_image ? (
                        <button className={styles.dishCard__imageButton} onClick={toggleImage} disabled={showImage}>
                            <span className={styles.dishCard__imageButtonContainer}>
                                <Picture
                                    apiUrl={`/dishes/${id}/image`}
                                    size={{ width: 64, height: 64 }}
                                    alt=""
                                    loading={'lazy'}
                                />
                            </span>
                        </button>
                    ) : null}
                    {!display ? (
                        <button
                            className={styles.dishCard__name}
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleDishSelect();
                            }}
                        >
                            {translator(title)} {type !== 'complex' ? <Allergens allergens={allergens} /> : null}
                        </button>
                    ) : (
                        <div className={styles.dishCard__name}>
                            {translator(title)} {type !== 'complex' ? <Allergens allergens={allergens} /> : null}
                        </div>
                    )}
                </div>
                <div className={styles.dishCard__action}>
                    <QuantityTag quantity={qty} />
                    <div className={styles.dishCard__price}>
                        {priceFrom ? <Text k={'from'} /> : ''} {priceFrom || price} €
                    </div>
                    {type === 'complex' ? (
                        !open ? (
                            <Button icon={<Expand />} iconOnly secondary small onClick={handleToggle} />
                        ) : (
                            <Button icon={<Collapse />} iconOnly secondary small onClick={handleToggle} />
                        )
                    ) : (
                        ''
                    )}
                </div>
            </div>
            {open ? (
                <div className={styles.dishCard__content}>
                    <div className={styles.dishCard__text}>
                        <p>{translator(summary)}</p>
                    </div>
                    {type === 'complex' && complex ? (
                        <>
                            <div className={styles.dishCard__groups}>
                                {complex.map(group => (
                                    <MenuComplexGroup
                                        key={group.id}
                                        group={group}
                                        complexId={id}
                                        handleSelect={handleGroupSelect}
                                        display={display}
                                        // @ts-ignore
                                        selected={selected[group.id]}
                                    />
                                ))}
                            </div>
                            {!display ? (
                                <div className={styles.dishCard__contentActions}>
                                    {isCompleted() ? (
                                        <PriceTag secondary price={getTotalPrice()} prefix={<Text k={`Total:`} />} />
                                    ) : (
                                        <div />
                                    )}
                                    <Button
                                        small
                                        disabled={!isCompleted() || !isAvailable}
                                        onClick={() => {
                                            dispatch(
                                                addDishToCart({
                                                    id,
                                                    category,
                                                    selected,
                                                    type,
                                                    price: getTotalPrice(),
                                                })
                                            );
                                            wipeSelected();
                                        }}
                                    >
                                        <Text k={`Add To Order`} />
                                    </Button>
                                </div>
                            ) : null}
                        </>
                    ) : (
                        ''
                    )}
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
