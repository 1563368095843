import { RESET_USER_FIELDS, SET_USER, SET_USER_FIELD } from './constants';
import { UserType } from 'store/user/types';

export const setUser = (payload: UserType) => {
    return {
        type: SET_USER,
        payload,
    };
};

export const setUserField = (key: string, value: any) => {
    return {
        type: SET_USER_FIELD,
        payload: {
            key,
            value,
        },
    };
};

export const resetUserFields = () => {
    return {
        type: RESET_USER_FIELDS,
    };
};
