import styles from './pagination.module.css';
import { ActionGroup } from 'components/common/actionGroup';
import { useLocation } from 'react-router-dom';
import { useSearchParam } from 'hooks/useSearchParam';
import { Button } from 'components/common/button';
import { Left, Right } from 'components/icons';
import { FunctionComponent } from 'react';

interface PaginationProps {
    data: any[];
    perPage: number;
}

export const Pagination: FunctionComponent<PaginationProps> = ({
    data,
    perPage,
}) => {
    const location = useLocation();
    const page = useSearchParam('page');
    const pageNumber = page ? parseInt(page) : 1;
    const cap = Math.ceil(data.length / perPage);
    if (cap === 1) return null;

    const boundPage = (number: number) => {
        if (number <= 0) return 1;
        if (number >= cap) return cap;
        return number;
    };

    const getLink = (page: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', page.toString());
        return `${location.pathname}?${searchParams.toString()}`;
    };

    const getPages = () => {
        const pages = [];
        for (let i = 1; i <= cap; i++) {
            pages.push(
                <Button
                    key={i}
                    small
                    to={getLink(boundPage(i))}
                    secondary={i !== pageNumber}
                >
                    {i}
                </Button>
            );
        }
        return pages;
    };

    return (
        <div className={styles.pagination}>
            <ActionGroup center>
                <Button
                    to={getLink(boundPage(pageNumber - 1))}
                    iconOnly
                    icon={<Left />}
                    secondary
                    disabled={pageNumber <= 1}
                    small
                />
                {getPages()}
                <Button
                    to={getLink(boundPage(pageNumber + 1))}
                    iconOnly
                    icon={<Right />}
                    secondary
                    disabled={pageNumber >= cap}
                    small
                />
            </ActionGroup>
        </div>
    );
};
