import React, { FunctionComponent } from 'react';
import { Text } from 'components/text';
import { useTranslator } from 'hooks/useTranslator';
import styles from './allergens.module.css';
import { useGetAllergensQuery } from '../../../../api/allergens/endpoints';

export const Allergens: FunctionComponent<{
    allergens: string[];
}> = ({ allergens }) => {
    const translator = useTranslator();
    const { data: allergensList } = useGetAllergensQuery();

    if (!allergensList || !allergens.length) return null;
    return (
        <span className={styles.allergens}>
            (
            <Text k={'Allergens'} />
            {': '}
            {allergens.reduce((acc, allergenId, currentIndex) => {
                const allergen = allergensList.data.find(item => item.id === allergenId);

                if (!allergen) return acc;
                if (currentIndex === allergens.length - 1) {
                    return acc + translator(allergen.title);
                }
                return acc + translator(allergen.title) + ', ';
            }, '')}
            )
        </span>
    );
};
