import styles from './layout.module.css';
import { Nav } from '../nav';
import { Header } from '../header';
import { Route, Navigate, Routes } from 'react-router-dom';
import { PrivacyPage } from 'pages/privacy';
import { ProfilePage } from 'pages/profile';
import { useProfileApi } from 'hooks/api/useProfileApi';
import { useNavApi } from 'hooks/api/useNavApi';
import { NewOrderPage } from 'pages/newOrder';
import { CategoriesPage } from 'pages/categories';
import { DishesPage } from 'pages/dishes';
import { LocationsPage } from 'pages/locations';
import { useIsLoading, useToken } from 'store/common/selectors';
import { OrderSuccessPage } from 'pages/orderSuccess';
import { ProvidersPage } from 'pages/providers';
import { useProvidersApi } from 'hooks/api/useProvidersApi';
import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Loader } from 'components/common/loader';
import { HistoryPage } from 'pages/history';
import { MenuHistoryPage } from 'pages/menuHistory';
import { OrderHistoryPage } from 'pages/orderHistory';
import { OrderFailPage } from 'pages/orderFail';
import { TeamPage } from 'pages/team';
import { TermsPage } from 'pages/terms';
import { NewMenuPage } from 'pages/newMenu';
import { MenusPage } from 'pages/menus';
import { OrdersPage } from 'pages/orders';
import { OrdersByMenuPage } from 'pages/ordersByMenu';
import { EditBalance } from 'pages/editBalance';
import { KDSPage } from 'pages/kds';
import { LapsPage } from 'pages/laps';
import { MenuLapsPage } from 'pages/menu-laps';
import { MenuLapPage } from 'pages/menu-lap';
import { ErrorBoundary } from 'components/common/errorBoudry';
import { Logo } from 'components/layout/logo';
import { OrderResultPage } from 'pages/orderResult';

export const Layout = () => {
    useProfileApi();
    useNavApi();
    useProvidersApi();
    const isLoading = useIsLoading();
    const token = useToken();

    if (!token) return <Navigate to={'/login'} />;
    return (
        <div className={styles.layout}>
            <div className={styles.layout__logoContainer}>
                <Logo />
            </div>
            <div className={styles.layout__header}>
                <ErrorBoundary boundary="header">
                    <Header />
                </ErrorBoundary>
            </div>
            <ErrorBoundary boundary="nav">
                <Nav className={styles.layout__nav} />
            </ErrorBoundary>
            <div className={styles.layout__content}>
                <ErrorBoundary boundary="content">
                    {!isLoading ? (
                        <Routes>
                            <Route path={'privacy'} element={<PrivacyPage />} />
                            <Route path={'terms-and-conditions'} element={<TermsPage />} />
                            <Route path={'profile'} element={<ProfilePage />} />
                            <Route path={'categories'} element={<CategoriesPage />} />
                            <Route path={'dishes'} element={<DishesPage />} />
                            <Route path={'locations'} element={<LocationsPage />} />
                            <Route path={'providers'} element={<ProvidersPage />} />
                            <Route path={'my-team'} element={<TeamPage />} />
                            <Route path={'order'}>
                                <Route index element={<NewOrderPage />} />
                                <Route path={'result'} element={<OrderResultPage />} />
                                <Route path={'success'} element={<OrderSuccessPage />} />
                                <Route path={'fail'} element={<OrderFailPage />} />
                                <Route path={':orderId'} element={<OrderHistoryPage />} />
                            </Route>
                            <Route path={'history'}>
                                <Route index element={<HistoryPage />} />
                                <Route path={':menuId'} element={<MenuHistoryPage />} />
                            </Route>
                            <Route path={'menu'}>
                                <Route index element={<MenusPage />} />
                                <Route path={'new'} element={<NewMenuPage />} />
                                <Route path={':menuId'}>
                                    <Route index element={<NewMenuPage />} />
                                    <Route path={'balance'} element={<EditBalance />} />
                                </Route>
                            </Route>
                            <Route path={'kds/:menuId'} element={<KDSPage />} />
                            <Route path={'laps'} element={<LapsPage />} />
                            <Route path={'menu-laps/:menuId'}>
                                <Route index element={<MenuLapsPage />} />
                                <Route path={':lapId'} element={<MenuLapPage />} />
                            </Route>
                            <Route path={'orders'}>
                                <Route index element={<OrdersPage />} />
                                <Route path={':menuId'} element={<OrdersByMenuPage />} />
                            </Route>
                            <Route path={'*'} element={<NewOrderPage />} />
                        </Routes>
                    ) : (
                        <ContentGrid>
                            <ContentBlock>
                                <ContentCard>
                                    <Loader />
                                </ContentCard>
                            </ContentBlock>
                        </ContentGrid>
                    )}
                </ErrorBoundary>
            </div>
        </div>
    );
};
