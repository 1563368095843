import styles from './kds.module.css';
import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { FunctionComponent, useEffect } from 'react';
import { useKDSApi, useKDSRefresh } from 'hooks/api/useKDSApi';
import { useKDSDishGroups, useKDSInitialGroups } from 'store/kds/selectors';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Loader } from 'components/common/loader';
import { KDSGrid } from 'components/pages/kds/kdsGrid';
import { ErrorBoundary } from 'components/common/errorBoudry';
import { useParams } from 'react-router-dom';

export const KDSPage: FunctionComponent = () => {
    const { menuId = '' } = useParams();
    useKDSApi(menuId);
    const refreshKDS = useKDSRefresh(menuId);
    const dishGroups = useKDSDishGroups();
    const initialsGroups = useKDSInitialGroups();

    useEffect(() => {
        let interval: ReturnType<typeof setInterval>;
        const ticker = () => {
            refreshKDS();
        };
        interval = setInterval(ticker, 10000);
        return () => {
            clearInterval(interval);
        };
    }, [refreshKDS]);

    return (
        <ErrorBoundary boundary="kds">
            <ContentGrid className={styles.kds}>
                <ContentBlock className={styles.kds__gridContainer}>
                    <ContentCard
                        className={styles.kds__gridContainer}
                        fixedHeight
                        fixedContentHeight
                    >
                        {dishGroups && initialsGroups ? (
                            <KDSGrid />
                        ) : (
                            <Loader />
                        )}
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
        </ErrorBoundary>
    );
};
