import styles from './priceTag.module.css';
import { useModifiers } from 'hooks/useModifiers';
import { usePriceFormatter } from 'hooks/useFormattedPrice';
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react';

interface PriceTagProps extends PropsWithChildren {
    price?: number;
    old?: boolean;
    secondary?: boolean;
    danger?: boolean;
    prefix?: string | ReactNode;
    bold?: boolean;
    noPaddingVertical?: boolean;
}

export const PriceTag: FunctionComponent<PriceTagProps> = ({
    price,
    old,
    secondary,
    danger,
    prefix,
    noPaddingVertical,
    bold,
    children,
}) => {
    const mods = useModifiers(styles, 'priceTag', {
        old,
        secondary,
        noPaddingVertical,
        danger,
        bold,
    });
    const priceFormatter = usePriceFormatter();

    const getPrice = () => {
        if (children) return children;
        if (price || price === 0) return priceFormatter(price);
        return '';
    };

    return (
        <span className={`${styles.priceTag} ${mods}`}>
            {prefix ? prefix : ''} <span className={styles.priceTag__price}>&nbsp;{getPrice()}</span>
        </span>
    );
};
