import { useEffect, useState } from 'react';
import styles from './newMenu.module.css';
import { Input } from 'components/common/input';
import { Text } from 'components/text';
import { updateNewMenu } from 'store/newMenu/actions';
import { useNewMenu } from 'store/newMenu/selectors';
import { useDispatch } from 'react-redux';
import { Checkbox } from 'components/common/checkbox';
import { Button } from 'components/common/button';
import { useApi } from 'hooks/useApi';
import { Navigate } from 'react-router-dom';
import { ActionGroup } from 'components/common/actionGroup';

export const Form = () => {
    const dispatch = useDispatch();
    const newMenu = useNewMenu();
    const { from, to, title, status, selectedDishes, id } = newMenu;
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState('');
    const submitMenu = useApi('/dishlists', {
        method: id ? 'put' : 'post',
        route: id ? `/${id}` : '',
    });

    const handleSubmit = async (event, editAfterSave) => {
        event.preventDefault();
        try {
            setLoading(true);
            const res = await submitMenu({
                body: JSON.stringify({
                    from,
                    to,
                    title,
                    status,
                    selectedDishes,
                }),
            });
            if (res.ok) {
                const menu = await res.json();
                const menuId = menu.id;
                dispatch(
                    updateNewMenu({
                        id: menuId,
                    })
                );
                if (!editAfterSave) {
                    window.history.replaceState('', '', `/menu/${menuId}`);
                } else {
                    setRedirect(`/menu/${menuId}/balance`);
                }
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (new Date(from) > new Date(to) || !to) {
            dispatch(
                updateNewMenu({
                    to: from,
                })
            );
        }
    }, [from, to, dispatch]);

    if (redirect) return <Navigate to={redirect} />;
    return (
        <form className={styles.newMenu__row} onSubmit={handleSubmit}>
            <div className={styles.newMenu__field}>
                <Input
                    label={<Text k={`Title`} />}
                    id={'new-menu-title'}
                    required
                    value={title}
                    onChange={event =>
                        dispatch(
                            updateNewMenu({
                                title: event.target.value,
                            })
                        )
                    }
                />
            </div>
            <div className={styles.newMenu__field_small}>
                <Input
                    label={<Text k={`From`} />}
                    id={'new-menu-from'}
                    type="datetime-local"
                    required
                    value={from}
                    onChange={event =>
                        dispatch(
                            updateNewMenu({
                                from: event.target.value,
                            })
                        )
                    }
                />
            </div>
            <div className={styles.newMenu__field_small}>
                <Input
                    label={<Text k={`To`} />}
                    id={'new-menu-to'}
                    type="datetime-local"
                    required
                    min={from}
                    value={to}
                    onChange={event =>
                        dispatch(
                            updateNewMenu({
                                to: event.target.value,
                            })
                        )
                    }
                />
            </div>
            <div className={styles.newMenu__field_small}>
                <Checkbox
                    label={<Text k={`Active`} />}
                    id={'new-menu-status'}
                    checked={status}
                    onChange={event =>
                        dispatch(
                            updateNewMenu({
                                status: event.target.checked,
                            })
                        )
                    }
                />
            </div>
            <div
                className={`${styles.newMenu__field_small} ${styles.newMenu__field_action}`}
            >
                <ActionGroup>
                    <Button
                        loading={loading}
                        secondary
                        onClick={event => handleSubmit(event, true)}
                    >
                        <Text k={`Save and Edit Balance`} />
                    </Button>
                    <Button type="submit" loading={loading}>
                        <Text k={`Save`} />
                    </Button>
                </ActionGroup>
            </div>
        </form>
    );
};
