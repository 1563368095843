import { useApiData } from 'hooks/useApiData';
import { resetNewMenu, setNewMenu } from 'store/newMenu/actions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const useMenuByIdApi = (menuId: number | string) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(resetNewMenu());
    }, [dispatch]);
    useApiData(`/dishlists/${menuId}`, setNewMenu);
};
