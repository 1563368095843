import { useAppSelector } from 'store/index';

export const useMenus = () => {
    return useAppSelector(({ menus: { data } }) => data);
};

export const useMenusLoading = () => {
    return useAppSelector(({ menus: { loading } }) => loading);
};

export const useMenusUpdating = () => {
    return useAppSelector(({ menus: { updating } }) => updating);
};
