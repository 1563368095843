import { useDispatch } from 'react-redux';
import { useApi } from './useApi';
import { useEffect } from 'react';

export const useApiData = (url, setter, options = {}) => {
    const dispatch = useDispatch();
    const request = useApi(url, options);

    useEffect(() => {
        const requestData = async () => {
            const res = await request();
            if (res) {
                const data = await res.json();
                dispatch(setter(data));
            }
        };
        requestData();
    }, []);
};
