import { ActionGroup } from 'components/common/actionGroup';
import { Input } from 'components/common/input';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'store/dictionary/selectors';
import { FilterCard } from 'components/common/filterCard';
import { locationActions } from 'store/locations/slice';
import { useLocationsSearchQuery } from 'store/locations/selectors';

export const LocationsFilterCard = () => {
    const dispatch = useDispatch();
    const searchQuery = useLocationsSearchQuery();
    const filterPlaceholder = useTranslation(`Filter...`);

    return (
        <FilterCard
            tinyPadding
            isSet={searchQuery}
            onReset={() => {
                dispatch(locationActions.setSearchQuery(''));
            }}
        >
            <div>
                <ActionGroup>
                    <Input
                        id={'dishes-search'}
                        placeholder={filterPlaceholder}
                        value={searchQuery}
                        onChange={event => dispatch(locationActions.setSearchQuery(event.target.value))}
                    />
                </ActionGroup>
            </div>
        </FilterCard>
    );
};
