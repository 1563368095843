import { ContentCard } from '../../../layout/contentCard/contentCard';
import { Text } from '../../../text';
import { FormItem } from '../../../common/formItem';
import { Input } from '../../../common/input';
import { resetUserFields, setUser, setUserField } from 'store/user/actions';
import { Button } from '../../../common/button';
import {
    useUserField,
    useUserFieldChanges,
    useUserFieldEdited,
} from 'store/user/selectors';
import { useDispatch } from 'react-redux';
import { useApi } from 'hooks/useApi';
import { Form } from 'components/layout/form';
import { Checkbox } from 'components/common/checkbox';

export const BasicInfoCard = () => {
    const email = useUserField('email');
    const firstName = useUserField('first_name');
    const firstNameEdited = useUserFieldEdited('first_name');
    const phone = useUserField('phone');
    const phoneEdited = useUserFieldEdited('phone');
    const remindToMakeOrder = useUserField('remind_to_make_order');
    const remindToMakeOrderEdited = useUserFieldEdited('remind_to_make_order');
    const notifyOnDelivery = useUserField('notify_on_delivery');
    const notifyOnDeliveryEdited = useUserFieldEdited('notify_on_delivery');
    const infoEnabled =
        firstNameEdited ||
        phoneEdited ||
        remindToMakeOrderEdited ||
        notifyOnDeliveryEdited;
    const changes = useUserFieldChanges();
    const save = useApi('/me', { method: 'post' });

    const dispatch = useDispatch();

    const handleSubmit = e => {
        e.preventDefault();
        const send = async () => {
            const res = await save({ body: JSON.stringify(changes) });
            const userData = await res.json();
            dispatch(setUser(userData));
            dispatch(resetUserFields());
        };
        send();
    };

    if (!email) {
        return <ContentCard title={<Text k={`Basic Information`} />} />;
    }
    return (
        <ContentCard title={<Text k={`Information`} />}>
            <Form onSubmit={handleSubmit}>
                <Input
                    id={'email'}
                    label={<Text k={`Email`} />}
                    type="email"
                    value={email}
                    disabled
                    onChange={e =>
                        dispatch(setUserField('email', e.target.value))
                    }
                    autoComplete="email"
                    inputMode="email"
                />
                <Input
                    id={'first_name'}
                    label={<Text k={`Name`} />}
                    type="text"
                    autoComplete="name"
                    value={firstName}
                    required
                    onChange={e =>
                        dispatch(setUserField('first_name', e.target.value))
                    }
                />
                <Input
                    id={'phone'}
                    label={<Text k={`Phone Number`} />}
                    type="tel"
                    autoComplete="tel"
                    inputMode={'tel'}
                    value={phone}
                    onChange={e =>
                        dispatch(setUserField('phone', e.target.value))
                    }
                />
                <Checkbox
                    id={'new_orders_notification'}
                    checked={remindToMakeOrder}
                    onChange={event =>
                        dispatch(
                            setUserField(
                                'remind_to_make_order',
                                event.target.checked
                            )
                        )
                    }
                >
                    <Text k={`Remind to make order`} />
                </Checkbox>
                <Checkbox
                    id={'new_orders_delivery'}
                    checked={notifyOnDelivery}
                    onChange={event =>
                        dispatch(
                            setUserField(
                                'notify_on_delivery',
                                event.target.checked
                            )
                        )
                    }
                >
                    <Text k={`Notify on delivery`} />
                </Checkbox>
                <FormItem action>
                    <Button
                        secondary
                        disabled={!infoEnabled}
                        onClick={() => dispatch(resetUserFields())}
                    >
                        Cancel
                    </Button>
                    <Button type={'submit'} disabled={!infoEnabled}>
                        Save
                    </Button>
                </FormItem>
            </Form>
        </ContentCard>
    );
};
