import { FunctionComponent, PropsWithChildren, ReactNode, useEffect } from 'react';
import styles from './modal.module.css';
import { Close } from 'components/icons';
import { Button } from 'components/common/button';
import { ActionGroup } from 'components/common/actionGroup';
import { useModifiers } from 'hooks/useModifiers';

export interface ModalProps extends PropsWithChildren {
    title?: string | ReactNode;
    onClose: () => void;
    actions?: any[];
    active: boolean;
    noScroll?: boolean;
}

export const Modal: FunctionComponent<ModalProps> = ({
    title,
    onClose = () => {},
    actions = [],
    children,
    active,
    noScroll,
}) => {
    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        if (active) {
            document.addEventListener('keydown', handleEscape);
        } else {
            document.removeEventListener('keydown', handleEscape);
        }
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onClose, active]);

    const mods = useModifiers(styles, 'modal', { noScroll });

    return (
        <div className={`${styles.modal__wrapper} ${active ? styles.modal__wrapper_active : ''}`}>
            <div className={styles.modal__curtain} onClick={onClose} />
            <div className={styles.modal__container}>
                <div className={`${styles.modal} ${mods}`}>
                    <Button
                        small
                        secondary
                        iconOnly
                        icon={<Close />}
                        className={styles.modal__close}
                        onClick={onClose}
                    />
                    <div className={styles.modal__content}>
                        <h2 className={`h3 ${styles.modal__title}`}>{title}</h2>
                        <div className={styles.modal__body}>{children}</div>
                    </div>
                    <div className={styles.modal__actions}>
                        <ActionGroup>
                            {actions.map(({ secondary, label, action, ...rest }, index) => {
                                let onClick;
                                if (action) {
                                    switch (action) {
                                        case 'cancel': {
                                            onClick = onClose;
                                            break;
                                        }
                                        default: {
                                            onClick = action;
                                            break;
                                        }
                                    }
                                }

                                return (
                                    <Button key={index} secondary={secondary} onClick={onClick} {...rest}>
                                        {label}
                                    </Button>
                                );
                            })}
                        </ActionGroup>
                    </div>
                </div>
            </div>
        </div>
    );
};
