import styles from './listCard.module.css';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { usePriceFormatter } from 'hooks/useFormattedPrice';
import { FunctionComponent, ReactNode } from 'react';

export const ListCard: FunctionComponent<{
    data: { value: string | number; label: string | ReactNode }[];
}> = ({ data }) => {
    const priceFormatter = usePriceFormatter();

    return (
        <ContentCard tinyPadding>
            {data.map((item, index) => (
                <dl className={styles.listCard} key={index}>
                    <div className={styles.listCard__item}>
                        <dt>{item.label}</dt>
                        <dd>
                            <b>{priceFormatter(item.value)}</b>
                        </dd>
                    </div>
                </dl>
            ))}
        </ContentCard>
    );
};
