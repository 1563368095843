import styles from './formItem.module.css';
import { useModifiers } from 'hooks/useModifiers';
import { FunctionComponent, HTMLAttributes } from 'react';

interface FormItemProps extends HTMLAttributes<HTMLDivElement> {
    action?: boolean;
}

export const FormItem: FunctionComponent<FormItemProps> = ({
    children,
    className = '',
    action,
    ...rest
}) => {
    const mods = useModifiers(styles, 'formItem', { action });

    return (
        <div className={`${styles.formItem} ${mods} ${className}`} {...rest}>
            {children}
        </div>
    );
};
