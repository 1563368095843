import { createReducer } from '@reduxjs/toolkit';
import { MenuCategory } from 'store/menu/types';
import { setPublicMenu } from 'store/publicMenu/actions';

export type PublicMenuReducer = {
    dishlist: {
        id: string;
        title: string;
        from: string;
        to: string;
        status: boolean;
        provider_id: string;
        categories: MenuCategory[];
    } | null;
};

const initialState: PublicMenuReducer = {
    dishlist: null,
};

export const publicMenuReducer = createReducer(initialState, builder => {
    builder.addCase(setPublicMenu, (state, { payload }) => {
        state.dishlist = payload;
    });
});
