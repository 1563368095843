import { useMenuUserData } from 'store/menu/selectors';
import { useSubtotalPrice } from 'hooks/useSubtotalPrice';

export const useTotalPrice = () => {
    let subtotalSum = useSubtotalPrice();
    const userData = useMenuUserData();
    if (userData && userData.full_subsidy) {
        return 0;
    }

    if (!userData) return subtotalSum;

    const { subsidy, bonus } = userData;
    if (bonus) {
        subtotalSum -= bonus;
    }
    if (subsidy) {
        subtotalSum -= subsidy;
    }
    return subtotalSum <= 0 ? 0 : subtotalSum;
};
