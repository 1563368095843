import { useApiData } from 'hooks/useApiData';
import { setBalance, wipeBalance } from 'store/balance/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useBalanceApi = (menuId: number | string) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(wipeBalance());
    }, [dispatch]);
    useApiData(`/dishlists/${menuId}/balance`, setBalance);
};
