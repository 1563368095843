import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { useHistoryApi } from 'hooks/api/useHistoryApi';
import { Loader } from 'components/common/loader';
import { useMenuHistory } from 'store/menuHistory/selectors';
import { setMenuHistory } from 'store/menuHistory/actions';
import { MenuHistoryTitle } from 'components/pages/menuHistory/title';
import { OrderCard } from 'components/order/orderCard';
import { OrderGrid } from 'components/order/orderGrid';
import { ErrorBoundary } from 'components/common/errorBoudry';
import { useParams } from "react-router-dom";

export const MenuHistoryPage = () => {
    const { menuId } = useParams();
    useHistoryApi(setMenuHistory, menuId);
    const menuHistory = useMenuHistory();

    return (
        <ErrorBoundary boundary="menu-history">
            <ContentGrid>
                <ContentBlock>
                    <MenuHistoryTitle menuId={menuId} />
                </ContentBlock>
                <ContentBlock>
                    {menuHistory && menuHistory.length ? (
                        <OrderGrid>
                            {menuHistory.map(order => (
                                <OrderCard
                                    order={order}
                                    client
                                    key={order.id}
                                />
                            ))}
                        </OrderGrid>
                    ) : (
                        <ContentBlock>
                            <ContentCard>
                                <Loader />
                            </ContentCard>
                        </ContentBlock>
                    )}
                </ContentBlock>
            </ContentGrid>
        </ErrorBoundary>
    );
};
