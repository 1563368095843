import styles from './cartItem.module.css';
import { useDishAvailableQuantity, useMenuDish } from 'store/menu/selectors';
import { useTranslator } from 'hooks/useTranslator';
import { Button } from 'components/common/button';
import { Add, Close, Remove } from 'components/icons';
import { removeFromCart, updateCartQuantity } from 'store/menu/actions';
import { useDispatch } from 'react-redux';
import { PriceTag } from 'components/common/priceTag/priceTag';
import { useEffect, useState } from 'react';

export const CartItem = ({ item, index }) => {
    const { id, category, type, selected, quantity, price } = item;
    const [tempQuantity, setTempQuantity] = useState(quantity);
    const availableQuantity = useDishAvailableQuantity(id, selected);
    const dispatch = useDispatch();
    const dish = useMenuDish(id, category);
    const translator = useTranslator();

    useEffect(() => {
        if (!dish) {
            dispatch(removeFromCart(index));
        }
    }, [index, dispatch, dish]);

    useEffect(() => {
        setTempQuantity(quantity);
    }, [quantity]);

    const setQuantity = () => {
        console.log(tempQuantity);
        if (tempQuantity !== '' && !isNaN(tempQuantity)) {
            if (tempQuantity <= 0) {
                dispatch(removeFromCart(index))
            } else {
                dispatch(updateCartQuantity(index, tempQuantity - quantity));
            }
        } else {
            setTempQuantity(quantity);
        }
    };

    const getPrice = () => {
        return price * quantity;
    };

    if (!dish) return null;

    const limitReached = availableQuantity !== null && availableQuantity <= 0;
    const overLimit = availableQuantity !== null && availableQuantity < 0;
    return (
        <div
            className={`${styles.cartItem} ${
                overLimit ? styles.cartItem_limit : ''
            } shadedBg`}
        >
            <div className={styles.cartItem__header}>
                <div>{translator(dish.title)}</div>

                <Button
                    small
                    secondary
                    icon={<Close />}
                    iconOnly
                    onClick={() => dispatch(removeFromCart(index))}
                />
            </div>
            {type === 'complex' ? (
                <ul className={styles.cartItem__list}>
                    {dish.complex.map(group => {
                        const dish = group.dishes.find(
                            dish => selected && dish.id === selected[group.id]
                        );
                        if (!dish) return '';
                        return (
                            <li
                                key={group.id}
                                className={styles.cartItem__listItem}
                            >
                                <div className={styles.cartItem__listItemTitle}>
                                    {translator(dish.title)}
                                </div>
                                <div className={styles.cartItem__listItemPrice}>
                                    {dish.price} €
                                </div>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                ''
            )}
            <div className={styles.cartItem__actions}>
                <div className={styles.cartItem__counterControls}>
                    <Button
                        small
                        secondary
                        icon={<Remove />}
                        iconOnly
                        onClick={() => dispatch(updateCartQuantity(index, -1))}
                    />
                    <input
                        className={styles.cartItem__quantityInput}
                        value={tempQuantity}
                        onChange={event => {
                            const { valueAsNumber } = event.target;
                            if (!isNaN(valueAsNumber)) {
                                setTempQuantity(parseInt(valueAsNumber));
                            } else {
                                setTempQuantity('');
                            }
                        }}
                        type="number"
                        onBlur={setQuantity}
                    />
                    <Button
                        small
                        secondary
                        icon={<Add />}
                        iconOnly
                        disabled={limitReached}
                        onClick={() => dispatch(updateCartQuantity(index, 1))}
                    />
                </div>
                <PriceTag secondary price={getPrice()} />
            </div>
        </div>
    );
};
