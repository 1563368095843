import PropTypes from 'prop-types';
import { Multifield } from 'components/common/multifield';
import { useLanguages } from 'store/common/selectors';

const MultilingualField = ({ value, changeHandler, ...rest }) => {
    const languages = useLanguages();

    return (
        <Multifield
            fields={languages.map(({ code }) => ({
                key: code,
                value: value[code] === undefined ? '' : value[code],
                tag: code,
            }))}
            changeHandler={changeHandler}
            {...rest}
        />
    );
};

MultilingualField.propTypes = {
    value: PropTypes.object,
    changeHandler: PropTypes.func,
};

export { MultilingualField };
