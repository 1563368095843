import { createAction } from '@reduxjs/toolkit';
import { Balance } from 'store/balance/index';

export const setBalance = createAction('balance/SET_BALANCE', data => {
    return {
        payload: data as Balance,
    };
});

export const wipeBalance = createAction('balance/WIPE_BALANCE');

export const wipeEdited = createAction(`balance/WIPE_EDITED`)

export const setEditedBalance = createAction(
    'balance/SET_EDITED_BALANCE',
    (id: string, qty: number | null) => {
        return {
            payload: {
                id,
                qty,
            },
        };
    }
);
