import { SET_LANG, SET_MAINTENANCE, SET_TOKEN } from './constants';

export const setLang = payload => {
    return {
        type: SET_LANG,
        payload,
    };
};

export const setToken = payload => {
    return {
        type: SET_TOKEN,
        payload,
    };
};

export const setMaintenance = status => {
    return {
        type: SET_MAINTENANCE,
        payload: status,
    };
};
