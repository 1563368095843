import { PublicCard } from 'components/layout/publicCard';
import { RequestResetPasswordForm } from 'components/pages/requestResetPassword/requestResetPasswordForm';
import { Text } from 'components/text';
import { ErrorBoundary } from 'components/common/errorBoudry';

export const RequestResetPasswordPage = () => {
    return (
        <ErrorBoundary boundary="request-password-reset">
            <PublicCard title={<Text k={`Request password reset link`} />}>
                <RequestResetPasswordForm />
            </PublicCard>
        </ErrorBoundary>
    );
};
