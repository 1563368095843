import { useNewLap } from 'store/laps/selectors';
import { EditModal } from 'components/common/editModal';
import { Text } from 'components/text';
import { LapNew } from 'store/laps/types';
import { resetNewLap, updateNewLap } from 'store/laps/actions';
import { LapEditor } from 'components/pages/laps/lapEditor';
import { useLapsRefresh } from 'hooks/api/useLapsApi';

export const LapsModal = () => {
    const newLap = useNewLap();
    const refresh = useLapsRefresh();

    return (
        <EditModal
            title={<Text k={`New Lap`} />}
            update={updateNewLap}
            reset={resetNewLap}
            item={newLap}
            fieldIdPrefix={'category'}
            refresh={refresh}
            url={'/laps'}
            noScroll
            fields={[
                {
                    type: 'input',
                    key: 'title',
                    label: <Text k={`Title`} />,
                },
                {
                    type: 'slot',
                    Component: LapEditor,
                },
                {
                    type: 'input',
                    key: 'position',
                    label: <Text k={`Position`} />,
                    props: {
                        type: 'number',
                    },
                    shouldRender: (item: LapNew) => item['id'],
                },
            ]}
            formId={'new-lap-form'}
        />
    );
};
