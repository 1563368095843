import { useEffect } from 'react';
import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Text } from 'components/text';
import { useDispatch } from 'react-redux';
import { clearCart, setOrderSuccess } from 'store/menu/actions';
import { SuccessCard } from 'components/pages/orderSuccess/successCard';
import { useLocation } from 'react-router-dom';
import { ActionGroup } from 'components/common/actionGroup';
import { Button } from 'components/common/button';
import { ErrorBoundary } from 'components/common/errorBoudry';

export const OrderSuccessPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const orderId = searchParams.get('order_id');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setOrderSuccess(false));
        dispatch(clearCart());
    }, [dispatch]);

    return (
        <ErrorBoundary boundary="order-success">
            <ContentGrid>
                <ContentBlock>
                    <ContentCard
                        center
                        title={<Text k={`We received your order!`} />}
                    >
                        <SuccessCard>
                            <ActionGroup center>
                                {orderId ? (
                                    <Button to={`/order/${orderId}`}>
                                        <Text k={`View Order`} />
                                    </Button>
                                ) : (
                                    ''
                                )}
                                <Button secondary={orderId} to={'/order'}>
                                    <Text k={`New Order`} />
                                </Button>
                            </ActionGroup>
                        </SuccessCard>
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
        </ErrorBoundary>
    );
};
