import { createAction } from '@reduxjs/toolkit';
import { Dish } from 'store/dishes/types';

export const setNewDish = createAction('dishes/NEW_SET', newDish => {
    return {
        payload: newDish,
    };
});

export const updateNewDish = createAction('dishes/NEW_UPDATE', subset => {
    return {
        payload: subset,
    };
});

export const resetNewDish = createAction('dishes/NEW_RESET');

export const setDishes = createAction('dishes/SET', (dishes: Dish[]) => {
    return {
        payload: dishes,
    };
});

export const setDishesUpdating = createAction(
    'dishes/UPDATING',
    (updating: boolean) => {
        return {
            payload: updating,
        };
    }
);

export const setFilteredDishes = createAction(
    'dishes/SET_FILTERED',
    (dishes: Dish[]) => {
        return {
            payload: dishes,
        };
    }
);

export const setDishImageEditing = createAction(
    'dishes/SET_IMAGE_EDITING',
    (id: string | null) => {
        return {
            payload: id,
        };
    }
);

export const setSearchQuery = createAction(
    'dishes/SET_SEARCH_QUERY',
    (query: string) => {
        return {
            payload: query,
        };
    }
);

export const setSearchCategory = createAction(
    'dishes/SET_SEARCH_CATEGORY',
    (category: string) => {
        return {
            payload: category,
        };
    }
);
