import { useDispatch } from 'react-redux';
import { useApi } from './useApi';
import { setToken } from '../store/common/actions';

export const useLogOut = () => {
    const dispatch = useDispatch();
    const requestLogOut = useApi('/logout');

    return async () => {
        try {
            const res = await requestLogOut();
            if (res.status >= 200 || res.status < 400) {
                dispatch(setToken(null));
            }
        } catch {}
    };
};
