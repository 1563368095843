import { useApiData } from '../useApiData';
import { setMenus, setMenusUpdating } from 'store/menus/actions';
import { useDispatch } from 'react-redux';
import { useApi } from 'hooks/useApi';
import { useEffect } from 'react';

export const useMenusApi = options => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setMenusUpdating(true));
    }, [dispatch]);
    useApiData('/dishlists', setMenus, {
        params: { per_page: 'all' },
        ...options,
    });
};

export const useMenusRefresh = () => {
    const dispatch = useDispatch();
    const getMenus = useApi('/dishlists', {
        params: { per_page: 'all' },
    });

    return async () => {
        const res = await getMenus();
        dispatch(setMenus(await res.json()));
    };
};
