import styles from './nav.module.css';
import { Link, useLocation } from 'react-router-dom';
import { AddToCart, Feedback, NavClose } from '../../icons';
import mastercard from 'images/mastercard.svg';
import visa from 'images/visa.svg';
import maestro from 'images/ms_vrt_pos.svg';
import { Text } from '../../text';
import { useNavOpen, useNavStructure } from 'store/nav/selectors';
import { useModifiers } from 'hooks/useModifiers';
import { useDispatch } from 'react-redux';
import { setNavOpen } from 'store/nav/actions';
import { Button } from 'components/common/button';
import { Loader } from 'components/common/loader';
import { LanguageSwitcher } from 'components/common/languageSwitcher';
import { setReviewOpen } from 'store/review/actions';

const NavButton = ({ children, to, ...rest }) => {
    const location = useLocation();

    return (
        <Link
            className={`${styles.nav__button} ${
                location.pathname === to ? styles.nav__button_active : ''
            }`}
            to={to}
            {...rest}
        >
            {children}
        </Link>
    );
};

const NavFooter = () => {
    return (
        <footer className={styles.nav__footer}>
            <p className={styles.nav__footerText}>
                SIA "Lunch2.work"
                <br />
                Reg.Nr.: 40203285137
                <br />
                Ēvalda Valtera iela 5 - 69, Rīga, Latvija
                <br />
                Phone: <a href="tel:+37129460406">+371 29460406</a>
            </p>
            <ul className={styles.nav__footerLogos}>
                <li>
                    <img src={mastercard} alt={'Mastercard'} />
                </li>
                <li>
                    <img src={maestro} alt={'Maestro'} />
                </li>
                <li>
                    <img src={visa} alt={'Visa'} />
                </li>
            </ul>
        </footer>
    );
};

const NavGroup = ({ group }) => {
    return (
        <div className={styles.nav__group}>
            <div className={styles.nav__groupTitle}>
                <Text k={`nav.group.${group.title}`} />
            </div>
            <div className={styles.nav__groupContent}>
                {group.pages.map((item, index) => (
                    <NavButton to={`/${item.page}`} key={index}>
                        <span className={styles.nav__buttonIcon}>
                            <AddToCart />
                        </span>
                        <span>
                            <Text k={`nav.item.${item.title}`} />
                        </span>
                    </NavButton>
                ))}
            </div>
        </div>
    );
};

export const Nav = ({ className = '' }) => {
    const dispatch = useDispatch();
    const closed = !useNavOpen();
    const mods = useModifiers(styles, 'nav', { closed });
    const nav = useNavStructure();

    return (
        <>
            <div className={`${styles.nav} ${mods} ${className}`}>
                <div className={styles.nav__body}>
                    <div className={styles.nav__header}>
                        <div className={styles.nav__headerWing} />
                        <div className={styles.nav__headerWing}>
                            <Button
                                secondary
                                iconOnly
                                small
                                icon={<NavClose />}
                                onClick={() => {
                                    dispatch(setNavOpen(false));
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.nav__content}>
                        {nav ? (
                            <nav className={styles.nav__grid}>
                                {nav?.map((group, index) => (
                                    <NavGroup key={index} group={group} />
                                ))}

                                <div className={styles.nav__reviews}>
                                    <Button
                                        secondary
                                        small
                                        icon={<Feedback />}
                                        onClick={() =>
                                            dispatch(setReviewOpen(true))
                                        }
                                    >
                                        <Text k={`Leave Review`} />
                                    </Button>
                                </div>
                            </nav>
                        ) : (
                            <Loader />
                        )}
                        <div className={styles.nav__lang}>
                            <LanguageSwitcher />
                        </div>
                        <NavFooter />
                    </div>
                </div>
            </div>
            <div
                className={styles.nav__curtain}
                onClick={() => {
                    dispatch(setNavOpen(false));
                }}
            />
        </>
    );
};
