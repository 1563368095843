import { FunctionComponent, HTMLAttributes } from 'react';
import styles from './disclaimer.module.css';
import { useModifiers } from "hooks/useModifiers";

interface DisclaimerProps extends HTMLAttributes<HTMLDivElement> {
    warning?: boolean;
}
export const Disclaimer: FunctionComponent<DisclaimerProps> = ({  warning, ...rest }) => {
    const mods = useModifiers(styles, 'disclaimer', {warning})

    return <div className={`${styles.disclaimer} ${mods}`} {...rest} />;
};
