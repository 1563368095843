import { Input } from 'components/common/input';
import { Text } from 'components/text';
import { Button } from 'components/common/button';
import { Form } from 'components/layout/form';
import { useState } from 'react';
import { constants } from '../../../../constants';
import { setToken } from 'store/common/actions';
import { useDispatch } from 'react-redux';

export const PasswordCreateForm = ({ hash, email }) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);
        try {
            const res = await fetch(
                `${constants.apiPath}/app/password/create `,
                {
                    method: 'post',
                    body: JSON.stringify({
                        password,
                        password_confirmation,
                        token: hash,
                    }),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            );
            const data = await res.json();
            if (!data.errors) {
                setErrors({});
                dispatch(setToken(data.token));
                window.location.replace('/');
            } else {
                setErrors(data.errors);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };
    return (
        <Form onSubmit={handleSubmit}>
            <Input
                id={'create-account-email'}
                defaultValue={email}
                type={'email'}
                readOnly
                label={<Text k={`Email`} />}
            />
            <Input
                id={'create-account-password'}
                value={password}
                type={'password'}
                errors={errors.password}
                onChange={event => {
                    setErrors({ ...errors, password: null });
                    setPassword(event.target.value);
                }}
                label={<Text k={`Password`} />}
            />
            <Input
                id={'create-account-password_confirmation'}
                value={password_confirmation}
                type={'password'}
                errors={errors.password_confirmation}
                onChange={event => {
                    setErrors({ ...errors, password_confirmation: null });
                    setPasswordConfirmation(event.target.value);
                }}
                label={<Text k={`Confirm Password`} />}
            />
            <Button type={'submit'} loading={loading}>
                <Text k={'Create'} />
            </Button>
        </Form>
    );
};
