import { useState } from 'react';
import { Form } from 'components/layout/form';
import { Input } from 'components/common/input';
import { Text } from 'components/text';
import { Button } from 'components/common/button';
import { constants } from '../../../../constants';
import { InfoTag } from 'components/common/infoTag';

export const RequestResetPasswordForm = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState({});

    const handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);
        try {
            const res = await fetch(`${constants.apiPath}/app/password/email`, {
                method: 'post',
                body: JSON.stringify({
                    email,
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            const data = await res.json();
            if (!data.errors) {
                setErrors({});
                setSuccess(true);
                setEmail('');
            } else {
                setErrors(data.errors);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    return (
        <Form onSubmit={handleSubmit}>
            {success ? (
                <InfoTag>
                    <Text
                        k={`Password request link is sent to provided email`}
                    />
                </InfoTag>
            ) : null}
            <Input
                id={'email'}
                value={email}
                type={'email'}
                errors={errors.email}
                name={`email`}
                autoComplete="email"
                onChange={event => {
                    setErrors({ ...errors, email: null });
                    setEmail(event.target.value);
                    setSuccess(false);
                }}
                label={<Text k={`Email`} />}
                required
            />
            <Button type={'submit'} loading={loading}>
                <Text k={'Request'} />
            </Button>
        </Form>
    );
};
