import { DataTable } from 'components/common/dataTable';
import { useLaps } from 'store/laps/selectors';
import { Text } from 'components/text';
import { Loader } from 'components/common/loader';
import { Edit } from 'components/icons';
import { useDispatch } from 'react-redux';
import { setLapEditing } from 'store/laps/actions';

export const LapsTable = () => {
    const dispatch = useDispatch();
    const laps = useLaps();

    if (!laps) return <Loader />;
    const filteredLaps = laps.filter(lap => lap.id);

    return (
        <DataTable
            data={filteredLaps}
            fields={[
                {
                    label: <Text k={`Title`} />,
                    key: 'title',
                    processor: value =>
                        value ? value : <Text k={`Unassigned`} />,
                },
                {
                    label: <Text k={`Location count`} />,
                    key: 'locations_count',
                },
            ]}
            itemActions={[
                {
                    icon: <Edit />,
                    onClick: item => dispatch(setLapEditing(item)),
                    active: item => item.id,
                },
            ]}
        />
    );
};
