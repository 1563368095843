import {
    MiddlewareAPI,
    isRejectedWithValue,
    Middleware,
} from '@reduxjs/toolkit';
import { setMaintenance, setToken } from 'store/common/actions';

export const rtkQueryErrorLogger: Middleware = (
    api: MiddlewareAPI
) => next => action => {
    const status = action.payload?.originalStatus ?? action.payload?.status;

    if (isRejectedWithValue(action)) {
        if (status) {
            if (status === 401) {
                api.dispatch(setToken(null));
            }
            if (status === 503) {
                api.dispatch(setMaintenance(true));
            } else {
                api.dispatch(setMaintenance(false));
            }
        }
    }

    return next(action);
};
