import { EditModal } from 'components/common/editModal';
import { Text } from 'components/text';
import { useProvidersRefresh } from 'hooks/api/useProvidersApi';
import { useNewProvider } from 'store/providers/selectors';
import { resetNewProvider, updateNewProvider } from 'store/providers/actions';

export const NewProviderModal = () => {
    const newProvider = useNewProvider();
    const refreshProvider = useProvidersRefresh();

    return (
        <EditModal
            title={<Text k={`New Provider`} />}
            reset={resetNewProvider}
            update={updateNewProvider}
            refresh={refreshProvider}
            item={newProvider}
            formId={'new-provider-form'}
            url={'/providers'}
            fieldIdPrefix={'provider'}
            fields={[
                {
                    type: 'input',
                    key: 'title',
                    label: <Text k={`Provider Title`} />,
                },
                {
                    type: 'input',
                    key: 'email',
                    label: <Text k={`Provider Email`} />,
                    props: {
                        type: 'email',
                    },
                },
                {
                    type: 'input',
                    key: 'phone',
                    label: <Text k={`Provider Phone`} />,
                    props: {
                        type: 'tel',
                    },
                },
                {
                    type: 'checkbox',
                    key: 'status',
                    label: <Text k={`Active`} />,
                },
            ]}
        />
    );
};
