import styles from './quantityTag.module.css';
import { Text } from 'components/text';
import { FunctionComponent } from 'react';

export const QuantityTag: FunctionComponent<{ quantity: number | null }> = ({
    quantity,
}) => {
    if (!quantity || quantity >= 5) return null;
    return (
        <div className={styles.quantityTag}>
            <b>{quantity}</b> <Text k={'left'} />
        </div>
    );
};
