import { useState, useCallback } from 'react';
import { PublicCard } from 'components/layout/publicCard';
import { useApiCallback } from 'hooks/useApiCallback';
import { UserCreateForm } from 'components/pages/userCreate/userCreateForm';
import { Loader } from 'components/common/loader';
import { ErrorBoundary } from 'components/common/errorBoudry';
import { useParams } from 'react-router-dom';

export const UserCreatePage = () => {
    const { hash } = useParams();
    const [location, setLocation] = useState(null);

    const callback = useCallback(
        res => {
            setLocation(res);
        },
        [setLocation]
    );

    useApiCallback(`/locations/token/${hash}`, callback);

    return (
        <ErrorBoundary boundary="user-create">
            <PublicCard>
                {location ? (
                    <UserCreateForm hash={hash} location={location} />
                ) : (
                    <Loader />
                )}
            </PublicCard>
        </ErrorBoundary>
    );
};
