import {useAppSelector} from "store/index";

export const useNewMenu = () => {
    return useAppSelector(({ newMenu }) => newMenu);
};

export const useSelectedDishes = () => {
    return useAppSelector(({ newMenu: { selectedDishes } }) => selectedDishes);
};

export const useSelectedDish = (id: string) => {
    return useAppSelector(({ newMenu: { selectedDishes } }) => {
        return selectedDishes.find(dish => dish.id === id);
    });
};

export const useSelectedComplexDish = (complexId: string, id: string) => {
    return useAppSelector(({ newMenu: { selectedDishes } }) => {
        const complex = selectedDishes.find(dish => dish.id === complexId);
        return complex?.complex?.find(dish => dish.id === id) || {};
    });
};
