import styles from './kdsGrid.module.css';
import {
    useKDSDishGroups,
    useKDSInitialGroups,
    useKDSUpdating,
} from 'store/kds/selectors';
import { DishRecord } from 'store/kds/types';
import { Text } from 'components/text';
import { Button } from 'components/common/button';
import { Fullscreen } from 'components/icons';
import { useEffect, useRef, useState } from 'react';
import { ThinLoader } from 'components/common/thinLoader';

export const KDSGrid = () => {
    const [fullscreen, setFullscreen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const dishGroups = useKDSDishGroups();
    const initialsGroups = useKDSInitialGroups();
    const updating = useKDSUpdating();

    useEffect(() => {
        if (!ref || !ref.current) return;
        if (fullscreen) {
            ref.current.requestFullscreen();
        } else {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            }
        }
    }, [fullscreen]);

    const printDishes = (dishes: DishRecord[][]) => {
        return dishes.map((group, index) => (
            <div key={index} className={styles.kdsGrid__group}>
                {group.map((dish, index) => (
                    <div key={index} className={styles.kdsGrid__item}>
                        <div>{dish.title}</div>
                        <div>{dish.qty}</div>
                    </div>
                ))}
            </div>
        ));
    };
    if (!dishGroups || !initialsGroups) return null;
    return (
        <div className={styles.kdsGrid__wrapper} ref={ref}>
            <ThinLoader active={updating} />
            <div className={styles.kdsGrid__header}>
                <h1 className={`h3 h3_noMargin ${styles.kdsGrid__title}`}>
                    <Text k={`KDS`} />
                </h1>
                <div className={styles.kdsGrid__actions}>
                    <Button
                        icon={<Fullscreen />}
                        iconOnly
                        small
                        secondary
                        inverse
                        onClick={() => setFullscreen(!fullscreen)}
                    />
                </div>
            </div>
            <div className={styles.kdsGrid}>
                <div className={styles.kdsGrid__subgridTitle}>
                    <Text k={`Initials`} />
                </div>
                <div className={styles.kdsGrid__subgrid}>
                    {printDishes(initialsGroups)}
                </div>
                {dishGroups.map(group => (
                    <>
                        <div className={styles.kdsGrid__subgridTitle}>
                            {group.key ? group.key : <Text k={`Dishes`} />}
                        </div>
                        <div className={styles.kdsGrid__subgrid}>
                            {printDishes(group.data)}
                        </div>
                    </>
                ))}
            </div>
        </div>
    );
};
