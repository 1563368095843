import { createReducer } from '@reduxjs/toolkit';
import {
    addComplexDish,
    addComplexGroup,
    removeComplexDish,
    removeComplexGroup,
    resetNewComplex,
    setNewComplex,
    updateComplexDish,
    updateComplexGroup,
    updateNewComplex,
} from 'store/complexes/actions';
import { Translatable } from 'types/translatable';
import { DishType } from 'constants/dishType';

export type NewComplexDish = {
    id: string | null;
    price?: string | null;
};

export type NewComplexGroup = {
    id?: string;
    dishes: NewComplexDish[];
    optional: boolean;
};

export type NewComplex = {
    title: {} | Translatable;
    category_id: string | null;
    summary: {} | Translatable;
    active: boolean;
    status: boolean;
    complex: NewComplexGroup[];
    type: DishType.complex;
};

const initialState: { new: NewComplex } = {
    new: {
        title: {},
        category_id: '',
        summary: {},
        active: false,
        status: true,
        complex: [],
        type: DishType.complex,
    },
};

const initialStateGroup: NewComplexGroup = {
    id: '',
    dishes: [],
    optional: false,
};

const initialStateDish: NewComplexDish = {
    id: '',
    price: '',
};

export const complexesReducer = createReducer(initialState, builder => {
    builder.addCase(setNewComplex, (state, { payload }) => {
        state.new = payload;
    });
    builder.addCase(updateNewComplex, (state, { payload }) => {
        state.new = { ...state.new, ...payload };
    });
    builder.addCase(resetNewComplex, state => {
        state.new = initialState.new;
    });
    builder.addCase(addComplexGroup, state => {
        state.new.complex.push({ ...initialStateGroup });
    });
    builder.addCase(updateComplexGroup, (state, { payload }) => {
        const {
            index,
            subset: { id, ...rest },
        } = payload;
        const complex = state.new.complex;
        state.new.complex[index] = { ...complex[index], ...rest };
        if (id) {
            complex[index].dishes = [];
            complex[index].id = id;
        }
    });
    builder.addCase(removeComplexGroup, (state, { payload }) => {
        state.new.complex.splice(payload, 1);
    });
    builder.addCase(addComplexDish, (state, { payload }) => {
        const complex = state.new.complex;
        const { dishes } = complex[payload];
        dishes.push({ ...initialStateDish });
    });
    builder.addCase(removeComplexDish, (state, { payload }) => {
        const { index, dishIndex } = payload;
        const complex = state.new.complex;
        const { dishes } = complex[index];
        dishes.splice(dishIndex, 1);
    });
    builder.addCase(updateComplexDish, (state, { payload }) => {
        const { index, dishIndex, subset } = payload;
        const complex = state.new.complex;
        const { dishes } = complex[index];
        dishes[dishIndex] = { ...dishes[dishIndex], ...subset };
    });
});

// export const complexesReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case SET_NEW_COMPLEX: {
//             return { ...state, new: action.payload };
//         }
//         case UPDATE_NEW_COMPLEX: {
//             return { ...state, new: { ...state.new, ...action.payload } };
//         }
//         case RESET_NEW_COMPLEX: {
//             return { ...state, new: initialState.new };
//         }
//         case ADD_COMPLEX_GROUP: {
//             const complex = [...state.new.complex];
//             complex.push({ ...initialStateGroup });
//             return {
//                 ...state,
//                 new: { ...state.new, complex },
//             };
//         }
//         case UPDATE_COMPLEX_GROUP: {
//             const {
//                 index,
//                 subset: { id, ...rest },
//             } = action.payload;
//             const complex = [...state.new.complex];
//             complex[index] = { ...complex[index], ...rest };
//             if (id) {
//                 complex[index].dishes = [];
//                 complex[index].id = id;
//             }
//             return {
//                 ...state,
//                 new: { ...state.new, complex },
//             };
//         }
//         case REMOVE_COMPLEX_GROUP: {
//             const { index } = action.payload;
//             const complex = [...state.new.complex];
//             complex.splice(index, 1);
//             return {
//                 ...state,
//                 new: { ...state.new, complex },
//             };
//         }
//         case ADD_COMPLEX_DISH: {
//             const { index } = action.payload;
//             const complex = [...state.new.complex];
//             const { dishes } = complex[index];
//             dishes.push({ ...initialStateDish });
//             return {
//                 ...state,
//                 new: { ...state.new, complex },
//             };
//         }
//         case REMOVE_COMPLEX_DISH: {
//             const { index, dishIndex } = action.payload;
//             const complex = [...state.new.complex];
//             const { dishes } = complex[index];
//             dishes.splice(dishIndex, 1);
//             return {
//                 ...state,
//                 new: { ...state.new, complex },
//             };
//         }
//         case UPDATE_COMPLEX_DISH: {
//             const { index, dishIndex, subset } = action.payload;
//             const complex = [...state.new.complex];
//             const { dishes } = complex[index];
//             dishes[dishIndex] = { ...dishes[dishIndex], ...subset };
//             return {
//                 ...state,
//                 new: { ...state.new, complex },
//             };
//         }
//         default: {
//             return state;
//         }
//     }
// };
