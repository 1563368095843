import { useActiveLang } from 'store/common/selectors';

export const usePriceFormatter = () => {
    const locale = useActiveLang() as string;
    return (price: number | string) => {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: 'EUR',
        }).format(typeof price === 'number' ? price : parseFloat(price));
    };
};


