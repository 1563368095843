import styles from './headerBlock.module.css';

export const HeaderBlock = ({ children, className = '', action, ...rest }) => {
    return (
        <div className={`${styles.headerBlock} ${className}`} {...rest}>
            {action ? (
                <div className={styles.headerBlock__action}>{action}</div>
            ) : (
                ''
            )}
            {children}
        </div>
    );
};
