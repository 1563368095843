import styles from './dishBalanceCard.module.css';
import { FunctionComponent } from 'react';
import { Dish } from 'store/dishes/types';
import { useTranslator } from 'hooks/useTranslator';
import { useDishBalanceById } from 'store/balance/selectors';
import { ActionGroup } from 'components/common/actionGroup';
import { Button } from 'components/common/button';
import { Text } from 'components/text';
import { Input } from 'components/common/input';
import { useDispatch } from 'react-redux';
import { setEditedBalance } from 'store/balance/actions';

export const DishBalanceCard: FunctionComponent<{ dish: Dish }> = ({
    dish,
}) => {
    const dispatch = useDispatch();
    const translator = useTranslator();
    const dishBalance = useDishBalanceById(dish.id);
    if (!dishBalance) return null;
    return (
        <div className={`${styles.dishBalanceCard} shaded`}>
            <div className={styles.dishBalanceCard__row}>
                <div>{translator(dish.title)}</div>
                <div className={styles.dishBalanceCard__actions}>
                    <ActionGroup>
                        <Button
                            small
                            secondary={dishBalance.qty !== null}
                            onClick={() =>
                                dispatch(setEditedBalance(dish.id, null))
                            }
                        >
                            <Text k={`Unlimited`} />
                        </Button>
                        <Input
                            className={styles.dishBalanceCard__input}
                            id={`quantity-input-${dish.id}`}
                            type="number"
                            step={1}
                            min={0}
                            value={
                                dishBalance.qty !== null ? dishBalance.qty : ''
                            }
                            onChange={event => {
                                dispatch(
                                    setEditedBalance(
                                        dish.id,
                                        isNaN(event.target.valueAsNumber)
                                            ? null
                                            : event.target.valueAsNumber
                                    )
                                );
                            }}
                        />
                    </ActionGroup>
                </div>
            </div>
        </div>
    );
};
