import styles from './lapList.module.css';
import { useLaps } from 'store/laps/selectors';
import { Loader } from 'components/common/loader';
import { CardGrid } from 'components/layout/cardGrid';
import { ActionGroup } from 'components/common/actionGroup';
import { Button } from 'components/common/button';
import { Text } from 'components/text';
import { Download, View } from 'components/icons';
import { useApi } from 'hooks/useApi';
import { FunctionComponent, useState } from 'react';
import { Lap } from 'store/laps/types';
import { useParams } from "react-router-dom";

const LapListItem: FunctionComponent<{ lap: Lap }> = ({ lap }) => {
    const {menuId} = useParams();
    const request = useApi(`/laps/print-report`);
    const [downloading, setDownloading] = useState(false);

    const handleDownload = async () => {
        setDownloading(true);
        const res = await request({ route: `/${lap.id}/${menuId}` });

        if (res.ok) {
            const blob = await res.blob();
            const file = new Blob([blob]);
            const fileUrl = window.URL.createObjectURL(file);
            console.log(fileUrl);
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', `${lap.title}.pdf`);
            document.body.appendChild(link);
            link.click();
        }
        setDownloading(false);
    };

    return (
        <div className={`${styles.lapList__item} shaded`} key={lap.id}>
            <span>{lap.title}</span>
            <ActionGroup>
                <Button
                    small
                    secondary
                    disabled={!lap.id}
                    icon={<View />}
                    to={`/menu-laps/${menuId}/${lap.id}`}
                >
                    <Text k={`View`} />
                </Button>
                <Button
                    small
                    secondary
                    loading={downloading}
                    disabled={!lap.id}
                    icon={<Download />}
                    iconOnly
                    onClick={handleDownload}
                />
            </ActionGroup>
        </div>
    );
};

export const LapList = () => {
    const laps = useLaps();

    if (!laps) return <Loader />;
    return (
        <div>
            <CardGrid>
                {laps.map(lap => {
                    return <LapListItem key={lap.id} lap={lap} />;
                })}
            </CardGrid>
        </div>
    );
};
