import styles from './lapDishes.module.css';
import { LapDishes as LapDishesType } from 'store/lap/types';
import { FunctionComponent } from 'react';
import { useModifiers } from 'hooks/useModifiers';

export const LapDishes: FunctionComponent<{
    dishes: LapDishesType;
    noMargin?: boolean;
}> = ({ dishes, noMargin = false }) => {
    const mods = useModifiers(styles, 'lapDishes', { noMargin });

    return (
        <div className={`${styles.lapDishes} ${mods}`}>
            <div className={styles.lapDishes__grid}>
                {dishes.map((kitchen, index) => {
                    return (
                        <div key={index}>
                            <h3 className={`h3`}>{kitchen.key}</h3>
                            <div className={styles.lapDishes__subgrid}>
                                {kitchen.data.map((dishGroup, groupIndex) => {
                                    return (
                                        <div
                                            className={styles.lapDishes__card}
                                            key={groupIndex}
                                        >
                                            {dishGroup.map(
                                                (dish, dishIndex) => (
                                                    <div
                                                        className={
                                                            styles.lapDishes__item
                                                        }
                                                        key={dishIndex}
                                                    >
                                                        <span>
                                                            {dish.title}
                                                        </span>
                                                        <span>{dish.qty}</span>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
