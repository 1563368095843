import { useState } from 'react';
import styles from './orderCard.module.css';
import { useApi } from 'hooks/useApi';
import { useFormattedDate } from 'hooks/useFormattedDate';
import { OrderDishCard } from 'components/pages/orderHistory/orderDishCard';
import { ActionGroup } from 'components/common/actionGroup';
import { PriceTag } from 'components/common/priceTag/priceTag';
import { Bonus, Cash, CreditCard, Paid } from 'components/icons';
import { Text } from 'components/text';
import { Button } from 'components/common/button';
import { useDispatch } from 'react-redux';
import { updateOrdersByMenuSingle } from 'store/ordersByMenu/actions';
import { useOrdersByMenuUpdating } from 'store/ordersByMenu/selectors';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { OrderTable } from 'components/pages/orderHistory/orderTable';

export const OrderCard = ({ locationId, order, client = false }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const orderApi = useApi(`/order`, { method: 'put' });
    const orderApiRemove = useApi(`/order`, { method: 'delete' });
    const updating = useOrdersByMenuUpdating();
    const {
        created_at,
        user: { first_name },
        items,
        comment,
        amount_to_pay,
        amount,
        payment_type,
        accepted,
        id,
        subsidy,
        bonus,
        shift,
    } = order;

    const acceptOrder = async () => {
        setLoading(true);
        try {
            const res = await orderApi({
                route: `/${id}`,
                params: { accepted: accepted ? 0 : 1 },
            });
            if (res.ok) {
                const updatedOrder = await res.json();
                setLoading(false);
                dispatch(updateOrdersByMenuSingle(locationId, order.id, updatedOrder));
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const handleRemove = async () => {
        setDeleting(true);
        if (window.confirm(`Are you sure you want to delete order ${order.id}?`)) {
            try {
                const res = await orderApiRemove({
                    route: `/${id}`,
                });
                if (res.ok) {
                    dispatch(updateOrdersByMenuSingle(locationId, order.id, null));
                } else {
                }
            } catch (error) {
                console.error(error);
            }
        }
        setDeleting(false);
    };

    const getPaymentIcon = () => {
        switch (payment_type) {
            case 'cash': {
                return <Cash />;
            }
            case 'card': {
                return <CreditCard />;
            }
            case 'subsidy': {
                return <Paid />;
            }
            case 'bonus': {
                return <Bonus />;
            }
            default: {
                return '';
            }
        }
    };
    const createdAt = useFormattedDate(created_at);
    return (
        <ContentCard key={order.id} smallPadding fixedHeight>
            <div className={styles.orderCard}>
                <div className={styles.orderCard__id}>{order.id}</div>
                <div className={styles.orderCard__header}>
                    <h3 className={styles.orderCard__name}>{first_name}</h3>
                    <div className={styles.orderCard__date}>{createdAt}</div>
                </div>
                <div className={styles.orderCard__dishes}>
                    {items.map(({ dish, qty, price, complex }, index) => (
                        <OrderDishCard
                            key={index}
                            dish={dish}
                            quantity={qty}
                            price={price}
                            complex={complex}
                            stripped
                        />
                    ))}
                </div>
                {comment ? (
                    <div className={`${styles.orderCard__comments} text`}>
                        <p>{comment}</p>
                    </div>
                ) : (
                    ''
                )}
                <div className={styles.orderCard__footer}>
                    {client ? (
                        <div className={styles.orderCard__prices}>
                            <OrderTable order={order} />
                        </div>
                    ) : (
                        <>
                            {shift ? (
                                <div className={styles.orderCard__prices}>
                                    <OrderTable order={order} shiftOnly={true} />
                                </div>
                            ) : null}
                            <div className={`${styles.orderCard__prices} ${styles.orderCard__prices_row}`}>
                                <ActionGroup>
                                    {getPaymentIcon()}
                                    <PriceTag prefix={<Text k={`Total:`} />} price={amount} bold secondary />
                                    <PriceTag prefix={<Text k={`Payed:`} />} price={amount_to_pay} />
                                </ActionGroup>
                            </div>
                            {subsidy || bonus ? (
                                <div className={`${styles.orderCard__prices} ${styles.orderCard__prices_row}`}>
                                    <ActionGroup>
                                        {subsidy ? (
                                            <PriceTag prefix={<Text k={`Subsidy:`} />} price={-subsidy} secondary />
                                        ) : null}
                                        {bonus ? (
                                            <PriceTag prefix={<Text k={`Bonus:`} />} price={-bonus} secondary />
                                        ) : null}
                                    </ActionGroup>
                                </div>
                            ) : null}
                            <div className={styles.orderCard__actions}>
                                <ActionGroup>
                                    <Button small secondary danger loading={deleting} onClick={handleRemove}>
                                        <Text k={`Remove`} />
                                    </Button>
                                </ActionGroup>
                                <ActionGroup>
                                    <div>{accepted ? <Text k={`Accepted`} /> : ''}</div>
                                    <Button
                                      small
                                      secondary={accepted}
                                      loading={loading}
                                      disabled={updating}
                                      onClick={acceptOrder}
                                    >
                                        {accepted ? <Text k={`Revoke Acceptance`} /> : <Text k={`Accept`} />}
                                    </Button>
                                </ActionGroup>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </ContentCard>
    );
};
