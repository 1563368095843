import { FunctionComponent, HTMLAttributes } from 'react';

interface LocationTitleProps extends HTMLAttributes<HTMLDivElement> {
    location: {
        title?: string;
        address?: string;
    };
}

export const LocationTitle: FunctionComponent<LocationTitleProps> = ({ location, className = '' }) => {
    return (
        <div className={`${className}`}>
            <h1 className={`h1 h1_noMargin`}>{location?.title}</h1>
            <div className={`text`}>
                <p>{location?.address}</p>
            </div>
        </div>
    );
};
