import { useSelector } from 'react-redux';
import { useActiveLang } from '../common/selectors';



export const useTranslation = key => {
    const dictionaryRecord = useSelector(
        ({ dictionary = {} }) => dictionary[key]
    );
    const locale = useActiveLang();
    if (dictionaryRecord && dictionaryRecord[locale]) {
        return dictionaryRecord[locale];
    }
    return key;
};
