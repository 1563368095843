import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { Text } from 'components/text';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Loader } from 'components/common/loader';
import { CardGrid } from 'components/layout/cardGrid';
import { useOrderHistoryApi } from 'hooks/api/useOrderHistory';
import { useOrderHistory } from 'store/orderHistory/selectors';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { OrderTable } from 'components/pages/orderHistory/orderTable';
import { ErrorBoundary } from 'components/common/errorBoudry';
import { useParams } from "react-router-dom";

const Page = ({ orderId }) => {
    const orderHistory = useOrderHistory();

    const getOrderDate = () => {
        if (!orderHistory || !orderHistory.created_at) return '';
        const createdAt = parseISO(orderHistory.created_at);
        return <span>{format(createdAt, 'dd.LL.yyyy')}</span>;
    };

    if (
        orderHistory &&
        orderHistory.id &&
        orderId.toString() !== orderHistory.id.toString()
    )
        return (
            <ContentGrid>
                <ContentBlock>
                    <ContentCard>
                        <Loader />
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
        );
    return (
        <>
            <ContentGrid>
                <ContentBlock>
                    <h1 className={`h1 h1_noMargin`}>
                        <Text k={`Ordered on`} /> {getOrderDate()}
                    </h1>
                </ContentBlock>
                <ContentBlock>
                    {orderHistory && orderHistory.id ? (
                        <ContentCard>
                            <CardGrid>
                                <OrderTable order={orderHistory} />
                            </CardGrid>
                        </ContentCard>
                    ) : (
                        <ContentCard>
                            <Loader />
                        </ContentCard>
                    )}
                </ContentBlock>
            </ContentGrid>
        </>
    );
};

export const OrderHistoryPage = () => {
    const { orderId } = useParams();
    useOrderHistoryApi(orderId);

    return (
        <ErrorBoundary boundary="order-history">
            <Page orderId={orderId} />
        </ErrorBoundary>
    );
};
