import { HistoryItem } from 'store/history/types';
import { createReducer } from '@reduxjs/toolkit';
import { setHistory } from 'store/history/actions';

const initialState: {
    data: HistoryItem[] | null;
} = {
    data: null,
};

export const historyReducer = createReducer(initialState, builder => {
    builder.addCase(setHistory, (state, { payload }) => {
        state.data = payload;
    });
});
