import styles from './contentGrid.module.css';
import { CSSProperties, FunctionComponent, HTMLAttributes } from 'react';

interface ContentBlockProps extends HTMLAttributes<HTMLDivElement> {
    span?: number;
}

export const ContentBlock: FunctionComponent<ContentBlockProps> = ({ children, className = '', span, ...rest }) => {
    return (
        <div
            className={`${styles.contentGrid__block} ${className}`}
            style={{ '--columnSpan': span ? `span ${span}` : '' } as CSSProperties}
            {...rest}
        >
            {children}
        </div>
    );
};

interface ContentGridProps extends HTMLAttributes<HTMLDivElement> {}

export const ContentGrid: FunctionComponent<ContentGridProps> = ({ children, className = '', ...rest }) => {
    return (
        <div className={`${styles.contentGrid} ${className}`} {...rest}>
            {children}
        </div>
    );
};
