import { ContentCard } from 'components/layout/contentCard/contentCard';
import styles from './filterCard.module.css';
import { ActionGroup } from 'components/common/actionGroup';
import { Button } from 'components/common/button';
import { Text } from 'components/text';

export const FilterCard = ({ children, isSet, onReset, ...rest }) => {
    return (
        <ContentCard tinyPadding {...rest}>
            <div className={styles.filterCard}>
                {children}
                {isSet ? (
                    <ActionGroup>
                        <Button secondary onClick={onReset}>
                            <Text k={`Clear Filter`} />
                        </Button>
                    </ActionGroup>
                ) : (
                    ''
                )}
            </div>
        </ContentCard>
    );
};
