import { FormEvent, ReactNode, useMemo, useState } from 'react';
import styles from './cart.module.css';
import {
    useCart,
    useCartOpen,
    useMenuId,
    useMenuUserData,
    useMenuUserPaymentMethods,
    useOrderSuccess,
    useSelectedShift,
} from 'store/menu/selectors';
import { Text } from 'components/text';
import { CartItem } from 'components/pages/newOrder/cartItem';
import { Button } from 'components/common/button';
import { Checkbox } from 'components/common/checkbox';
import { Link, Navigate } from 'react-router-dom';
import { FormItem } from 'components/common/formItem';
import { Input } from 'components/common/input';
import { useApi } from 'hooks/useApi';
import { setCartStatus, setOrderSuccess, setSelectedShift } from 'store/menu/actions';
import { useDispatch } from 'react-redux';
import { RadioGroup } from 'components/common/radioGroup';
import { useModifiers } from 'hooks/useModifiers';
import { ContentBlock } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Close } from 'components/icons';
import { ErrorBlock } from 'components/common/errorBlock';
import { useMenuRefresh } from 'hooks/api/useMenuApi';
import { useUserIsCustomer, useUserLocation } from 'store/user/selectors';
import { UserSelect } from 'components/pages/newOrder/userSelect';
import { CartTotal } from 'components/pages/newOrder/cartTotal';
import { InfoTag } from 'components/common/infoTag';
import { usePriceFormatter } from 'hooks/useFormattedPrice';
import { CartItem as CartItemType, PaymentTypes } from 'store/menu/types';
import { BankImage } from 'components/pages/newOrder/bankImage';
import { Disclaimer } from 'components/common/disclaimer';
import { LocationType } from 'store/user/types';
import { Select } from 'components/common/select';
import { useTranslator } from 'hooks/useTranslator';
import { useTranslation } from 'store/dictionary/selectors';
import { useUserList } from 'store/userList/selectors';

export const Cart = () => {
    const dispatch = useDispatch();

    const translator = useTranslator();
    const selectShiftPlaceholder = useTranslation('Select shift');

    const cart = useCart();
    const isOpen = useCartOpen();
    const menuId = useMenuId();
    const refreshMenu = useMenuRefresh();
    const isCustomer = useUserIsCustomer();
    const location = useUserLocation() as LocationType;
    const success = useOrderSuccess();
    const userData = useMenuUserData();
    const userPaymentMethods = useMenuUserPaymentMethods();
    const priceFormatter = usePriceFormatter();
    const userList = useUserList();

    const order = useApi(isCustomer ? '/order/create' : '/order/create-for-user', { method: 'post' });
    const [comment, setComment] = useState('');
    const [agree, setAgree] = useState(!isCustomer);
    const [payment, setPayment] = useState<PaymentTypes | string>(
        isCustomer && userPaymentMethods?.card ? PaymentTypes.Card : PaymentTypes.Cash
    );
    const [bank, setBank] = useState<boolean>(false);
    const [sending, setSending] = useState(false);
    const [errors, setErrors] = useState<Record<string, string[]>>({});
    const [issue, setIssue] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const selectedShift = useSelectedShift();

    const mods = useModifiers(styles, 'cart', { open: isOpen });

    const selectedUserData = useMemo(() => {
        if (!userList || !Array.isArray(userList)) return;
        return userList.find(user => user.id === selectedUser);
    }, [selectedUser, userList]);

    console.log(selectedUserData);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        setIssue(false);
        if (cart.length) {
            const orderData: {
                dishlist_id?: string;
                items: {
                    id: string;
                    qty: number;
                    complex?: CartItemType['selected'];
                }[];
                comment?: string;
                payment_type?: PaymentTypes | string;
                user_id?: string;
                bank?: string;
                shift_id: string | null;
            } = {
                dishlist_id: menuId,
                items: cart.map(({ id, quantity: qty, selected, type }) => {
                    const item: {
                        id: string;
                        qty: number;
                        complex?: CartItemType['selected'];
                    } = { id, qty };
                    if (type === 'complex') {
                        item.complex = selected;
                    }
                    return item;
                }),
                comment,
                payment_type: bank ? PaymentTypes.Bank : payment,
                bank: bank ? payment : undefined,
                shift_id: selectedShift,
            };

            if (!isCustomer) {
                orderData.user_id = selectedUser;
            }

            const send = async () => {
                setSending(true);
                try {
                    const res = await order({
                        body: JSON.stringify(orderData),
                    });
                    const data = await res.json();
                    if (data.success) {
                        if (data.payment_link) {
                            window.location.replace(data.payment_link);
                        } else {
                            dispatch(setOrderSuccess(true));
                        }
                    } else if (data.errors) {
                        setErrors(data.errors);
                        refreshMenu();
                    } else {
                        setIssue(true);
                    }
                } catch (error) {
                    setIssue(true);
                    console.error(error);
                }
                setSending(false);
            };
            send();
        }
    };

    const getBalanceError = () => {
        const errorKeys = Object.keys(errors);
        return errorKeys.some(key => key.startsWith('balance'));
    };

    const getDisabled = () => {
        if (!isCustomer && selectedUserData?.shifts?.length && !selectedShift) return true;
        if (isCustomer && location.shifts?.length && !selectedShift) return true;
        return isCustomer ? !agree : !selectedUser;
    };

    const paymentOptions: {
        key: PaymentTypes | string;
        label: string | ReactNode;
        bankId?: string;
        center?: boolean;
    }[] = [];

    if (isCustomer && userPaymentMethods) {
        if (userPaymentMethods.banks) {
            userPaymentMethods.banks.map(bank => {
                paymentOptions.push({
                    key: bank.code,
                    label: <BankImage src={bank.imageUrl} alt={bank.name} />,
                    bankId: bank.code,
                    center: true,
                });
            });
        }
    }
    if (isCustomer && userPaymentMethods?.card) {
        paymentOptions.push({
            key: PaymentTypes.Card,
            label: <Text k={`Pay with card`} />,
        });
    }
    if (!location?.disable_cash) {
        paymentOptions.push({
            key: PaymentTypes.Cash,
            label: <Text k={`Pay with cash`} />,
        });
    }

    const getCommission = () => {
        if (bank) return userPaymentMethods?.bankCommission;
        if (payment === PaymentTypes.Card) return userPaymentMethods?.cardCommission;
    };

    const getCartContent = () => {
        if (!cart.length) return <Text k={`Cart is empty`} />;
        return (
            <form onSubmit={handleSubmit}>
                <div className={styles.cart__body}>
                    {cart.map((item, index) => (
                        <CartItem item={item} key={index} index={index} />
                    ))}
                </div>
                <FormItem>
                    <Input
                        textarea
                        label={<Text k={'Comment'} />}
                        id={`cart-terms-comment`}
                        value={comment}
                        onChange={event => setComment(event.target.value)}
                    />
                </FormItem>
                {isCustomer && location.shifts?.length ? (
                    <FormItem>
                        <Select
                            label={<Text k={`Shift`} />}
                            options={[
                                { key: 'null', label: selectShiftPlaceholder },
                                ...location.shifts.map(shift => ({ key: shift.id, label: translator(shift.title) })),
                            ]}
                            value={selectedShift || 'null'}
                            onChange={event => {
                                if (event.target.value !== 'null') {
                                    dispatch(setSelectedShift(event.target.value));
                                } else {
                                    dispatch(setSelectedShift(null));
                                }
                            }}
                        />
                    </FormItem>
                ) : null}
                <FormItem>
                    <RadioGroup
                        label={<Text k={`Payment method`} />}
                        id={`cart-payment`}
                        value={payment}
                        errors={errors['payment_type']}
                        handleChange={(value, option) => {
                            if (option.bankId) {
                                setBank(true);
                            } else {
                                setBank(false);
                            }
                            setPayment(value);
                        }}
                        options={paymentOptions}
                    />
                </FormItem>
                {payment === PaymentTypes.Card && userPaymentMethods?.cardCommission ? (
                    <FormItem>
                        <Disclaimer warning>
                            <Text k={`Card commission`} />: {priceFormatter(userPaymentMethods.cardCommission)}
                        </Disclaimer>
                    </FormItem>
                ) : null}
                {bank && userPaymentMethods?.bankCommission ? (
                    <FormItem>
                        <Disclaimer warning>
                            <Text k={`Bank commission`} />: {priceFormatter(userPaymentMethods.bankCommission)}
                        </Disclaimer>
                    </FormItem>
                ) : null}
                {bank && userPaymentMethods?.bankConsent ? (
                    <FormItem>
                        <Disclaimer
                            dangerouslySetInnerHTML={{
                                __html: userPaymentMethods.bankConsent,
                            }}
                        />
                    </FormItem>
                ) : null}
                {payment === PaymentTypes.Card && userPaymentMethods?.cardConsent ? (
                    <FormItem>
                        <Disclaimer
                            dangerouslySetInnerHTML={{
                                __html: userPaymentMethods.cardConsent,
                            }}
                        />
                    </FormItem>
                ) : null}
                <CartTotal commission={getCommission()} />
                {isCustomer ? (
                    <FormItem>
                        <Checkbox
                            id={`cart-terms-agree`}
                            checked={agree}
                            onChange={event => setAgree(event.target.checked)}
                        >
                            I agree to <Link to={'/privacy'}>the terms of service</Link>
                        </Checkbox>
                    </FormItem>
                ) : (
                    <FormItem>
                        <UserSelect
                            value={selectedUser}
                            //@ts-ignore
                            onChange={(value: { value: string }) => setSelectedUser(value.value)}
                        />
                    </FormItem>
                )}
                {!isCustomer && selectedUserData?.shifts?.length ? (
                    <FormItem>
                        <Select
                            label={<Text k={`Shift`} />}
                            options={[
                                { key: 'null', label: selectShiftPlaceholder },
                                ...selectedUserData?.shifts.map(shift => ({
                                    key: shift.id,
                                    label: translator(shift.title),
                                })),
                            ]}
                            value={selectedShift || 'null'}
                            onChange={event => {
                                if (event.target.value !== 'null') {
                                    dispatch(setSelectedShift(event.target.value));
                                } else {
                                    dispatch(setSelectedShift(null));
                                }
                            }}
                        />
                    </FormItem>
                ) : null}
                {issue ? (
                    <ErrorBlock className={styles.cart__issue}>
                        <Text k={`There was an issue while placing your order, please, try again.`} />
                    </ErrorBlock>
                ) : (
                    ''
                )}
                {getBalanceError() ? (
                    <ErrorBlock className={styles.cart__issue}>
                        <Text k={`Some items you ordered are not available.`} />
                    </ErrorBlock>
                ) : (
                    ''
                )}
                <div className={styles.cart__action}>
                    <Button type="submit" disabled={getDisabled()} loading={sending}>
                        <Text k={`Order`} />
                    </Button>
                </div>
            </form>
        );
    };

    if (success) return <Navigate to={'/order/success'} />;
    return (
        <>
            <ContentBlock span={5} className={`${styles.cart} ${mods}`}>
                <div className={styles.cart__header}>
                    <Button
                        secondary
                        iconOnly
                        small
                        icon={<Close />}
                        onClick={() => {
                            dispatch(setCartStatus(false));
                        }}
                    />
                </div>
                <ContentCard title={<Text k={`Your Order`} />} className={styles.cart__card}>
                    {userData?.bonus ? (
                        <InfoTag className={styles.cart__info}>
                            <div className={styles.cart__infoRow}>
                                <Text k={'You have a bonus available!'} />
                                <div className={styles.cart__infoBonus}>{priceFormatter(userData.bonus)}</div>
                            </div>
                        </InfoTag>
                    ) : null}
                    {getCartContent()}
                </ContentCard>
            </ContentBlock>
            <div
                className={styles.cart__curtain}
                onClick={() => {
                    dispatch(setCartStatus(false));
                }}
            />
        </>
    );
};
