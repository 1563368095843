import { useAppSelector } from 'store/index';
import { getAvailableBalance } from 'store/menu/index';

export const useMenu = () => {
    return useAppSelector(({ menu: { dishlist } }) => dishlist?.categories);
};

export const useAvailableDishlists = () => {
    return useAppSelector(({ menu: { availableDishlists } }) => availableDishlists);
};

export const useSelectedDishlist = () => {
    return useAppSelector(({ menu: { selectedDishlist } }) => selectedDishlist);
};

export const useMenuWaiting = () => {
    return useAppSelector(({ menu: { waiting } }) => waiting);
};

export const useMenuUpdating = () => {
    return useAppSelector(({ menu: { updating } }) => updating);
};

export const useCartOpen = () => {
    return useAppSelector(({ menu: { cartOpen } }) => cartOpen);
};

export const useMenuId = () => {
    return useAppSelector(({ menu: { dishlist } }) => dishlist?.id);
};

export const useMenuUserData = () => {
    return useAppSelector(({ menu }) => menu?.userData);
};

export const useMenuUserPaymentMethods = () => {
    return useAppSelector(({ menu }) => menu?.paymentMethods);
};



export const useOrderSuccess = () => {
    return useAppSelector(({ menu: { success } }) => success);
};

export const useCart = () => {
    return useAppSelector(({ menu: { cart } }) => cart);
};

export const useMenuDish = (id: string, categoryId: string) => {
    return useAppSelector(({ menu: { dishlist } }) => {
        const category = dishlist?.categories.find(category => category.id == categoryId);
        return category ? category.dishes.find(dish => dish.id == id) : null;
    });
};

export const useMenuDuration = () => {
    return useAppSelector(({ menu: { dishlist } }) => ({
        from: dishlist?.from,
        to: dishlist?.to,
    }));
};

export const useDishAvailableQuantity = (id?: string, selected?: { [x: string]: string }) => {
    return useAppSelector(({ menu }) => {
        if (!id) return null;
        return getAvailableBalance(menu, id, selected);
    });
};

export const useSelectedShift = () => {
    return useAppSelector(({ menu }) => menu.selectedShift);
};
