import { useEffect, useState } from 'react';
import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Text } from 'components/text';
import { useDispatch } from 'react-redux';
import { clearCart, setOrderSuccess } from 'store/menu/actions';
import { SuccessCard } from 'components/pages/orderSuccess/successCard';
import { ActionGroup } from 'components/common/actionGroup';
import { Button } from 'components/common/button';
import { Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'components/common/errorBoudry';

export const OrderFailPage = () => {
    const dispatch = useDispatch();
    const [reset, setReset] = useState(false);

    useEffect(() => {
        dispatch(setOrderSuccess(false));
    }, [dispatch]);

    useEffect(() => {
        if (reset) {
            dispatch(clearCart());
        }
    }, [dispatch, reset]);

    if (reset) return <Navigate to={'/order'} />;
    return (
        <ErrorBoundary boundary="order-fail">
            <ContentGrid>
                <ContentBlock>
                    <ContentCard
                        center
                        title={<Text k={`There was a problem with payment!`} />}
                    >
                        <SuccessCard>
                            <ActionGroup center>
                                <Button to={`/order`}>
                                    <Text k={`New Order`} />
                                </Button>
                            </ActionGroup>
                        </SuccessCard>
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
        </ErrorBoundary>
    );
};
