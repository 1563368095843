import { createReducer } from '@reduxjs/toolkit';
import {
    addLocationToLap,
    moveLocationInLap,
    removeLocationFromLap,
    resetNewLap,
    setLapEditing,
    setLaps,
    setLapsUpdating,
    updateNewLap,
} from 'store/laps/actions';
import { LapsReducer } from 'store/laps/types';

const initialState: LapsReducer = {
    laps: null,
    new: {
        title: '',
        position: null,
        locations: [],
        active: false,
        saving: false,
    },
    updating: false,
};

export const lapsReducer = createReducer(initialState, builder => {
    builder.addCase(setLaps, (state, { payload }) => {
        state.laps = payload;
        state.updating = false;
    });
    builder.addCase(setLapEditing, (state, { payload }) => {
        if (payload === undefined) {
            state.new.active = true;
        } else {
            state.new = { ...payload, active: true, saving: false };
        }
    });
    builder.addCase(updateNewLap, (state, { payload }) => {
        state.new = { ...state.new, ...payload };
    });
    builder.addCase(resetNewLap, state => {
        state.new = initialState.new;
    });
    builder.addCase(addLocationToLap, (state, { payload }) => {
        state.new.locations.push({ id: payload });
    });
    builder.addCase(removeLocationFromLap, (state, { payload }) => {
        state.new.locations = state.new.locations.filter(
            item => item.id !== payload
        );
    });
    builder.addCase(moveLocationInLap, (state, { payload }) => {
        const { id, offset } = payload;
        const index = state.new.locations.findIndex(
            location => location.id === id
        );
        if (index >= 0) {
            const finalDestination = index + offset;
            if (
                finalDestination >= 0 &&
                finalDestination < state.new.locations.length
            ) {
                const temp = { ...state.new.locations[finalDestination] };
                state.new.locations[finalDestination] = {
                    ...state.new.locations[index],
                };
                state.new.locations[index] = temp;
            }
        }
    });
    builder.addCase(setLapsUpdating, (state, { payload }) => {
        state.updating = payload;
    });
});
