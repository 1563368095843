import styles from './thinLoader.module.css';
import { FunctionComponent } from 'react';
import { useModifiers } from 'hooks/useModifiers';

export const ThinLoader: FunctionComponent<{ active: boolean, card?:boolean }> = ({
    active,
    card
}) => {
    const mods = useModifiers(styles, 'thinLoader', { active, card });
    return <div className={`${styles.thinLoader} ${mods}`} />;
};
