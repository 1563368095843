import styles from './lapEditor.module.css';
import { useNewLap } from 'store/laps/selectors';
import { LocationSelect } from 'components/pages/locations/locationSelect';
import { FunctionComponent, useMemo } from 'react';
import { Button } from 'components/common/button';
import { ActionGroup, ActionGroupGrow } from 'components/common/actionGroup';
import { useDispatch } from 'react-redux';
import { addLocationToLap, moveLocationInLap, removeLocationFromLap } from 'store/laps/actions';
import { Bin, Collapse, Expand } from 'components/icons';
import { InfoTag } from 'components/common/infoTag';
import { useGetAllLocationsQuery } from '../../../../api/locations/endpoints';
import { Loader } from 'components/common/loader';

const LapEditorItem: FunctionComponent<{
    id: string;
    first?: boolean;
    last?: boolean;
}> = ({ id, first = false, last = false }) => {
    const dispatch = useDispatch();
    const { data: locations } = useGetAllLocationsQuery();

    const location = useMemo(() => {
        if (!locations) return undefined;
        return locations.find(location => location.id === id);
    }, [locations, id]);

    if (!location) return <InfoTag>Location not found</InfoTag>;
    return (
        <div className={styles.lapEditor__item}>
            <div className={styles.lapEditor__itemLabel}>{location.title}</div>
            <ActionGroup>
                <Button
                    small
                    secondary
                    iconOnly
                    disabled={first}
                    icon={<Collapse />}
                    onClick={() => dispatch(moveLocationInLap(id, -1))}
                />
                <Button
                    small
                    secondary
                    iconOnly
                    disabled={last}
                    icon={<Expand />}
                    onClick={() => dispatch(moveLocationInLap(id, 1))}
                />
                <Button small secondary iconOnly icon={<Bin />} onClick={() => dispatch(removeLocationFromLap(id))} />
            </ActionGroup>
        </div>
    );
};

export const LapEditor = () => {
    const dispatch = useDispatch();
    const { locations } = useNewLap();
    const { isLoading } = useGetAllLocationsQuery();

    return (
        <div className={`${styles.lapEditor} shaded`}>
            {locations.length && !isLoading ? (
                <div className={`${styles.lapEditor__grid}`}>
                    {locations.map((item, index) => (
                        <LapEditorItem
                            first={index === 0}
                            last={index === locations.length - 1}
                            id={item.id}
                            key={item.id}
                        />
                    ))}
                </div>
            ) : isLoading ? (
                <Loader />
            ) : null}
            <div className={styles.lapEditor__actions}>
                <ActionGroup>
                    <ActionGroupGrow>
                        <LocationSelect
                            value={null}
                            filter={item => {
                                return !locations.find(singleLocation => item.id === singleLocation.id);
                            }}
                            onChange={value => {
                                if (value) {
                                    //@ts-ignore
                                    dispatch(addLocationToLap(value.value));
                                }
                            }}
                        />
                    </ActionGroupGrow>
                </ActionGroup>
            </div>
        </div>
    );
};
