import { setMenu, setMenuUpdating } from 'store/menu/actions';
import { useDispatch } from 'react-redux';
import { useApi } from 'hooks/useApi';

export const useMenuRefresh = () => {
    const dispatch = useDispatch();
    const getMenus = useApi('/order/create');

    return async () => {
        dispatch(setMenuUpdating(true));
        const res = await getMenus();
        dispatch(setMenu(await res.json()));
    };
};
