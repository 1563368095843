import { useEffect, useState } from 'react';
import { useAvailableDishlists, useMenuDuration } from 'store/menu/selectors';

export const useWatchMenu = (): boolean => {
    const { to } = useMenuDuration();
    const dishlists = useAvailableDishlists();
    const [shouldPoll, setShouldPoll] = useState(false);

    useEffect(() => {
        if (to && dishlists?.length) {
            const toDate = new Date(to);
            const ticker = () => {
                setShouldPoll(new Date() > toDate);
            };
            const interval = setInterval(ticker, 2000);
            return () => {
                clearInterval(interval);
            };
        } else {
            setShouldPoll(false);
        }
    }, [to, setShouldPoll, dishlists]);

    return shouldPoll;
};
