import { createAction } from '@reduxjs/toolkit';
import { Lap, LapNew } from 'store/laps/types';

export const setLaps = createAction('laps/SET', laps => {
    return {
        payload: laps,
    };
});

export const setLapEditing = createAction('laps/SET_EDITING', (lap?: Lap) => {
    return {
        payload: lap,
    };
});

export const updateNewLap = createAction(
    `laps/UPDATE_NEW`,
    (subset: Partial<LapNew>) => {
        return {
            payload: subset,
        };
    }
);

export const addLocationToLap = createAction(
    `laps/ADD_LOCATION`,
    (id: string) => {
        return {
            payload: id,
        };
    }
);

export const removeLocationFromLap = createAction(
    `laps/REMOVE_LOCATION`,
    (id: string) => {
        return {
            payload: id,
        };
    }
);

export const moveLocationInLap = createAction(
    `laps/MOVE_LOCATION`,
    (id: string, offset: number) => {
        return {
            payload: {id, offset},
        };
    }
);

export const setLapsUpdating = createAction(
    `laps/UPDATING`,
    (updating: boolean) => {
        return {
            payload: updating,
        };
    }
);

export const resetNewLap = createAction(`laps/RESET_NEW`);
