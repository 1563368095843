import styles from './addMemberModal.module.css';
import { useState } from 'react';
import { Modal } from 'components/layout/modal';
import { Text } from 'components/text';
import { FormItem } from 'components/common/formItem';
import { Input } from 'components/common/input';
import { ActionGroup, ActionGroupGrow } from 'components/common/actionGroup';
import { Button } from 'components/common/button';
import { Add, Close, Copy } from 'components/icons';
import { useApi } from 'hooks/useApi';
import { useTeamRefresh } from 'hooks/api/useTeamApi';
import { useCopyToClipboard } from 'hooks/useCoppyToClipboard';
import { useTranslation } from 'store/dictionary/selectors';
import { useUserLocation } from 'store/user/selectors';

export const AddMemberModal = ({ active, handleClose }) => {
    const [emailList, setEmailList] = useState(['']);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const sender = useApi('/me/invite', { method: 'post' });
    const reset = useTeamRefresh();
    const origin = window.location.origin;
    const clipboardCopier = useCopyToClipboard();
    const placeholder = useTranslation(`Invite by email...`);
    const location = useUserLocation();

    const handleChange = (event, index) => {
        const list = [...emailList];
        list[index] = event.target.value;
        setErrors({ ...errors, [`emails.${index}`]: null });
        setEmailList(list);
    };

    const handleAdd = () => {
        const list = [...emailList, ''];
        setEmailList(list);
    };

    const handleRemove = index => {
        const list = [...emailList];
        list.splice(index, 1);
        setEmailList(list);
    };

    const handleSave = async event => {
        event.preventDefault();
        setLoading(true);
        const res = await sender({
            body: JSON.stringify({ emails: emailList }),
        });
        const data = await res.json();
        if (!data.errors) {
            reset();
            setEmailList(['']);
            handleClose();
        } else {
            setErrors(data.errors);
        }
        setLoading(false);
    };

    if (!location) return null;
    return (
        <Modal
            title={<Text k={`Add New Member`} />}
            active={active}
            onClose={handleClose}
            actions={[
                {
                    label: <Text k={`Cancel`} />,
                    action: 'cancel',
                    secondary: true,
                },
                {
                    label: <Text k={`Invite`} />,
                    type: 'submit',
                    form: 'add-member-form',
                    loading,
                },
            ]}
        >
            <div>
                <ActionGroup bottom>
                    <ActionGroupGrow>
                        <Input
                            label={<Text k={`Share invite link`} />}
                            id={`add-member-link}`}
                            type={`text`}
                            readOnly
                            defaultValue={`${origin}/user/register-invited/${location.token}`}
                        />
                    </ActionGroupGrow>
                    <Button
                        icon={<Copy />}
                        iconOnly
                        secondary
                        onClick={() =>
                            clipboardCopier(
                                `${origin}/user/register-invited/${location.token}`
                            )
                        }
                    />
                </ActionGroup>
                <div className={styles.addMemberModal__separator}>
                    <Text k={`or`} />
                </div>
            </div>
            <form id={'add-member-form'} onSubmit={handleSave}>
                {emailList.map((email, index) => (
                    <FormItem key={index}>
                        <ActionGroup>
                            <ActionGroupGrow>
                                <Input
                                    id={`add-member-email-${index}`}
                                    type={`email`}
                                    onChange={event =>
                                        handleChange(event, index)
                                    }
                                    placeholder={placeholder}
                                    errors={errors[`emails.${index}`]}
                                    value={emailList[index]}
                                />
                            </ActionGroupGrow>
                            <Button
                                icon={<Close />}
                                iconOnly
                                secondary
                                disabled={index === 0}
                                onClick={() => handleRemove(index)}
                            />
                        </ActionGroup>
                    </FormItem>
                ))}
                <ActionGroup center>
                    <Button secondary icon={<Add />} onClick={handleAdd}>
                        <Text k={`Add Member`} />
                    </Button>
                </ActionGroup>
            </form>
        </Modal>
    );
};
