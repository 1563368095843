import styles from './publicCard.module.css';
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { useModifiers } from 'hooks/useModifiers';
import { PublicHeader } from 'components/layout/publicHeader';
import { ActionGroup } from 'components/common/actionGroup';

export interface PublicCardProps extends PropsWithChildren {
    title?: string | ReactNode;
    action?: ReactNode;
    wide?: boolean;
}

export const PublicCard: FunctionComponent<PublicCardProps> = ({ title, wide, action, children }) => {
    const mods = useModifiers(styles, 'publicCard', { wide });

    return (
        <div className={`${styles.publicCard__wrapper}`}>
            <PublicHeader />
            <div className={`${styles.publicCard} ${mods}`}>
                <div className={styles.publicCard__container}>
                    <div className={styles.publicCard__card}>
                        {title || action ? (
                            <div className={styles.publicCard__header}>
                                <h1 className={styles.publicCard__title}>{title}</h1>
                                {action ? <ActionGroup>{action}</ActionGroup> : null}
                            </div>
                        ) : (
                            ''
                        )}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};
