import { SET_NAV_OPEN, SET_NAV_STRUCTURE } from './constants';

export const navReducer = (
    state = {
        open: false,
        structure: null,
    },
    action
) => {
    switch (action.type) {
        case SET_NAV_OPEN: {
            return { ...state, open: action.payload };
        }
        case SET_NAV_STRUCTURE: {
            return { ...state, structure: action.payload };
        }
        default: {
            return state;
        }
    }
};
