import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { OrderFailPage } from 'pages/orderFail';
import { OrderSuccessPage } from 'pages/orderSuccess';

export const OrderResultPage: FunctionComponent = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const statusGroup = searchParams.get('statusGroup');

    if (statusGroup === 'failed') {
        return <OrderFailPage />;
    } else {
        return <OrderSuccessPage />;
    }
};
