import { useSelector } from 'react-redux';

export const useNewProvider = () => {
    return useSelector(({ providers: { new: newProvider } }) => newProvider);
};

export const useProviders = () => {
    return useSelector(({ providers: { data } }) => data);
};

export const useActiveProvider = () => {
    return useSelector(({ providers: { active } }) => active);
};
