import styles from './dishImageModal.module.css';
import { Modal } from 'components/layout/modal';
import { Text } from 'components/text';
import { useEditingImage } from 'store/dishes/selectors';
import { useDispatch } from 'react-redux';
import { setDishImageEditing } from 'store/dishes/actions';
import { FileInput } from 'components/common/fileInput';
import { FormEvent, FunctionComponent, useEffect, useState } from 'react';
import { Picture } from 'components/common/picture';
import { Button } from 'components/common/button';
import { useAddDishImageMutation, useRemoveDishImageMutation } from '../../../../api/dishes/endpoints';
import { Dish } from 'store/dishes/types';

export const DishImageModal: FunctionComponent<{ dishes: Dish[] }> = ({ dishes }) => {
    const dispatch = useDispatch();
    const dishId = useEditingImage();
    const dish = dishes.find(dish => dish.id === dishId);
    const [submitImage, { isLoading: loading, isSuccess, reset }] = useAddDishImageMutation();
    const [removeImage, { isLoading: removeLoading, isSuccess: removeSuccess, reset: removeReset }] =
        useRemoveDishImageMutation();
    const [file, setFile] = useState<File | null>(null);
    const [localImage, setLocalImage] = useState<string | null>(null);

    useEffect(() => {
        if (isSuccess) {
            reset();
            dispatch(setDishImageEditing(null));
        }
    }, [dispatch, isSuccess, reset]);

    useEffect(() => {
        if (removeSuccess) {
            removeReset();
            dispatch(setDishImageEditing(null));
        }
    }, [dispatch, removeSuccess, removeReset]);

    useEffect(() => {
        if (!dishId) {
            setFile(null);
        }
    }, [dishId]);

    useEffect(() => {
        if (file) {
            if (FileReader) {
                const fr = new FileReader();

                fr.onload = () => {
                    setLocalImage(fr.result as string);
                };
                fr.readAsDataURL(file);
            }
        } else {
            setLocalImage(null);
        }
    }, [file]);

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (file && dishId) {
            const formData = new FormData();
            console.log(file);
            formData.append('name', file);
            submitImage({ id: dishId, body: formData });
        }
    };

    const handleDelete = async () => {
        if (dishId) {
            removeImage({ id: dishId });
        }
    };

    return (
        <Modal
            title={<Text k={'Edit dish image'} />}
            active={!!dishId}
            onClose={() => dispatch(setDishImageEditing(null))}
            noScroll={true}
            actions={[
                {
                    label: <Text k={`Cancel`} />,
                    action: 'cancel',
                    secondary: true,
                },
                {
                    label: <Text k={`Upload`} />,
                    type: 'submit',
                    form: 'dish-image-form',
                    loading: loading,
                    disabled: !file,
                },
            ]}
        >
            {dish?.has_image || localImage ? (
                <div className={`${styles.dishImageModal__image} shadedBg`}>
                    {!localImage ? (
                        <>
                            <Picture apiUrl={`/dishes/${dishId}/image`} alt="" size={{ width: 500 }} />
                            <Button
                                // secondary
                                onClick={handleDelete}
                                loading={removeLoading}
                                className={styles.dishImageModal__imageAction}
                            >
                                <Text k={'Remove Image'} />
                            </Button>
                        </>
                    ) : (
                        <img src={localImage} alt={''} />
                    )}
                </div>
            ) : null}
            <form id={'dish-image-form'} onSubmit={handleSubmit}>
                <FileInput
                    callback={file => setFile(file)}
                    id={'dish-image-input'}
                    file={file}
                    accept="image/x-png,image/gif,image/jpeg"
                />
            </form>
        </Modal>
    );
};
