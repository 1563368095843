import { FunctionComponent, ReactNode, useState } from 'react';
import styles from './dataTable.module.css';
import { Text } from 'components/text';
import { Button } from 'components/common/button';
import { useSearchParam } from 'hooks/useSearchParam';
import { Pagination } from 'components/common/pagination';
import { BottomSmall, TopSmall } from 'components/icons';

type Field = {
    key: string;
    label: string | ReactNode;
    sorter?: (a: any, b: any, order: boolean) => number;
    processor?: (value: any, item: object) => any;
};

type ItemAction = {
    icon: ReactNode;
    onClick?: (item?: any) => void;
    active?: boolean | ((item?: any) => boolean);
    loading?: boolean | ((item?: any) => boolean);
    [x: string]: any;
};

const Item: FunctionComponent<{
    item: any;
    fields: Field[];
    itemActions?: ItemAction[];
}> = ({ item, fields, itemActions }) => {
    const getItemField = (item: any, { processor, key }: Field) => {
        const value = item[key];
        if (processor && typeof processor === 'function') {
            return processor(value, item);
        }
        return value;
    };

    const resolveProperty = (property: any, defaultValue?: any) => {
        if (typeof property === 'function') {
            return property(item);
        }
        return property !== undefined ? property : defaultValue;
    };

    return (
        <tr key={item.id} className={styles.table__item}>
            {fields.map((field, index) => (
                <td key={index}>{getItemField(item, field)}</td>
            ))}
            {itemActions ? (
                <td>
                    <div className={styles.table__controls}>
                        {itemActions.map(
                            ({ icon, onClick, active, ...rest }, index) => {
                                const props: { [x: string]: any } = {};
                                Object.keys(rest).forEach(key => {
                                    props[key] = resolveProperty(rest[key]);
                                });
                                if (onClick) {
                                    props.onClick = () => onClick(item);
                                }
                                return resolveProperty(active, true) ? (
                                    <Button
                                        key={index}
                                        secondary
                                        iconOnly
                                        icon={resolveProperty(icon)}
                                        {...props}
                                    />
                                ) : (
                                    ''
                                );
                            }
                        )}
                    </div>
                </td>
            ) : (
                ''
            )}
        </tr>
    );
};

export const DataTable: FunctionComponent<{
    placeholderAction?: string | ReactNode;
    data: object[];
    itemActions?: ItemAction[];
    fields: Field[];
    perPage?: number | 'all';
    customPagination?: ReactNode;
}> = ({
    placeholderAction = '',
    data = [],
    itemActions,
    fields,
    perPage = 20,
    customPagination,
}) => {
    const page = useSearchParam('page');
    const [sortingOrder, setSortingOrder] = useState(true);
    const [sortingKey, setSortingKey] = useState('');

    if (!data.length) {
        return (
            <div className={styles.table__placeholder}>
                <div className={styles.table__placeholderTitle}>
                    😧 <Text k={`There is nothing to display...`} />
                </div>
                {placeholderAction}
            </div>
        );
    }

    const getSortedData = () => {
        if (!sortingKey) return data;
        const field = fields.find(field => field.key === sortingKey);
        if (!field || !field.sorter) return data;
        const sortedData = [...data];
        sortedData.sort((a, b) =>
            // @ts-ignore
            field.sorter(a[sortingKey], b[sortingKey], sortingOrder)
        );
        return sortedData;
    };

    const sortedData = getSortedData();

    const getItems = () => {
        let subset = sortedData;
        if (perPage !== 'all') {
            const pageNumber = page ? parseInt(page) - 1 : 0;
            const start = pageNumber <= 0 ? 0 : pageNumber * perPage - 1;
            subset = sortedData.slice(start, start + perPage);
        }
        return subset.map(item => (
            <Item
                // @ts-ignore
                key={item.id}
                item={item}
                fields={fields}
                itemActions={itemActions}
            />
        ));
    };

    return (
        <>
            <div className={styles.table__wrapper}>
                <div className={styles.table__content}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                {fields.map(({ label, key, sorter }, index) => (
                                    <th key={`${key}-${index}`}>
                                        {sorter ? (
                                            <button
                                                className={
                                                    styles.table__sortingButton
                                                }
                                                onClick={() => {
                                                    if (sortingKey === key) {
                                                        setSortingOrder(
                                                            !sortingOrder
                                                        );
                                                    } else {
                                                        setSortingOrder(true);
                                                    }
                                                    setSortingKey(key);
                                                }}
                                            >
                                                <span
                                                    className={
                                                        styles.table__sortingButtonLabel
                                                    }
                                                >
                                                    {label}
                                                </span>
                                                {sortingKey === key ? (
                                                    <span
                                                        className={
                                                            styles.table__sortingButtonIcon
                                                        }
                                                    >
                                                        {sortingOrder ? (
                                                            <BottomSmall />
                                                        ) : (
                                                            <TopSmall />
                                                        )}
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                            </button>
                                        ) : (
                                            label
                                        )}
                                    </th>
                                ))}
                                {itemActions ? (
                                    <th>
                                        <Text k={`Actions`} />
                                    </th>
                                ) : (
                                    ''
                                )}
                            </tr>
                        </thead>
                        <tbody>{getItems()}</tbody>
                    </table>
                </div>
            </div>
            {!customPagination ? (
                perPage !== 'all' ? <Pagination perPage={perPage} data={data} /> : ''
            ) : (
                customPagination
            )}
        </>
    );
};
