import { createAction } from '@reduxjs/toolkit';
import {NewComplexDish} from "store/complexes/index";

// export const setNewComplex = complex => {
//     return {
//         type: SET_NEW_COMPLEX,
//         payload: complex,
//     };
// };

export const setNewComplex = createAction(`complexes/SET_NEW`, complex => ({
    payload: complex,
}));

// export const updateNewComplex = subset => {
//     return {
//         type: UPDATE_NEW_COMPLEX,
//         payload: subset,
//     };
// };

export const updateNewComplex = createAction(
    `complexes/UPDATE_NEW`,
    subset => ({
        payload: subset,
    })
);

// export const resetNewComplex = () => {
//     return {
//         type: RESET_NEW_COMPLEX,
//     };
// };

export const resetNewComplex = createAction(`complexes/RESET_NEW`);

// export const addComplexGroup = () => {
//     return {
//         type: ADD_COMPLEX_GROUP,
//     };
// };

export const addComplexGroup = createAction(`complexes/ADD_GROUP`);

// export const updateComplexGroup = (index, subset) => {
//     return {
//         type: UPDATE_COMPLEX_GROUP,
//         payload: { index, subset },
//     };
// };

export const updateComplexGroup = createAction(
    `complexes/UPDATE_GROUP`,
    (index: number, subset) => ({
        payload: { index, subset },
    })
);

// export const removeComplexGroup = index => {
//     return {
//         type: REMOVE_COMPLEX_GROUP,
//         payload: { index },
//     };
// };

export const removeComplexGroup = createAction(
    `complexes/REMOVE_GROUP`,
    (index: number) => ({
        payload: index,
    })
);

// export const addComplexDish = index => {
//     return {
//         type: ADD_COMPLEX_DISH,
//         payload: { index },
//     };
// };

export const addComplexDish = createAction(
    `complexes/ADD_DISH`,
    (index: number) => ({
        payload: index,
    })
);

// export const removeComplexDish = (index, dishIndex) => {
//     return {
//         type: REMOVE_COMPLEX_DISH,
//         payload: { index, dishIndex },
//     };
// };

export const removeComplexDish = createAction(
    `complexes/REMOVE_DISH`,
    (index: number, dishIndex: number) => ({
        payload: { index, dishIndex },
    })
);

// export const updateComplexDish = (index, dishIndex, subset) => {
//     return {
//         type: UPDATE_COMPLEX_DISH,
//         payload: { index, dishIndex, subset },
//     };
// };

export const updateComplexDish = createAction(
    `complexes/UPDATE_DISH`,
    (index: number, dishIndex: number, subset: Partial<NewComplexDish>) => ({
        payload: { index, dishIndex, subset },
    })
);
