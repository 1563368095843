import { api } from '../api';

const headerInfoApi = api.injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
        getHeaderInfo: builder.query<{ text: Transferable }, void>({
            query: () => ({
                url: '/app/block/header-info',
            }),
        }),
    }),
});

export const { useGetHeaderInfoQuery } = headerInfoApi;
