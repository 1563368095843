import { Text } from 'components/text';
import { useNewDish } from 'store/dishes/selectors';
import { resetNewDish, updateNewDish } from 'store/dishes/actions';
import { CategorySelect } from 'components/pages/categories/categorySelect';
import { EditModal } from 'components/common/editModal';
import { AllergensPicker } from 'components/pages/dishes/allergensPicker';
import { useNewDishMutation, useUpdateDishMutation } from '../../../../api/dishes/endpoints';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const NewDishModal = () => {
    const dispatch = useDispatch();
    const newDish = useNewDish();
    const [trigger, { isLoading, isSuccess, reset }] = useUpdateDishMutation();
    const [newTrigger, { isLoading: isNewLoading, isSuccess: isNewSuccess, reset: newReset }] = useNewDishMutation();

    useEffect(() => {
        if (isSuccess || isNewSuccess) {
            dispatch(resetNewDish());
            reset();
            newReset();
        }
    }, [isSuccess, dispatch, reset, isNewSuccess, newReset]);

    const handleSave = () => {
        //@ts-ignore
        if (newDish?.id) {
            trigger(newDish);
        } else {
            newTrigger(newDish);
        }
    };

    return (
        <EditModal
            reset={resetNewDish}
            update={updateNewDish}
            //@ts-ignore
            interceptSave={handleSave}
            loading={isLoading || isNewLoading}
            item={newDish}
            url={'/dishes'}
            formId={'new-dish-form'}
            title={<Text k={`New Dish`} />}
            fieldIdPrefix={'dish'}
            fields={[
                {
                    type: 'multilingual',
                    key: 'title',
                    label: <Text k={`Dish Title`} />,
                },
                {
                    type: 'input',
                    key: 'code',
                    label: <Text k={`Code`} />,
                },
                {
                    type: 'custom',
                    Component: CategorySelect,
                    key: 'category_id',
                    label: <Text k={`Dish Category`} />,
                },
                {
                    type: 'custom',
                    Component: AllergensPicker,
                    key: 'allergens',
                    label: <Text k={`Allergens`} />,
                },
                {
                    type: 'input',
                    key: 'price',
                    label: <Text k={`Price (€)`} />,
                    props: {
                        type: 'number',
                        step: 0.01,
                    },
                },
                {
                    type: 'checkbox',
                    key: 'status',
                    label: <Text k={`Active`} />,
                },
                {
                    type: 'multilingual',
                    key: 'summary',
                    label: <Text k={`Dish Summary`} />,
                    props: {
                        textarea: true,
                    },
                },
                {
                    type: 'input',
                    key: 'position',
                    label: <Text k={`Position`} />,
                    props: {
                        type: 'number',
                    },
                    shouldRender: (item: any) => item['id'],
                },
            ]}
        />
    );
};
