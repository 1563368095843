import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { FunctionComponent } from 'react';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { useLapsApi } from 'hooks/api/useLapsApi';
import { Text } from 'components/text';
import { LapsModal } from 'components/pages/laps/lapsModal';
import { LapList } from 'components/pages/laps/lapList';
import { ErrorBoundary } from 'components/common/errorBoudry';

export const MenuLapsPage: FunctionComponent = () => {
    useLapsApi();

    return (
        <ErrorBoundary boundary="menu-laps">
            <ContentGrid>
                <ContentBlock>
                    <h1 className={`h1 h1_noMargin`}>
                        <Text k={`Laps for menu`} />
                    </h1>
                </ContentBlock>
                <ContentBlock span={7}>
                    <ContentCard>
                        <LapList />
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
            <LapsModal />
        </ErrorBoundary>
    );
};
