import style from './newOrder.module.css';
import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { CurrentMenu } from 'components/pages/newOrder/curentMenu';
import { Cart } from 'components/pages/newOrder/cart';
import { Text } from 'components/text';
import {
    useMenu,
    useMenuUpdating,
    useMenuWaiting,
    useSelectedDishlist,
} from 'store/menu/selectors';
import { Loader } from 'components/common/loader';
import { CartRibbon } from 'components/pages/newOrder/cartRibbon';
import { useWatchMenu } from 'hooks/useWatchMenu';
import { NewOrderHeader } from 'components/pages/newOrder/header';
import { useUserListApi } from 'hooks/api/useUserListApi';
import { ErrorBoundary } from 'components/common/errorBoudry';
import { Button } from 'components/common/button';
import { useDispatch } from 'react-redux';
import { setAllExpanded } from 'store/expandedMenu/actions';
import { useIsAllExpanded } from 'store/expandedMenu/selectors';
import {
    useGetDishlistQuery,
    useGetDishlistsQuery,
} from '../../api/dishlists/endpoints';
import { useEffect } from 'react';
import {
    setAvailableMenus,
    setMenu,
    setMenuUpdating, setMenuWaiting,
} from 'store/menu/actions';

const Page = () => {
    const dispatch = useDispatch();
    const menu = useMenu();
    const waiting = useMenuWaiting();
    const updating = useMenuUpdating();
    const allExpanded = useIsAllExpanded();

    if (!menu)
        return (
            <ContentGrid>
                <ContentBlock>
                    {waiting ? (
                        <ContentCard>
                            <Loader />
                        </ContentCard>
                    ) : (
                        <ContentCard
                            center
                            title={<Text k={`No menu available for today`} />}
                        />
                    )}
                </ContentBlock>
            </ContentGrid>
        );
    return (
        <>
            <ContentGrid className={style.newOrder}>
                <ContentBlock>
                    <ContentCard accent>
                        <NewOrderHeader />
                    </ContentCard>
                </ContentBlock>
                <ContentBlock span={7} className={style.newOrder__menu}>
                    <ContentCard
                        loading={updating}
                        title={<Text k={`Menu`} />}
                        className={style.newOrder__menuCard}
                        action={
                            <Button
                                secondary
                                small
                                onClick={() => dispatch(setAllExpanded())}
                            >
                                {!allExpanded ? (
                                    <Text k={`Expand all`} />
                                ) : (
                                    <Text k={`Collapse all`} />
                                )}
                            </Button>
                        }
                    >
                        <CurrentMenu />
                    </ContentCard>
                </ContentBlock>
                <Cart />
            </ContentGrid>
            <CartRibbon />
        </>
    );
};

export const NewOrderPage = () => {
    const dispatch = useDispatch();
    const selectedDishlist = useSelectedDishlist();
    useUserListApi();
    const shouldRefresh = useWatchMenu();
    const {
        data: dishlist,
        isFetching: isDishlistFetching,
    } = useGetDishlistQuery(
        { id: selectedDishlist || '' },
        {
            skip: !selectedDishlist,
            pollingInterval: shouldRefresh ? 2000 : undefined,
        }
    );

    const { data: dishlists } = useGetDishlistsQuery(undefined, {
        pollingInterval: shouldRefresh ? 2000 : undefined,
    });

    useEffect(() => {
        if (isDishlistFetching) {
            dispatch(setMenuUpdating(isDishlistFetching));
        }
    }, [dispatch, isDishlistFetching, selectedDishlist]);

    useEffect(() => {
        if (dishlists) {
            dispatch(setAvailableMenus(dishlists.dishlists));
        }
    }, [dispatch, dishlists]);

    useEffect(() => {
        if (dishlists) {
            if(!dishlists.dishlists.length && !selectedDishlist) {
                dispatch(setMenuWaiting(false));
                dispatch(setMenu(null));
            }
        }
    }, [dispatch, dishlists, selectedDishlist]);

    useEffect(() => {
        if (dishlist) {
            dispatch(setMenu(dishlist));
        }
    }, [dispatch, dishlist]);

    return (
        <ErrorBoundary boundary="new-order">
            <Page />
        </ErrorBoundary>
    );
};
