import { createAction } from '@reduxjs/toolkit';
import { HistoryItem } from 'store/history/types';

export const setHistory = createAction(
    'history/SET_HISTORY',
    (history: HistoryItem[]) => {
        return {
            payload: history,
        };
    }
);
