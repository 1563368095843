import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { useBalanceApi } from 'hooks/api/useBalanceApi';
import { FunctionComponent } from 'react';
import { useBalance } from 'store/balance/selectors';
import { useMenuByIdApi } from 'hooks/api/useMenuByIdApi';
import { useNewMenu } from 'store/newMenu/selectors';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Loader } from 'components/common/loader';
import { HeaderBlock } from 'components/common/headerBlock';
import { Button } from 'components/common/button';
import { Left } from 'components/icons';
import { Text } from 'components/text';
import { EditBalanceList } from 'components/pages/editBalance/editBalanceList';
import { ErrorBoundary } from 'components/common/errorBoudry';
import { useParams } from 'react-router-dom';
import { useGetAllCategoriesQuery } from '../api/categories/endpoints';

export const EditBalance: FunctionComponent = () => {
    const { menuId = '' } = useParams();
    useBalanceApi(menuId);
    useMenuByIdApi(menuId);
    const balance = useBalance();
    const menu = useNewMenu();
    const { isFetching } = useGetAllCategoriesQuery({});

    const isLoading = () => {
        return !balance || isFetching;
    };

    return (
        <ErrorBoundary boundary="edit-balance">
            <ContentGrid>
                <ContentBlock>
                    <HeaderBlock
                        action={
                            <Button icon={<Left />} small secondary to={`/menu`}>
                                <Text k={`To menu list`} />
                            </Button>
                        }
                        className={`shaded`}
                    >
                        <h1 className={`h1 h1_noMargin`}>
                            <Text k={`Edit balance `} />
                            {menu.title ? `(${menu.title})` : null}
                        </h1>
                    </HeaderBlock>
                </ContentBlock>
                <ContentBlock span={7}>
                    <ContentCard>{isLoading() ? <Loader /> : <EditBalanceList />}</ContentCard>
                </ContentBlock>
            </ContentGrid>
        </ErrorBoundary>
    );
};
