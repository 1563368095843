import styles from './fileInput.module.css';
import { FunctionComponent, InputHTMLAttributes, ReactNode } from 'react';
import { Text } from 'components/text';
import { Button } from 'components/common/button';
import { Close } from 'components/icons';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    callback: (x: File | null) => void;
    label?: ReactNode | string;
    file: File | null;
}

export const FileInput: FunctionComponent<Props> = ({
    callback,
    label,
    file,
    id,
    ...rest
}) => {
    const getLabel = () => {
        if (label) return label;
        if (!file) return <Text k={'Select file to upload'} />;
        return;
    };

    return (
        <div className={`${styles.fileInput}`}>
            <div className={styles.fileInput__container}>
                <input
                    className={styles.fileInput__input}
                    type="file"
                    id={id}
                    onChange={event => {
                        if (event.target.files) {
                            callback(event.target.files[0]);
                        } else {
                            callback(null);
                        }
                        event.target.value = '';
                    }}
                    {...rest}
                />
                <label className={styles.fileInput__label} htmlFor={id}>
                    <span className={styles.fileInput__labelIcon}></span>
                    <span className={styles.fileInput__labelContent}>
                        {file ? <span>{file.name}</span> : ''}
                        <span>{getLabel()}</span>
                    </span>
                </label>
            </div>
            {file ? (
                <div className={`${styles.fileInput__remove} shadedBg`}>
                    <Button
                        secondary
                        type="button"
                        onClick={() => callback(null)}
                        icon={<Close />}
                        iconOnly
                    />
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
