import { useApiData } from 'hooks/useApiData';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {resetKDS, setKDS, setKDSUpdating} from 'store/kds/actions';
import { useApi } from 'hooks/useApi';

export const useKDSApi = (menuId: number | string) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(resetKDS());
    }, [dispatch]);
    useApiData(`/kitchen/${menuId}`, setKDS);
};

export const useKDSRefresh = (menuId: number | string) => {
    const dispatch = useDispatch();
    const getKDS = useApi(`/kitchen/${menuId}`);

    return async () => {
        dispatch(setKDSUpdating(true))
        const res = await getKDS();
        dispatch(setKDS((await res.json())));
    };
};
