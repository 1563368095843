import styles from './infoTag.module.css';
import { FunctionComponent, HTMLAttributes } from 'react';

export interface InfoTagProps extends HTMLAttributes<HTMLDivElement> {}

export const InfoTag: FunctionComponent<InfoTagProps> = ({
    children,
    className,
    ...rest
}) => {
    return (
        <div className={`${styles.infoTag} ${className}`} {...rest}>
            {children}
        </div>
    );
};
