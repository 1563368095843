import { useSelector } from 'react-redux';

export const selectToken = ({ common: { token } }) => token;

export const useToken = () => {
    return useSelector(selectToken);
};

export const useMaintenance = () => {
    return useSelector(({ common: { maintenance } }) => maintenance);
};

export const useActiveLang = () => {
    return useSelector(({ common: { lang } }) => lang);
};

export const useLanguages = () => {
    return useSelector(({ common: { languages } }) => languages);
};

export const useIsLoading = () => {
    const loading = useSelector(({ common: { loading } }) => loading);
    return loading && !!loading.length;
};

export const useRequestMode = () => {
    return useSelector(({ common: { requestMode } }) => requestMode);
};
