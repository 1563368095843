import { NewCategory } from 'store/categories/types';
import { createReducer } from '@reduxjs/toolkit';
import { resetNewCategory, setNewCategory, updateNewCategory } from 'store/categories/actions';

export type CategoriesReducer = {
    loading: boolean;
    new: NewCategory;
};

const initialState: CategoriesReducer = {
    loading: true,
    new: { title: {}, active: false, status: true, simple: false },
};

export const categoriesReducer = createReducer(initialState, builder => {
    builder.addCase(setNewCategory, (state, { payload }) => {
        state.new = payload;
    });
    builder.addCase(updateNewCategory, (state, { payload }) => {
        state.new = { ...state.new, ...payload };
    });
    builder.addCase(resetNewCategory, state => {
        state.new = initialState.new;
    });
});
