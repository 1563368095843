import { Text } from 'components/text';
import { useNewCategory } from 'store/categories/selectors';
import { resetNewCategory, updateNewCategory } from 'store/categories/actions';
import { EditModal } from 'components/common/editModal';
import { useNewCategoryMutation, useUpdateCategoryMutation } from '../../../../api/categories/endpoints';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const NewCategoryModal = () => {
    const dispatch = useDispatch();
    const newCategory = useNewCategory();
    const [trigger, { isLoading, isSuccess, reset }] = useUpdateCategoryMutation();
    const [newTrigger, { isLoading: isNewLoading, isSuccess: isNewSuccess, reset: newReset }] =
        useNewCategoryMutation();

    useEffect(() => {
        if (isSuccess || isNewSuccess) {
            dispatch(resetNewCategory());
            reset();
            newReset();
        }
    }, [isSuccess, dispatch, reset, isNewSuccess, newReset]);

    const handleSave = () => {
        if (newCategory?.id) {
            trigger(newCategory);
        } else {
            newTrigger(newCategory);
        }
    };

    return (
        <EditModal
            title={<Text k={`New Category`} />}
            update={updateNewCategory}
            reset={resetNewCategory}
            //@ts-ignore
            interceptSave={handleSave}
            loading={isLoading || isNewLoading}
            item={newCategory}
            fieldIdPrefix={'category'}
            url={'/categories'}
            fields={[
                {
                    type: 'multilingual',
                    key: 'title',
                    label: <Text k={`Category Title`} />,
                },
                {
                    type: 'checkbox',
                    key: 'status',
                    label: <Text k={`Active`} />,
                },
                {
                    type: 'checkbox',
                    key: 'simple',
                    label: <Text k={`Simple`} />,
                },
                {
                    type: 'input',
                    key: 'group_name',
                    label: <Text k={`Group Name`} />,
                },
                {
                    type: 'input',
                    key: 'position',
                    label: <Text k={`Position`} />,
                    props: {
                        type: 'number',
                    },
                    //@ts-ignore
                    shouldRender: item => item['id'],
                },
            ]}
            formId={'new-category-form'}
        />
    );
};
