import { useState, useEffect } from 'react';
import { Modal } from 'components/layout/modal';
import { Text } from 'components/text';
import { Form } from 'components/layout/form';
import { Input } from 'components/common/input';
import { Checkbox } from 'components/common/checkbox';
import { useApi } from 'hooks/useApi';
import { useDispatch } from 'react-redux';
import { MultilingualField } from 'components/common/multilingualField';
import { Loader } from 'components/common/loader';

export const EditModal = ({
    reset,
    update,
    refresh = () => {},
    item,
    url,
    formId,
    title,
    fields = [],
    fieldIdPrefix,
    noScroll,
    interceptSave = false,
    loading = false,
    errors: initialErrors = {},
}) => {
    const dispatch = useDispatch();
    const { id, active, waiting = false, saving } = item;
    const sender = useApi(url, { method: 'post' });
    const [errors, setErrors] = useState(initialErrors);

    useEffect(() => {
        setErrors(initialErrors);
    }, [initialErrors]);

    useEffect(() => {
        setErrors({});
    }, [id]);

    const handleTranslatableChange = (e, lang, key) => {
        setErrors({ ...errors, [key]: null });
        dispatch(
            update({
                [key]: { ...item[key], [lang]: e.target.value },
            })
        );
    };

    const handleFieldChange = (value, key) => {
        setErrors({ ...errors, [key]: null });
        dispatch(update({ [key]: value }));
    };

    const handleClose = () => {
        if (id) {
            dispatch(reset());
        } else {
            dispatch(update({ active: false }));
        }
    };

    const handleSave = async e => {
        if (interceptSave) {
            interceptSave();
            return;
        }
        const { id, active, saving, ...rest } = item;
        dispatch(update({ saving: true }));

        const res = await sender({
            body: JSON.stringify(rest),
            route: id ? `/${id}` : undefined,
            method: id ? 'put' : 'post',
        });
        console.log(res);
        const data = await res.json();
        if (!data.errors) {
            await refresh();
            dispatch(reset());
            e.target.reset();
        } else {
            setErrors(data.errors);
        }
        dispatch(update({ saving: false }));
    };

    const getFields = () => {
        return fields.map(({ type, key, label, props, Component, shouldRender = true, disabled }, index) => {
            if (!shouldRender || (typeof shouldRender === 'function' && !shouldRender(item))) {
                return '';
            }

            if (typeof disabled === 'function') {
                disabled = disabled(item);
            }

            const genericProps = {
                id: `${fieldIdPrefix}-${key}`,
                key: key,
                value: item[key] || '',
                label: label,
                errors: errors[key],
                disabled,
                ...props,
            };

            switch (type) {
                case 'multilingual': {
                    return (
                        <MultilingualField
                            changeHandler={(e, lang) => handleTranslatableChange(e, lang, key)}
                            {...genericProps}
                        />
                    );
                }
                case 'custom': {
                    return (
                        <Component onChange={event => handleFieldChange(event.target.value, key)} {...genericProps} />
                    );
                }
                case 'input': {
                    return <Input onChange={event => handleFieldChange(event.target.value, key)} {...genericProps} />;
                }
                case 'checkbox': {
                    return (
                        <Checkbox
                            onChange={event => handleFieldChange(event.target.checked, key)}
                            checked={!!item[key]}
                            {...genericProps}
                            value={1}
                        />
                    );
                }
                case 'slot': {
                    return <Component key={index} />;
                }
                default: {
                    return '';
                }
            }
        });
    };

    return (
        <Modal
            title={title}
            active={active}
            onClose={handleClose}
            noScroll={noScroll}
            actions={[
                {
                    label: <Text k={`Cancel`} />,
                    action: 'cancel',
                    secondary: true,
                },
                {
                    label: <Text k={`Save`} />,
                    type: 'submit',
                    form: formId,
                    loading: saving || loading,
                },
            ]}
        >
            {waiting ? (
                <Loader />
            ) : (
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        handleSave(e);
                    }}
                    id={formId}
                >
                    {getFields()}
                </Form>
            )}
        </Modal>
    );
};
