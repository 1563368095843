import styles from './dishSelector.module.css';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { Input } from 'components/common/input';
import { useTranslator } from 'hooks/useTranslator';
import { PriceTag } from 'components/common/priceTag/priceTag';
import { CategorySelect } from 'components/pages/categories/categorySelect';
import { useTranslation } from 'store/dictionary/selectors';
import { useGetDishesQuery } from '../../../../api/dishes/endpoints';
import { Dish } from 'store/dishes/types';

export const DishSelector: FunctionComponent<{
    onLoading: (loading: boolean) => void;
    isSelected: (dish: Dish) => boolean;
    onSelect: (dish: Dish) => void;
}> = ({ onLoading, isSelected, onSelect }) => {
    const translator = useTranslator();

    const [search, setSearch] = useState('');
    const [category, setCategory] = useState('');
    const { data: dishes, isFetching } = useGetDishesQuery(
        { category, search, page: 1, perPage: 20, activeOnly: true }
    );
    const filterPlaceholder = useTranslation(`Search...`);

    useEffect(() => {
        onLoading(isFetching);
    }, [onLoading, isFetching]);

    const getResults = () => {
        if (!dishes) return null;
        return dishes.data.map(dish => {
            return (
                <button
                    key={dish.id}
                    className={`${styles.dishSelector__item} ${
                        isSelected(dish) ? styles.dishSelector__item_active : ''
                    } shaded`}
                    onClick={() => onSelect(dish)}
                >
                    <span className={styles.dishSelector__itemRow}>
                        <span className={styles.dishSelector__itemContent}>
                            <span className={styles.dishSelector__itemTitle}>{translator(dish.title)}</span>
                            <span className={styles.dishSelector__itemCategory}>{translator(dish.category.title)}</span>
                        </span>
                        <span className={styles.dishSelector__itemPrice}>
                            <PriceTag price={dish.price} secondary />
                        </span>
                    </span>
                </button>
            );
        });
    };

    return (
        <div className={styles.dishSelector}>
            <div className={styles.dishSelector__filter}>
                <Input
                    id={'new-menu-dish-filter'}
                    value={search}
                    placeholder={filterPlaceholder}
                    onChange={event => setSearch(event.target.value)}
                />
                <CategorySelect
                    id={`new-menu-dish-category`}
                    value={category}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setCategory(event.target.value);
                    }}
                />
            </div>
            {dishes ? <div className={styles.dishSelector__grid}>{getResults()}</div> : null}
        </div>
    );
};
