import { createReducer } from '@reduxjs/toolkit';
import { setBalance, setEditedBalance, wipeBalance, wipeEdited } from 'store/balance/actions';
import { CompactMenuItem } from 'store/newMenu/types';

export type Balance = {
    id: number | string;
    title: string;
    from: string;
    to: string;
    status: boolean;
    provider_id: number | string;
    balance: CompactMenuItem[];
};

export type BalanceReducer = {
    balance: Balance | null;
    loading: boolean;
    edited: CompactMenuItem[];
};

const initialState: BalanceReducer = {
    balance: null,
    loading: true,
    edited: [],
};

export const balanceReducer = createReducer(initialState, builder => {
    builder.addCase(setBalance, (state, { payload }) => {
        state.balance = payload;
        state.loading = false;
    });
    builder.addCase(wipeBalance, state => {
        state.balance = initialState.balance;
    });
    builder.addCase(setEditedBalance, (state, { payload: { id, qty } }) => {
        const balance = state.balance?.balance.find(item => item.id == id);
        let keepDefault = balance && balance.qty === qty;

        const index = state.edited.findIndex(item => item.id == id);
        if (index < 0) {
            if (!keepDefault && balance) {
                state.edited.push({ id, qty, category_id: balance?.category_id });
            }
        } else {
            if (!keepDefault) {
                state.edited[index].qty = qty;
            } else {
                state.edited.splice(index, 1);
            }
        }
    });
    builder.addCase(wipeEdited, state => {
        state.edited = initialState.edited;
    });
});
