import { useEffect, useState } from 'react';
import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Text } from 'components/text';
import { useApi } from 'hooks/useApi';
import { useTranslator } from 'hooks/useTranslator';
import { Loader } from 'components/common/loader';
import { ErrorBoundary } from 'components/common/errorBoudry';

export const TermsPage = () => {
    const getText = useApi('/block/terms-and-conditions');
    const [text, setText] = useState();
    const translator = useTranslator();

    useEffect(() => {
        const request = async () => {
            const res = await getText();
            const data = await res.json();
            setText(data.text);
        };
        if (!text) {
            request();
        }
    }, [text, setText, getText]);

    const getTextBlock = () => {
        return (
            <div
                className={'text'}
                dangerouslySetInnerHTML={{ __html: translator(text) }}
            />
        );
    };

    return (
        <ErrorBoundary boundary="teams">
            <ContentGrid>
                <ContentBlock>
                    <ContentCard
                        header
                        title={<Text k={`Terms and Conditions`} />}
                    >
                        {text ? getTextBlock() : <Loader />}
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
        </ErrorBoundary>
    );
};
