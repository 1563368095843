import { ChangeEvent, FunctionComponent } from 'react';
import { useGetAllergensQuery } from '../../../../api/allergens/endpoints';
import { Loader } from 'components/common/loader';
import { Select } from 'components/common/select';
import { useTranslator } from 'hooks/useTranslator';
import { useTranslation } from 'store/dictionary/selectors';
import { Button } from 'components/common/button';
import { ActionGroup } from 'components/common/actionGroup';
import { Close } from 'components/icons';

export const AllergensPicker: FunctionComponent<{
    onChange: (allergens: { target: { value: string[] } }) => void;
    value: string[];
}> = ({ onChange, value }) => {
    const { data: allergens, isLoading } = useGetAllergensQuery();
    const translator = useTranslator();
    const label = useTranslation('Add allergen');

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        console.log(event.target.value);
        console.log(onChange);
        if (value.includes(event.target.value)) return;
        onChange({ target: { value: [...value, event.target.value] } });
    };

    if (isLoading || !allergens) return <Loader />;
    return (
        <>
            <Select
                label={label}
                value={''}
                options={[
                    { label, key: '' },
                    ...allergens.data.map(allergen => ({ label: translator(allergen.title), key: allergen.id })),
                ]}
                onChange={handleChange}
            />
            <ActionGroup>
                {value.map(allergen => (
                    <Button
                        key={allergen}
                        secondary
                        icon={<Close />}
                        onClick={() => onChange({ target: { value: value.filter(item => item !== allergen) } })}
                    >
                        {translator(allergens.data.find(item => item.id === allergen)?.title)}
                    </Button>
                ))}
            </ActionGroup>
        </>
    );
};
