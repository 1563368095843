import { Link } from 'react-router-dom';
import logo from 'images/logo.svg';
import { FunctionComponent, HTMLAttributes } from 'react';

interface LogoProps extends HTMLAttributes<HTMLImageElement> {}

export const Logo: FunctionComponent<LogoProps> = ({ ...rest }) => {
    return (
        <Link to={`/`}>
            <img src={logo} alt={'Lunch.Work'} {...rest} />
        </Link>
    );
};
