import styles from './lapLocations.module.css';
import { useLapLocations } from 'store/lap/selectors';
import { LapDishes } from 'components/pages/lap/lapDishes';
import { LocationTitle } from 'components/common/locationTitle';
import { Input } from 'components/common/input';
import { FormEvent, FunctionComponent, useState } from 'react';
import { LapLocation } from 'store/lap/types';
import { ActionGroup } from 'components/common/actionGroup';
import { Button } from 'components/common/button';
import { Text } from 'components/text';
import { ListCard } from 'components/common/listCard';
import { useApi } from 'hooks/useApi';
import { useDispatch } from 'react-redux';
import { setLapCurrentCash } from 'store/lap/actions';
import { Download } from 'components/icons';
import { useParams } from 'react-router-dom';

const Location: FunctionComponent<{ location: LapLocation }> = ({
    location,
}) => {
    const dispatch = useDispatch();
    const [cash, setCash] = useState('');
    const [loading, setLoading] = useState(false);
    const { menuId } = useParams();
    const request = useApi(`/cashbalance/${menuId}`, { method: 'post' });

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setLoading(true);
        const data = {
            location_id: location.id,
            amount: cash,
        };
        try {
            const res = await request({ body: JSON.stringify(data) });
            if (res.ok) {
                const { amount } = await res.json();
                dispatch(setLapCurrentCash(location.id, amount));
                setCash('');
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <div
            key={location.id}
            className={`${styles.lapLocations__location} shaded`}
        >
            <div className={styles.lapLocations__header}>
                <div className={styles.lapLocations__headerContent}>
                    <LocationTitle
                        className={styles.lapLocations__headerTitle}
                        location={location}
                    />
                    <ListCard
                        data={[
                            {
                                label: <Text k={`Balance`} />,
                                value: location.amounts.cash_balance,
                            },
                            {
                                label: <Text k={`Current`} />,
                                value: location.amounts.current_payment,
                            },
                        ]}
                    />
                </div>
            </div>
            <div className={styles.lapLocations__content}>
                <LapDishes dishes={location.dishes} noMargin />
            </div>
            <div className={styles.lapLocations__footer}>
                <Button type="button" iconOnly icon={<Download />} secondary />
                <form onSubmit={handleSubmit}>
                    <ActionGroup>
                        <Input
                            type="number"
                            value={cash}
                            onChange={event => setCash(event.target.value)}
                        />
                        <Button
                            type="submit"
                            disabled={!cash}
                            loading={loading}
                        >
                            <Text k={`Submit`} />
                        </Button>
                    </ActionGroup>
                </form>
            </div>
        </div>
    );
};

export const LapLocations = () => {
    const locations = useLapLocations();

    if (!locations) return null;
    return (
        <div className={styles.lapLocations}>
            {locations.map(location => {
                return <Location key={location.id} location={location} />;
            })}
        </div>
    );
};
