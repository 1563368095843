import styles from './menuComplexGroup.module.css';
import { useTranslator } from 'hooks/useTranslator';
import { PriceTag } from 'components/common/priceTag/priceTag';
import { Lock, Tick } from 'components/icons';
import { useModifiers } from 'hooks/useModifiers';
import { Text } from 'components/text';
import { QuantityTag } from 'components/common/quantityTag';
import React, { useEffect } from 'react';
import { Allergens } from 'components/pages/newOrder/allergens';

export const MenuComplexGroup = ({
    group,
    complexId,
    selected = false,
    handleSelect,
    display,
}) => {
    const { title, dishes, id, optional } = group;
    const translator = useTranslator();
    const mods = useModifiers(styles, 'menuComplexGroup', {
        selected,
        optional,
    });

    useEffect(() => {
        if (dishes.length === 1 && !optional) {
            handleSelect(dishes[0].id, id);
        }
    }, [handleSelect, dishes, optional, id]);

    return (
        <div className={`${styles.menuComplexGroup} ${mods}`}>
            <div className={styles.menuComplexGroup__title}>
                {translator(title)}{' '}
                {!optional && !display ? (
                    <div className={styles.menuComplexGroup__required}>
                        <Lock />
                        <span>
                            <Text k={`Required`} />
                        </span>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div className={styles.menuComplexGroup__itemGrid}>
                {dishes.map(dish => (
                    <div
                        key={dish.id}
                        className={styles.menuComplexGroup__item}
                    >
                        {!display ? (
                            <input
                                id={`dish-input-${complexId}-${id}-${dish.id}`}
                                type={!optional ? 'radio' : 'checkbox'}
                                name={
                                    !optional
                                        ? `dish-input-${complexId}-${id}`
                                        : `dish-input-${complexId}-${id}-${dish.id}`
                                }
                                key={dish.id}
                                checked={selected === dish.id}
                                onChange={event => {
                                    handleSelect(
                                        event.target.checked ? dish.id : false,
                                        id
                                    );
                                }}
                            />
                        ) : null}
                        {!display ? (
                            <label
                                htmlFor={`dish-input-${complexId}-${id}-${dish.id}`}
                                className={styles.menuComplexGroup__itemLabel}
                            >
                                <span>
                                    {translator(dish.title)}{' '}
                                    <Allergens allergens={dish.allergens} />
                                </span>
                                <span
                                    className={
                                        styles.menuComplexGroup__itemActions
                                    }
                                >
                                    <QuantityTag quantity={dish.qty} />
                                    <PriceTag
                                        noPaddingVertical
                                        secondary
                                        price={dish.price}
                                    />
                                    <span
                                        className={
                                            styles.menuComplexGroup__itemTick
                                        }
                                    >
                                        <Tick />
                                    </span>
                                </span>
                            </label>
                        ) : (
                            <div
                                className={`${styles.menuComplexGroup__itemLabel} ${styles.menuComplexGroup__itemLabel_display}`}
                            >
                                <span>{translator(dish.title)}</span>
                                <span
                                    className={
                                        styles.menuComplexGroup__itemActions
                                    }
                                >
                                    <QuantityTag quantity={dish.qty} />
                                    <PriceTag
                                        noPaddingVertical
                                        secondary
                                        price={dish.price}
                                    />
                                </span>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
