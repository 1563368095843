import styles from './lapUnasignedList.module.css';
import { useLaps } from 'store/laps/selectors';

export const LapUnassignedList = () => {
    const laps = useLaps();
    if (!laps) return null;

    const unassigned = laps.find(lap => !lap.id);
    if (!unassigned) return null;

    return (
        <div className={styles.lapUnassignedList}>
            {unassigned.locations.map(location => (
                <div className={styles.lapUnassignedList__item} key={location.id}>
                    {location.title}
                </div>
            ))}
        </div>
    );
};
