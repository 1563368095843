import styles from './lapLocationsTitle.module.css';
import { Text } from 'components/text';
import { usePriceFormatter } from 'hooks/useFormattedPrice';
import { useLocationTotal } from 'store/lap/selectors';

export const LapLocationsTitle = () => {
    const total = useLocationTotal();
    const formatter = usePriceFormatter();

    if (!total) return null;
    return (
        <div className={styles.lapLocationsTitle}>
            <h2 className={`h2 h2_noMargin`}>
                <Text k={`Total`} />
            </h2>
            <div className={styles.lapLocationsTitle__price}>{formatter(total.current_payment)}</div>
        </div>
    );
};
