import { createReducer } from '@reduxjs/toolkit';
import { Dish } from 'store/dishes/types';
import { Translatable } from 'types/translatable';
import {
    resetNewDish,
    setDishes,
    setDishesUpdating,
    setDishImageEditing,
    setFilteredDishes,
    setNewDish,
    setSearchCategory,
    setSearchQuery,
    updateNewDish,
} from 'store/dishes/actions';

export type EditingDishType =
    | {
          title: Translatable | {};
          summary: Translatable | {};
          active: boolean;
          status: boolean;
          price: string;
          category_id: string;
          allergens: string[];
      }
    | Dish;

const initialState: {
    data: Dish[];
    filtered: null | Dish[];
    loading: boolean;
    updating: boolean;
    imageEditing: string | null;
    searchQuery: string;
    searchCategory: string;
    new: EditingDishType;
} = {
    data: [],
    filtered: null,
    loading: true,
    updating: false,
    imageEditing: null,
    searchQuery: '',
    searchCategory: '',
    new: {
        title: {},
        summary: {},
        active: false,
        status: true,
        price: '',
        category_id: '',
        allergens: [],
    },
};

export const dishesReducer = createReducer(initialState, builder => {
    builder.addCase(setNewDish, (state, { payload }) => {
        state.new = payload;
    });
    builder.addCase(updateNewDish, (state, { payload }) => {
        state.new = { ...state.new, ...payload };
    });
    builder.addCase(resetNewDish, state => {
        state.new = initialState.new;
    });
    builder.addCase(setDishes, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
    });
    builder.addCase(setFilteredDishes, (state, { payload }) => {
        state.filtered = payload;
    });
    builder.addCase(setDishesUpdating, (state, { payload }) => {
        state.updating = payload;
    });
    builder.addCase(setDishImageEditing, (state, { payload: id }) => {
        state.imageEditing = id;
    });
    builder.addCase(setSearchQuery, (state, { payload: query }) => {
        state.searchQuery = query;
    });
    builder.addCase(setSearchCategory, (state, { payload: category }) => {
        state.searchCategory = category;
    });
});
