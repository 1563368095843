import {RESET_USER_FIELDS, SET_USER, SET_USER_FIELD} from './constants';

export const userReducer = (
    state = {
        edited: {},
    },
    action
) => {
    switch (action.type) {
        case SET_USER: {
            return { ...state, ...action.payload };
        }
        case SET_USER_FIELD: {
            const { key, value } = action.payload;
            const edited = { ...state.edited };
            if (value === state[key]) {
                if (edited[key] !== null && edited[key] !== undefined) {
                    delete edited[key];
                }
                return {
                    ...state,
                    edited,
                };
            }
            return {
                ...state,
                edited: { ...edited, [key]: value },
            };
        }
        case RESET_USER_FIELDS: {
            return {
                ...state,
                edited: {},
            };
        }

        default: {
            return state;
        }
    }
};
