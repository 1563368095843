import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LoginPage } from 'pages/system/login';
import { Layout } from 'components/layout/layout';
import { useDictionaryApi } from 'hooks/api/useDictionaryApi';
import { UserCreatePage } from 'pages/system/userCreate';
import { RequestResetPasswordPage } from 'pages/system/requestResetPassword';
import { ResetPasswordPage } from 'pages/system/resetPassword';
import { PasswordCreatePage } from 'pages/system/passwordCreate';
import { MaintenancePage } from 'pages/maintenance';
import { useMaintenance } from 'store/common/selectors';
import { PublicMenuPage } from 'pages/public/menu';

export const App = () => {
    useDictionaryApi();
    const maintenance = useMaintenance();

    if (maintenance) return <MaintenancePage />;
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/login'} element={<LoginPage />} />
                <Route path={'/password/request'} element={<RequestResetPasswordPage />} />
                <Route path={'/password/reset/:hash'} element={<ResetPasswordPage />} />
                <Route path={'/password/create/:hash'} element={<PasswordCreatePage />} />
                <Route path={'/user/register-invited/:hash'} element={<UserCreatePage />} />
                <Route path={'/public/menu'} element={<PublicMenuPage />} />
                <Route path={'/*'} element={<Layout />} />
            </Routes>
        </BrowserRouter>
    );
};
