import styles from './input.module.css';
import { useModifiers } from 'hooks/useModifiers';
import { ErrorContainer } from 'components/common/errorContainer';
import { FunctionComponent, InputHTMLAttributes, ReactNode } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string | ReactNode;
    tag?: string | ReactNode;
    textarea?: boolean;
    errors?: string[];
}

export const Input: FunctionComponent<Props> = ({
    label,
    className = '',
    tag,
    id,
    textarea,
    disabled,
    errors,
    ...rest
}) => {
    const mods = useModifiers(styles, 'input', { textarea, disabled, errors });

    return (
        <div className={`${styles.input} ${mods} ${className}`}>
            {label ? (
                <label className={styles.input__label} htmlFor={id}>
                    {label}
                </label>
            ) : null}
            <div className={styles.input__fieldContainer}>
                {tag ? (
                    <div className={styles.input__fieldTag}>
                        <span>{tag}</span>
                    </div>
                ) : null}
                {!textarea ? (
                    <input
                        className={styles.input__field}
                        id={id}
                        disabled={disabled}
                        {...rest}
                    />
                ) : (
                    //@ts-ignore
                    <textarea
                        className={styles.input__field}
                        id={id}
                        {...rest}
                    />
                )}
                <div className={styles.input__fieldFocusRing} />
            </div>
            <ErrorContainer errors={errors} />
        </div>
    );
};
