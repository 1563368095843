import { Category } from 'store/categories/types';
import { createAction } from '@reduxjs/toolkit';

export const setNewCategory = createAction(
    `categories/SET_NEW_CATEGORY`,
    (newCategory: Category) => {
        return {
            payload: newCategory,
        };
    }
);

export const updateNewCategory = createAction(
    `categories/UPDATE_NEW_CATEGORY`,
    subset => {
        return {
            payload: subset,
        };
    }
);

export const resetNewCategory = createAction(`categories/RESET_NEW_CATEGORY`);

