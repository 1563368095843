import { createAction } from '@reduxjs/toolkit';
import {Dish} from "store/dishes/types";

export const updateNewMenu = createAction('newMenu/UPDATE', subset => {
    return {
        payload: subset,
    };
});

export const setNewMenu = createAction('newMenu/SET', menu => {
    return {
        payload: menu,
    };
});

export const resetNewMenu = createAction('newMenu/RESET');

export const toggleSelectedDish = createAction(
    'newMenu/TOGGLE_DISH',
    (dishToToggle: Dish) => {
        return {
            payload: dishToToggle,
        };
    }
);

export const removeSelectedDish = createAction(
    'newMenu/REMOVE_DISH',
    (dishId: string) => {
        return {
            payload: dishId,
        };
    }
);

export const editSelectedDishPrice = createAction(
    'newMenu/DISH_PRICE_EDIT',
    (dishId: string, price: number | string) => {
        return {
            payload: { dishId, price },
        };
    }
);

export const resetSelectedDish = createAction(
    'newMenu/RESET_DISH',
    (dishId: string) => {
        return {
            payload: dishId,
        };
    }
);

export const enableEditSelectedComplex = createAction(
    'newMenu/ENABLE_COMPLEX_EDIT',
    (dishId: string) => {
        return {
            payload: dishId,
        };
    }
);

export const editSelectedComplexDishPrice = createAction(
    'newMenu/EDIT_COMPLEX_DISH_PRICE',
    (complexId: string, dishId: string, price: number | string) => {
        return {
            payload: { complexId, dishId, price },
        };
    }
);

export const resetSelectedComplexDish = createAction(
    'newMenu/RESET_COMPLEX_DISH',
    (complexId: string, dishId: string) => {
        return { payload: { complexId, dishId } };
    }
);
