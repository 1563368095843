import styles from './select.module.css';
import { useModifiers } from 'hooks/useModifiers';
import { ErrorContainer } from 'components/common/errorContainer';
import { Expand } from 'components/icons';
import { FunctionComponent, InputHTMLAttributes, ReactNode } from 'react';

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
    label?: string | ReactNode;
    errors?: string[];
    options: { label: string | ReactNode; key: string | number }[];
    emptyLabel?: string | ReactNode;
}

export const Select: FunctionComponent<Props> = ({
    options = [],
    value,
    label,
    id,
    emptyLabel,
    errors,
    className,
    ...rest
}) => {
    const mods = useModifiers(styles, 'select', { errors });

    return (
        <div className={`${styles.select} ${mods} ${className}`}>
            {label ? (
                <label className={styles.select__label} htmlFor={id}>
                    {label}
                </label>
            ) : (
                ''
            )}
            <div className={styles.select__wrapper}>
                <div className={styles.select__container}>
                    <select
                        className={styles.select__select}
                        value={value}
                        id={id}
                        {...rest}
                    >
                        {emptyLabel ? (
                            <option value="">{emptyLabel}</option>
                        ) : null}
                        {options.map(({ label, key }) => (
                            <option key={key} value={key}>
                                {label}
                            </option>
                        ))}
                    </select>
                    <div className={styles.select__icon}>
                        <Expand />
                    </div>
                </div>
                <ErrorContainer errors={errors} />
            </div>
        </div>
    );
};
