import { createReducer } from '@reduxjs/toolkit';
import { UserListRecord } from 'store/userList/types';
import { setUserList } from 'store/userList/actions';

const initialState: {
    list: UserListRecord[] | null;
} = {
    list: null,
};

export const userListReducer = createReducer(initialState, builder => {
    builder.addCase(setUserList, (state, { payload }) => {
        state.list = payload;
    });
});
