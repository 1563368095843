import { useState, useCallback } from 'react';
import { PublicCard } from 'components/layout/publicCard';
import { useApiCallback } from 'hooks/useApiCallback';
import { Loader } from 'components/common/loader';
import { Text } from 'components/text';
import { PasswordCreateForm } from 'components/pages/passwordCreate/passwordCreateForm';
import { ErrorBoundary } from 'components/common/errorBoudry';
import { useParams } from "react-router-dom";

const Page = ({ hash }) => {
    const [email, setEmail] = useState(null);
    const [failed, setFailed] = useState(null);

    const callback = useCallback(
        res => {
            if (res.email) {
                setEmail(res.email);
            } else {
                setFailed(true);
            }
        },
        [setEmail]
    );

    useApiCallback(`/user/token/${hash}`, callback);

    if (failed)
        return (
            <PublicCard
                title={<Text k={`This link is outdated or invalid`} />}
            />
        );
    return (
        <PublicCard title={<Text k={`Create password for your account`} />}>
            {email ? (
                <PasswordCreateForm hash={hash} email={email} />
            ) : (
                <Loader />
            )}
        </PublicCard>
    );
};

export const PasswordCreatePage = () => {
    const { hash } = useParams();

    return (
        <ErrorBoundary boundary="password-create">
            <Page hash={hash} />
        </ErrorBoundary>
    );
};
