import { useState } from 'react';
import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Text } from 'components/text';
import { Button } from 'components/common/button';
import { useTeamApi } from 'hooks/api/useTeamApi';
import { TeamsTable } from 'components/pages/team/teamTable';
import { AddMemberModal } from 'components/pages/team/addMemberModal';
import { ConfirmModal } from 'components/common/confirmModal';
import { ActionGroup } from 'components/common/actionGroup';
import { useApi } from 'hooks/useApi';
import { constants } from '../constants';
import { useUserLocation } from 'store/user/selectors';
import { LocationTitle } from 'components/common/locationTitle';
import { useToken } from 'store/common/selectors';
import { FileDownload } from 'components/icons';
import { ErrorBoundary } from 'components/common/errorBoudry';

export const TeamPage = () => {
    useTeamApi();
    const location = useUserLocation() as { title: string; address: string; id: string };
    const [modalOpen, setModalOpen] = useState(false);
    const [confirm, setConfirm] = useState<(() => void) | null>(null);
    const [loading, setLoading] = useState(false);
    const token = useToken();

    const request = useApi(`/me/notify-lunch-is-delivered`);

    const notify = async () => {
        setLoading(true);
        try {
            await request();
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <ErrorBoundary boundary="team">
            <ContentGrid>
                <ContentBlock>
                    <LocationTitle location={location} />
                </ContentBlock>
                <ContentBlock>
                    <ContentCard tinyPadding>
                        <ActionGroup>
                            <Button secondary onClick={() => setModalOpen(true)}>
                                <Text k={`Invite New Member`} />
                            </Button>
                            <Button secondary loading={loading} onClick={() => setConfirm(() => () => notify())}>
                                <Text k={`Notify Team`} />
                            </Button>
                            <Button
                                href={`${constants.apiPath}/app/order/export/location/${location?.id}?bearer=${token}`}
                                secondary
                                download
                                icon={<FileDownload />}
                            >
                                <Text k={`Download Team Report`} />
                            </Button>
                        </ActionGroup>
                    </ContentCard>
                </ContentBlock>
                <ContentBlock>
                    <ContentCard>
                        <TeamsTable />
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
            <AddMemberModal active={modalOpen} handleClose={() => setModalOpen(false)} />
            <ConfirmModal
                title={<Text k={`Notify team`} />}
                active={!!confirm}
                callback={confirm}
                label={<Text k={`Notify`} />}
                onClose={() => setConfirm(null)}
            />
        </ErrorBoundary>
    );
};
