import styles from './header.module.css';
import { HeaderDropdown } from '../headerDropdown';
import { Text } from '../../text';
import { Person } from '../../icons';
import { useUser } from 'store/user/selectors';
import { useLogOut } from 'hooks/useLogOut';
import { Button } from 'components/common/button';

export const ProfileDropdown = () => {
    const { first_name: firstName } = useUser();
    const logOut = useLogOut();

    return (
        <HeaderDropdown
            items={[
                {
                    to: '/profile',
                    label: <Text k={`My Profile`} />,
                },
                {
                    onClick: logOut,
                    label: <Text k={`Log Out`} />,
                },
            ]}
        >
            <Button
                className={styles.header__button}
                secondary
                small
                icon={<Person />}
            >
                {firstName}
            </Button>
            <Button
                className={`${styles.header__button_mobile}`}
                secondary
                small
                icon={<Person />}
                iconOnly
            />
        </HeaderDropdown>
    );
};
