import { useApiData } from 'hooks/useApiData';
import {setLaps, setLapsUpdating} from 'store/laps/actions';
import {useDispatch} from "react-redux";
import {useApi} from "hooks/useApi";

export const useLapsApi = () => {
    useApiData(`/laps`, setLaps);
};

export const useLapsRefresh = () => {
    const dispatch = useDispatch();
    const getLaps = useApi('/laps');

    return async () => {
        dispatch(setLapsUpdating(true))
        const res = await getLaps();
        dispatch(setLaps(await res.json()));
    };
};
