import { createReducer } from '@reduxjs/toolkit';
import { setReviewMessage, setReviewOpen } from 'store/review/actions';

const initialState: {
    modalOpen: boolean;
    message: string;
} = {
    modalOpen: false,
    message: '',
};

export const reviewReducer = createReducer(initialState, builder => {
    builder.addCase(setReviewOpen, (state, { payload }) => {
        state.modalOpen = payload;
    });
    builder.addCase(setReviewMessage, (state, { payload }) => {
        state.message = payload;
    });
});
