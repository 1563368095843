import { SET_CSRF_TOKEN, SET_LANG, SET_MAINTENANCE, SET_TOKEN } from './constants';
import { SET_DICTIONARY } from '../dictionary/constants';
import { SET_PROVIDERS } from 'store/providers/constants';

const params = new URLSearchParams(window.location.search);

export const initialState = {
    token: null,
    maintenance: false,
    csrfToken: null,
    lang: 'lv',
    languages: [],
    loading: ['providers'],
    requestMode: params.has('a') ? 'a' : null,
};

export const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LANG: {
            return { ...state, lang: action.payload };
        }
        case SET_DICTIONARY: {
            return { ...state, languages: action.payload.languages };
        }
        case SET_CSRF_TOKEN: {
            return { ...state, csrfToken: action.payload };
        }
        case SET_TOKEN: {
            return { ...state, token: action.payload };
        }
        case SET_MAINTENANCE: {
            return { ...state, maintenance: action.payload };
        }
        case SET_PROVIDERS: {
            const loading = [...state.loading];
            return {
                ...state,
                loading: loading.filter(key => 'providers' !== key),
            };
        }
        default: {
            return state;
        }
    }
};
