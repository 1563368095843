import { PublicCard } from 'components/layout/publicCard';
import { Text } from 'components/text';
import { ResetPasswordForm } from 'components/pages/resetPassword/resetPasswordForm';
import { ErrorBoundary } from 'components/common/errorBoudry';
import { useParams } from 'react-router-dom';

export const ResetPasswordPage = () => {
    const { hash } = useParams();

    return (
        <ErrorBoundary boundary="password-reset">
            <PublicCard title={<Text k={`Complete resetting password`} />}>
                <ResetPasswordForm hash={hash} />
            </PublicCard>
        </ErrorBoundary>
    );
};
