import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { Provider } from 'react-redux';
import 'focus-visible';
import './global/theme.css';
import './global/common.css';
import './global/typography.css';
import 'components/common/searchableSelect/searchableSelect.css';

import { store } from './store';
import { ErrorBoundary } from 'components/common/errorBoudry';

const element = document.getElementById('root');
const root = ReactDOM.createRoot(element!);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ErrorBoundary boundary="app">
                <App />
            </ErrorBoundary>
        </Provider>
    </React.StrictMode>
);
