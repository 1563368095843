import styles from './cartRibbon.module.css';
import { useTotalPrice } from 'hooks/useTotalPrice';
import { PriceTag } from 'components/common/priceTag/priceTag';
import { Text } from 'components/text';
import { useCart, useCartOpen } from 'store/menu/selectors';
import { useDispatch } from 'react-redux';
import { setCartStatus } from 'store/menu/actions';

export const CartRibbon = () => {
    const dispatch = useDispatch();
    const total = useTotalPrice();
    const cart = useCart();
    const isOpen = useCartOpen();

    return (
        <div
            className={`${styles.cartRibbon} ${
                cart.length && !isOpen ? styles.cartRibbon_open : ''
            }`}
            onClick={() => dispatch(setCartStatus(true))}
        >
            <div>
                <Text k={`Cart`} />
            </div>
            <div>
                <PriceTag price={total} secondary />
            </div>
        </div>
    );
};
