import styles from './userCreateForm.module.css';
import { useState } from 'react';
import { Form } from 'components/layout/form';
import { Input } from 'components/common/input';
import { Text } from 'components/text';
import { Button } from 'components/common/button';
import { constants } from '../../../../constants';
import { setToken } from 'store/common/actions';
import { useDispatch } from 'react-redux';

export const UserCreateForm = ({ hash, location }) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);
        try {
            const res = await fetch(
                `${constants.apiPath}/app/register-invited`,
                {
                    method: 'post',
                    body: JSON.stringify({
                        name,
                        email,
                        phone,
                        password,
                        password_confirmation,
                        token: hash,
                    }),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            );
            const data = await res.json();
            if (!data.errors) {
                setErrors({});
                dispatch(setToken(data.token));
                window.location.replace('/login');
            } else {
                setErrors(data.errors);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };
    return (
        <div>
            <h1 className={styles.userCreateForm__header}>
                <span className={styles.userCreateForm__headerTitle}>
                    <Text k={`Create your Lunch.work account`} />
                </span>
                <span className={styles.userCreateForm__headerLocation}>
                    <span className={styles.userCreateForm__headerSeparator}>
                        <Text k={`at`} />
                    </span>
                    {location.title}
                </span>
            </h1>
            <Form onSubmit={handleSubmit}>
                <Input
                    id={'create-account-name'}
                    value={name}
                    type={'text'}
                    errors={errors.name}
                    autoComplete="name"
                    onChange={event => {
                        setErrors({ ...errors, name: null });
                        setName(event.target.value);
                    }}
                    label={<Text k={`Name`} />}
                />
                <Input
                    id={'create-account-email'}
                    value={email}
                    type={'email'}
                    errors={errors.email}
                    autoComplete="email"
                    onChange={event => {
                        setErrors({ ...errors, email: null });
                        setEmail(event.target.value);
                    }}
                    label={<Text k={`Email`} />}
                />
                <Input
                    id={'create-account-phone'}
                    value={phone}
                    type={'tel'}
                    errors={errors.phone}
                    autoComplete="tel"
                    onChange={event => {
                        setErrors({ ...errors, tel: null });
                        setPhone(event.target.value);
                    }}
                    label={<Text k={`Phone`} />}
                />
                <Input
                    id={'create-account-password'}
                    value={password}
                    type={'password'}
                    errors={errors.password}
                    onChange={event => {
                        setErrors({ ...errors, password: null });
                        setPassword(event.target.value);
                    }}
                    label={<Text k={`New Password`} />}
                />
                <Input
                    id={'create-account-password_confirmation'}
                    value={password_confirmation}
                    type={'password'}
                    errors={errors.password_confirmation}
                    onChange={event => {
                        setErrors({ ...errors, password_confirmation: null });
                        setPasswordConfirmation(event.target.value);
                    }}
                    label={<Text k={`Confirm New Password`} />}
                />
                <Button type={'submit'} loading={loading}>
                    <Text k={'Submit'} />
                </Button>
            </Form>
        </div>
    );
};
