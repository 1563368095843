import styles from './checkbox.module.css';
import { Tick } from '../../icons';
import { useModifiers } from 'hooks/useModifiers';
import { FunctionComponent, InputHTMLAttributes, ReactNode } from 'react';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string | ReactNode;
    className?: string;
    id: string;
    type?: 'checkbox' | 'radio';
    center?: boolean;
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({
    label,
    children,
    className = '',
    id,
    type = 'checkbox',
    center,
    ...rest
}) => {
    const mods = useModifiers(styles, 'checkbox', {
        radio: type === 'radio',
        center,
    });

    return (
        <div className={`${styles.checkbox} ${mods}`}>
            <input
                id={id}
                type={type}
                className={styles.checkbox__input}
                {...rest}
            />
            <label htmlFor={id} className={styles.checkbox__labelContainer}>
                <span className={styles.checkbox__tick}>
                    <Tick />
                </span>
                <span className={styles.checkbox__label}>
                    {label || children}
                </span>
            </label>
        </div>
    );
};
