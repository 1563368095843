import { FunctionComponent } from 'react';
import { usePublicMenu } from 'store/publicMenu/selectors';
import { CategoryMenu } from 'components/pages/newOrder/categoryMenu';
import { Loader } from 'components/common/loader';

export const PublicCategoryMenu: FunctionComponent = () => {
    const categories = usePublicMenu();

    if (!categories) return <Loader />;
    return (
        <div>
            {categories.map((category, index) => {
                return <CategoryMenu display category={category} key={index} />;
            })}
        </div>
    );
};
