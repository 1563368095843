import { createAction } from '@reduxjs/toolkit';
import {LapLocationAmounts} from "store/lap/types";

export const setLap = createAction('lap/SET', lap => {
    return {
        payload: lap,
    };
});

export const resetLap = createAction('lap/RESET');

export const setLapCurrentCash = createAction(
    'lap/SET_CURRENT',
    (locationId: string, amounts: LapLocationAmounts) => {
        return {
            payload: { locationId, amounts },
        };
    }
);
