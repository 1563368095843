import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Text } from 'components/text';
import { Button } from 'components/common/button';
import { useDispatch } from 'react-redux';
import { updateNewDish } from 'store/dishes/actions';
import { NewDishModal } from 'components/pages/dishes/newDishesModal';
import { DishesTable } from 'components/pages/dishes/dishesTable';
import { ActionGroup } from 'components/common/actionGroup';
import { updateNewComplex } from 'store/complexes/actions';
import { NewComplexModal } from 'components/pages/dishes/newComplexModal';
import { DishFilterCard } from 'components/pages/dishes/filterCard';
import { ErrorBoundary } from 'components/common/errorBoudry';

export const DishesPage = () => {
    const dispatch = useDispatch();

    return (
        <ErrorBoundary boundary="dishes">
            <ContentGrid>
                <ContentBlock>
                    <h1 className={`h1 h1_noMargin`}>
                        <Text k={`Dishes`} />
                    </h1>
                </ContentBlock>
                <ContentBlock>
                    <ContentCard tinyPadding>
                        <ActionGroup>
                            <Button secondary onClick={() => dispatch(updateNewDish({ active: true }))}>
                                <Text k={`New Dish`} />
                            </Button>
                            <Button secondary onClick={() => dispatch(updateNewComplex({ active: true }))}>
                                <Text k={`New Complex`} />
                            </Button>
                        </ActionGroup>
                    </ContentCard>
                </ContentBlock>
                <ContentBlock>
                    <DishFilterCard />
                </ContentBlock>
                <ContentBlock>
                    <ContentCard>
                        <DishesTable />
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
            <NewDishModal />
            <NewComplexModal />
        </ErrorBoundary>
    );
};
