import { Order } from 'store/types';
import { PaymentType } from 'constants/paymentType';
import { LocationType } from 'store/user/types';
import { createReducer } from '@reduxjs/toolkit';
import {
    setOrdersByMenu,
    setOrdersByMenuLoading,
    setOrdersByMenuUpdating,
    updateOrdersByMenuSingle,
} from 'store/ordersByMenu/actions';

type Orders = {
    orders: Order[];
    [PaymentType.card]: number;
    [PaymentType.cash]: number;
    [PaymentType.subsidy]: number;
    [PaymentType.bonus]: number;
    location: LocationType;
}[];

const initialState: {
    orders: null | Orders;
    loading: boolean;
    updating: boolean;
} = {
    orders: null,
    loading: true,
    updating: false,
};

export const ordersByMenuReducer = createReducer(initialState, builder => {
    builder.addCase(setOrdersByMenuLoading, (state, { payload }) => {
        state.loading = payload;
    });
    builder.addCase(setOrdersByMenuUpdating, (state, { payload }) => {
        state.loading = payload;
    });
    builder.addCase(updateOrdersByMenuSingle, (state, { payload }) => {
        const { locationId, orderId, order } = payload;
        const locations = state.orders;
        if (!locations) return state;
        const locationIndex = locations.findIndex(
            ({ location }) => location.id == locationId
        );
        if (locationIndex >= 0) {
            const location = locations[locationIndex];
            const orderIndex = location.orders.findIndex(
                order => order.id == orderId
            );
            if (orderIndex >= 0) {
                if (order) {
                    location.orders[orderIndex] = order;
                } else {
                    location.orders = location.orders.filter(
                        item => item.id != orderId
                    );
                }
            }
        }
    });
    builder.addCase(setOrdersByMenu, (state, { payload: orders }) => {
        const sortedOrders: Orders = [];
        if (!Array.isArray(orders)) return { ...state, loading: false };
        orders.forEach(order => {
            const orderLocation = order.user.location;
            const locationIndex = sortedOrders.findIndex(
                location => location.location.id === orderLocation.id
            );
            const sum = { cash: 0, card: 0 };
            switch (order.payment_type) {
                case 'cash': {
                    sum.cash = order.amount_to_pay;
                    break;
                }
                case 'card': {
                    sum.card = order.amount_to_pay;
                    break;
                }
                default: {
                    break;
                }
            }
            if (locationIndex >= 0) {
                sortedOrders[locationIndex].orders.push(order);
                sortedOrders[locationIndex].cash += sum.cash;
                sortedOrders[locationIndex].card += sum.card;
                sortedOrders[locationIndex].subsidy += order.subsidy;
                sortedOrders[locationIndex].bonus += order.bonus || 0;
            } else {
                sortedOrders.push({
                    location: orderLocation,
                    cash: sum.cash,
                    card: sum.card,
                    subsidy: order.subsidy,
                    bonus: order.bonus || 0,
                    orders: [order],
                });
            }
        });
        state.orders = sortedOrders;
        state.loading = false;
    });
});

// export const ordersByMenuReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case SET_ORDERS_BY_MENU_LOADING: {
//             return { ...state, loading: action.payload };
//         }
//         case SET_ORDERS_BY_MENU_UPDATING: {
//             return { ...state, updating: action.payload };
//         }
//         case UPDATE_ORDERS_BY_MENU_SINGLE: {
//             const { locationId, orderId, order } = action.payload;
//             const locations = [...state.data];
//             const locationIndex = locations.findIndex(
//                 ({ location }) => location.id == locationId
//             );
//             if (locationIndex >= 0) {
//                 const location = locations[locationIndex];
//                 const orderIndex = location.orders.findIndex(
//                     order => order.id == orderId
//                 );
//                 if (orderIndex >= 0) {
//                     location.orders[orderIndex] = order;
//                 }
//             }
//             return { ...state, data: locations };
//         }
//         case SET_ORDERS_BY_MENU: {
//             const orders = action.payload;
//             const sortedOrders: Orders = [];
//             if (!Array.isArray(orders)) return { ...state, loading: false };
//             orders.forEach(order => {
//                 const orderLocation = order.user.location;
//                 const locationIndex = sortedOrders.findIndex(
//                     location => location.location.id === orderLocation.id
//                 );
//                 const sum = { cash: 0, card: 0 };
//                 switch (order.payment_type) {
//                     case 'cash': {
//                         sum.cash = order.amount_to_pay;
//                         break;
//                     }
//                     case 'card': {
//                         sum.card = order.amount_to_pay;
//                         break;
//                     }
//                     default: {
//                         break;
//                     }
//                 }
//                 if (locationIndex >= 0) {
//                     sortedOrders[locationIndex].orders.push(order);
//                     sortedOrders[locationIndex].cash += sum.cash;
//                     sortedOrders[locationIndex].card += sum.card;
//                     sortedOrders[locationIndex].subsidy += order.subsidy;
//                 } else {
//                     sortedOrders.push({
//                         location: orderLocation,
//                         cash: sum.cash,
//                         card: sum.card,
//                         subsidy: order.subsidy,
//                         orders: [order],
//                     });
//                 }
//             });
//             return { ...state, data: sortedOrders, loading: false };
//         }
//         default: {
//             return state;
//         }
//     }
// };
