import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Text } from 'components/text';
import { useProfileApi } from 'hooks/api/useProfileApi';
import { BasicInfoCard } from 'components/pages/profile/basicInfoCard';
import { PasswordForm } from 'components/pages/profile/passwordForm';
import { ErrorBoundary } from 'components/common/errorBoudry';

export const ProfilePage = () => {
    useProfileApi();

    return (
        <ErrorBoundary boundary="profile">
            <ContentGrid>
                <ContentBlock>
                    <h1 className={`h1 h1_noMargin`}>Profile</h1>
                </ContentBlock>
                <ContentBlock span={6}>
                    <BasicInfoCard />
                </ContentBlock>
                <ContentBlock span={6}>
                    <ContentCard title={<Text k={`Change Password`} />}>
                        <PasswordForm />
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
        </ErrorBoundary>
    );
};
