import { useAppSelector } from 'store/index';

export const useNewDish = () => {
    return useAppSelector(({ dishes: { new: newDish } }) => newDish);
};













export const useEditingImage = () => {
    return useAppSelector(({ dishes }) => dishes.imageEditing);
};

export const useSearchQuery = () => {
    return useAppSelector(({ dishes }) => dishes.searchQuery);
};

export const useSearchCategory = () => {
    return useAppSelector(({ dishes }) => dishes.searchCategory);
};
