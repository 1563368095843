import { useToken } from 'store/common/selectors';
import { Navigate } from 'react-router-dom';
import { Text } from 'components/text';
import { PublicCard } from 'components/layout/publicCard';
import { LoginForm } from 'components/pages/login/loginForm';
import { ErrorBoundary } from 'components/common/errorBoudry';

export const LoginPage = () => {
    const token = useToken();
    return (
        <ErrorBoundary boundary="login">
            {token ? <Navigate to={'/'} /> : ''}
            <PublicCard title={<Text k={`Login to my profile`} />}>
                <LoginForm />
            </PublicCard>
        </ErrorBoundary>
    );
};
