import styles from './newMenuDishCard.module.css';
import { useTranslator } from 'hooks/useTranslator';
import { Button } from 'components/common/button';
import {
    BottomSmall,
    Close,
    Complex,
    Edit,
    Reset,
    TopSmall,
} from 'components/icons';
import { PriceTag } from 'components/common/priceTag/priceTag';
import { Input } from 'components/common/input';
import { ActionGroup } from 'components/common/actionGroup';
import { usePriceFormatter } from 'hooks/useFormattedPrice';
import {
    useSelectedComplexDish,
    useSelectedDish,
} from 'store/newMenu/selectors';
import { useDispatch } from 'react-redux';
import {
    editSelectedComplexDishPrice,
    editSelectedDishPrice,
    enableEditSelectedComplex,
    resetSelectedComplexDish,
    resetSelectedDish,
} from 'store/newMenu/actions';
import { useApi } from 'hooks/useApi';
import { useState } from 'react';
import { Loader } from 'components/common/loader';

const SubDish = ({ dish: { title, id, price: originalPrice }, complexId }) => {
    const dispatch = useDispatch();
    const translator = useTranslator();
    const priceFormatter = usePriceFormatter();
    const { price } = useSelectedComplexDish(complexId, id);
    const isEdited = price;

    return (
        <div className={`${styles.newMenuDishCard__dishCard} standardBg`}>
            <div className={styles.newMenuDishCard__dishCardRow}>
                <div className={styles.newMenuDishCard__dishCardWing}>
                    {translator(title)}
                </div>
                <div className={styles.newMenuDishCard__dishCardWing}>
                    <ActionGroup>
                        {isEdited ? (
                            <Button
                                className={styles.newMenuDishCard__close}
                                icon={<Reset />}
                                iconOnly
                                secondary
                                small
                                onClick={() =>
                                    dispatch(
                                        resetSelectedComplexDish(complexId, id)
                                    )
                                }
                            />
                        ) : (
                            ''
                        )}
                        <PriceTag
                            price={originalPrice}
                            secondary={isEdited}
                            old={isEdited}
                        />
                        <Input
                            id={`new-menu-dish-price-${id}`}
                            type="number"
                            step={0.1}
                            value={price || ''}
                            onChange={event =>
                                dispatch(
                                    editSelectedComplexDishPrice(
                                        complexId,
                                        id,
                                        event.target.value
                                    )
                                )
                            }
                            min={0}
                            className={styles.newMenuDishCard__priceInput}
                            placeholder={priceFormatter(originalPrice)}
                        />
                    </ActionGroup>
                </div>
            </div>
        </div>
    );
};

export const NewMenuDishCard = ({ dish, onRemove }) => {
    const dispatch = useDispatch();
    const translator = useTranslator();
    const { type, price: originalPrice, title, id } = dish;
    const priceFormatter = usePriceFormatter();
    const { price, complex } = useSelectedDish(id);
    const [loading, setLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [complexDish, setComplexDish] = useState(null);
    const requestComplex = useApi('/dishes', { parsed: true });
    const isEdited = price || price === 0;

    const handleEdit = async () => {
        setLoading(true);
        const result = await requestComplex({ route: `/${id}` });
        setLoading(false);
        setComplexDish(result);
        dispatch(enableEditSelectedComplex(id));
    };

    return (
        <div className={`${styles.newMenuDishCard} shaded`}>
            <div className={styles.newMenuDishCard__header}>
                <div className={styles.newMenuDishCard__headerWing}>
                    {type === 'complex' ? (
                        <div className={styles.newMenuDishCard__icon}>
                            <Complex />
                        </div>
                    ) : (
                        ''
                    )}
                    <div className={styles.newMenuDishCard__title}>
                        {translator(title)}
                    </div>
                </div>
                <div className={styles.newMenuDishCard__headerWing}>
                    <ActionGroup>
                        <Button
                            icon={<Reset />}
                            iconOnly
                            secondary={
                                !(
                                    isEdited ||
                                    (complex &&
                                        complex.some(item => item.price))
                                )
                            }
                            small
                            onClick={() => dispatch(resetSelectedDish(id))}
                        />
                        {type !== 'complex' ? (
                            <>
                                <PriceTag
                                    price={originalPrice}
                                    secondary={isEdited}
                                    old={isEdited}
                                />
                                <Input
                                    id={`new-menu-dish-price-${id}`}
                                    type="number"
                                    step={0.1}
                                    value={price === 0 ? 0 : price || ''}
                                    onChange={event =>
                                        dispatch(
                                            editSelectedDishPrice(
                                                id,
                                                event.target.value
                                            )
                                        )
                                    }
                                    min={0}
                                    className={
                                        styles.newMenuDishCard__priceInput
                                    }
                                    placeholder={priceFormatter(originalPrice)}
                                />
                            </>
                        ) : !complexDish ? (
                            <Button
                                icon={<Edit />}
                                iconOnly
                                secondary
                                disabled={loading || complexDish}
                                small
                                onClick={handleEdit}
                            />
                        ) : (
                            <Button
                                icon={
                                    collapsed ? <BottomSmall /> : <TopSmall />
                                }
                                iconOnly
                                secondary
                                small
                                onClick={() => setCollapsed(!collapsed)}
                            />
                        )}
                        <Button
                            icon={<Close />}
                            iconOnly
                            secondary
                            small
                            onClick={onRemove}
                        />
                    </ActionGroup>
                </div>
            </div>
            {(loading || (complex && !complexDish && loading) || complexDish) &&
            !collapsed ? (
                <div className={styles.newMenuDishCard__body}>
                    {loading && !complexDish ? (
                        <Loader />
                    ) : (
                        complexDish?.complex.map((complexCategory, index) => {
                            return (
                                <div
                                    className={
                                        styles.newMenuDishCard__complexCategory
                                    }
                                    key={index}
                                >
                                    <div
                                        className={
                                            styles.newMenuDishCard__complexCategoryTitle
                                        }
                                    >
                                        {translator(complexCategory.title)}
                                    </div>
                                    <div
                                        className={
                                            styles.newMenuDishCard__dishGrid
                                        }
                                    >
                                        {complexCategory.dishes.map(subDish => (
                                            <SubDish
                                                dish={subDish}
                                                complexId={id}
                                                key={subDish.id}
                                            />
                                        ))}
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
