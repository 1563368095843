import { SET_MENU_HISTORY } from './constants';

const initialState = {};

export const menuHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MENU_HISTORY: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};
