import { createAction } from '@reduxjs/toolkit';

export const setKDS = createAction('kds/SET', data => {
    return {
        payload: data,
    };
});

export const setKDSUpdating = createAction('kds/SET_UPDATING', (updating: boolean) => {
    return {
        payload: updating,
    };
});

export const resetKDS = createAction('kds/RESET')