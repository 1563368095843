import { LanguageSwitcher } from 'components/common/languageSwitcher';
import styles from './publicHeader.module.css';
import { Logo } from 'components/layout/logo';

export const PublicHeader = () => {
    return (
        <header className={styles.publicHeader}>
            <Logo className={styles.publicHeader__logo} />
            <LanguageSwitcher />
        </header>
    );
};
