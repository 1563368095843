import { useAppSelector } from 'store/index';

export const useLapTotals = () => {
    return useAppSelector(({ lap }) => {
        return lap.total;
    });
};

export const useLocationTotal = () => {
    return useAppSelector(({lap}) => {
        return lap.total?.amounts
    })
}

export const useLapLocations = () => {
    return useAppSelector(({ lap }) => {
        return lap.locations;
    });
};
