import { Disabled, Power, TickFilled, UserOff } from 'components/icons';
import { Text } from 'components/text';
import { DataTable } from 'components/common/dataTable';
import { Loader } from 'components/common/loader';
import { useTeam, useTeamLoading } from 'store/team/selectors';
import { useTranslation } from 'store/dictionary/selectors';
import { useApi } from 'hooks/useApi';
import { useTeamRefresh } from 'hooks/api/useTeamApi';
import { useUserId } from 'store/user/selectors';
import { useState } from 'react';

export const TeamsTable = () => {
    const team = useTeam() as object[];
    const userId = useUserId();
    const loading = useTeamLoading();
    const refresh = useTeamRefresh();
    const deactivateTitle = useTranslation(`Deactivate User`);
    const activateTitle = useTranslation(`Activate User`);
    const deactivate = useApi(`/me/deactivate`, { method: 'delete' });
    const activate = useApi(`/me/activate`);
    const [usersLoading, setUsersLoading] = useState<string[]>([]);

    const handleDeactivate = async (item: { id: string }) => {
        setUsersLoading([...usersLoading, item.id]);
        try {
            const res = await deactivate({ route: `/${item.id}` });
            if (res.ok) {
                await refresh();
                setUsersLoading(usersLoading.filter(id => id !== item.id));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleActivate = async (item: { id: string }) => {
        setUsersLoading([...usersLoading, item.id]);
        try {
            const res = await activate({ route: `/${item.id}` });
            if (res.ok) {
                await refresh();
                setUsersLoading(usersLoading.filter(id => id !== item.id));
            }
        } catch (error) {
            console.error(error);
        }
    };

    if (loading) return <Loader />;
    return (
        <>
            <DataTable
                fields={[
                    {
                        label: <Text k={`Name`} />,
                        key: 'first_name',
                    },
                    {
                        label: <Text k={`Email`} />,
                        key: 'email',
                    },
                    {
                        label: <Text k={`Active`} />,
                        key: 'activated',
                        processor: value => (value ? <TickFilled /> : <Disabled />),
                    },
                ]}
                data={team}
                itemActions={[
                    {
                        icon: <UserOff />,
                        onClick: item => handleDeactivate(item),
                        title: deactivateTitle,
                        active: item => item.activated && item.id != userId,
                        loading: item => usersLoading.includes(item.id),
                    },
                    {
                        icon: <Power />,
                        onClick: item => handleActivate(item),
                        title: activateTitle,
                        active: item => !item.activated && item.id != userId,
                        loading: item => usersLoading.includes(item.id),
                    },
                ]}
            />
        </>
    );
};
