import { createAction } from '@reduxjs/toolkit';
import { DishlistResponseType, DishlistType } from 'store/menu/types';

export const setMenu = createAction('menu/SET_MENU', (menu: DishlistResponseType | null) => {
    return {
        payload: menu,
    };
});

export const setMenuWaiting = createAction('menu/SET_MENU_WAITING', (waiting: boolean) => {
    return {
        payload: waiting,
    };
});

export const setAvailableMenus = createAction('menu/SET_AVAILABLE_MENUS', (availableMenus: DishlistType[]) => {
    return {
        payload: availableMenus,
    };
});

export const setSelectedDishlist = createAction('menu/SET_SELECTED_DISHLIST', (id: string) => {
    return {
        payload: id,
    };
});

export const setMenuUpdating = createAction('menu/SET_MENU_UPDATING', (updating: boolean) => {
    return {
        payload: updating,
    };
});

export const setOrderSuccess = createAction('menu/SET_ORDER_SUCCESS', success => {
    return {
        payload: success,
    };
});

export const addDishToCart = createAction(
    'menu/ADD_DISH_TO_CART',
    ({ id, category, quantity = 1, selected, ...rest }) => {
        return {
            payload: { id, category, quantity, selected, ...rest },
        };
    }
);

export const removeFromCart = createAction('menu/REMOVE_FROM_CART', index => {
    return {
        payload: index,
    };
});

export const clearCart = createAction('menu/CLEAR_CART');

export const setCartStatus = createAction('menu/SET_CART_STATUS', status => {
    return {
        payload: status,
    };
});

export const updateCartQuantity = createAction('menu/UPDATE_CART_QUANTITY', (index, delta) => {
    return {
        payload: { index, delta },
    };
});

export const setSelectedShift = createAction('menu/SET_SELECTED_SHIFT', (shift: string | null) => {
    return {
        payload: shift,
    };
});
