import { useApi } from './useApi';
import { useEffect } from 'react';

export const useApiCallback = (url, callback, options = {}) => {
    const request = useApi(url, { ...options, parsed: true });

    useEffect(() => {
        const requestData = async () => {
            try {
                const res = await request();
                callback(res);
            } catch (error) {
                console.error(error);
            }
        };
        requestData();
    }, []);
};
