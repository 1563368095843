import { createReducer } from '@reduxjs/toolkit';
import {
    addDishToCart,
    clearCart,
    removeFromCart,
    setAvailableMenus,
    setCartStatus,
    setMenu,
    setMenuUpdating,
    setMenuWaiting,
    setOrderSuccess,
    setSelectedDishlist,
    setSelectedShift,
    updateCartQuantity,
} from 'store/menu/actions';
import { CartItem, DishBalanceItem, DishlistDetailsType, DishlistType, PaymentMethodsType } from 'store/menu/types';

type MenuReducer = {
    cart: CartItem[];
    dishlist: DishlistDetailsType | null;
    availableDishlists: DishlistType[] | null;
    selectedDishlist: string | null;
    cartOpen: boolean;
    success: boolean | null;
    waiting: boolean;
    updating: boolean;
    dishlistBalance: DishBalanceItem[] | null;
    userData: {
        bonus: number | null;
        full_subsidy: boolean;
        subsidy: number | null;
    } | null;
    selectedShift: string | null;
    paymentMethods: PaymentMethodsType | null;
};

const initialState: MenuReducer = {
    cart: [],
    dishlist: null,
    availableDishlists: null,
    selectedDishlist: null,
    dishlistBalance: null,
    userData: null,
    paymentMethods: null,
    cartOpen: false,
    success: null,
    waiting: true,
    updating: false,
    selectedShift: null,
};

export const menuReducer = createReducer(initialState, builder => {
    builder.addCase(setMenu, (state, { payload }) => {
        return {
            ...state,
            waiting: false,
            updating: false,
            cart: initialState.cart,
            dishlist: payload?.dishlist || null,
            dishlistBalance: payload?.dishlistBalance || null,
            userData: payload?.userData || null,
            paymentMethods: payload?.paymentMethods || null,
        };
    });
    builder.addCase(setMenuWaiting, (state, { payload }) => {
        state.waiting = payload;
    });
    builder.addCase(setAvailableMenus, (state, { payload }) => {
        state.availableDishlists = payload;
        if (payload[0]) {
            if (!state.selectedDishlist || !payload.some(item => item.id === state.selectedDishlist)) {
                state.selectedDishlist = payload[0].id;
            }
        } else {
            state.selectedDishlist = null;
        }
    });
    builder.addCase(setSelectedDishlist, (state, { payload }) => {
        state.selectedDishlist = payload;
    });
    builder.addCase(setOrderSuccess, (state, { payload }) => {
        state.success = payload;
    });
    builder.addCase(addDishToCart, (state, { payload }) => {
        const newItem = payload;
        if (!state.dishlist) return state;
        const availableBalance = getAvailableBalance(state, newItem.id, newItem.selected);
        if (availableBalance === null || availableBalance >= newItem.quantity) {
            const itemIndex = state.cart.findIndex(item => item.id == newItem.id);
            if (itemIndex >= 0 && newItem.type !== 'complex') {
                state.cart[itemIndex].quantity += newItem.quantity;
            } else {
                state.cart.push(newItem);
            }
        }
    });
    builder.addCase(removeFromCart, (state, { payload }) => {
        state.cart.splice(payload, 1);
    });
    builder.addCase(clearCart, state => {
        state.cart = initialState.cart;
    });
    builder.addCase(setCartStatus, (state, { payload }) => {
        state.cartOpen = payload;
    });
    builder.addCase(updateCartQuantity, (state, { payload }) => {
        const { index, delta } = payload;
        const item = state.cart[index];
        if (item.quantity + delta <= 0) {
            state.cart.splice(index, 1);
        } else {
            item.quantity = item.quantity + delta;
        }
    });
    builder.addCase(setMenuUpdating, (state, { payload }) => {
        state.updating = payload;
    });
    builder.addCase(setSelectedShift, (state, { payload }) => {
        state.selectedShift = payload;
    });
});

export const getAvailableBalance = (
    state: MenuReducer,
    parentDishId: string,
    selected?: { [x: string]: string | number }
) => {
    const { dishlist, dishlistBalance, cart } = state;
    if (!dishlist || !dishlistBalance) return null;

    const getAvailableById = (id: string) => {
        const dishBalance = dishlistBalance.find(dishBalance => dishBalance.id == id);
        if (!dishBalance) return null;

        const totalAvailable = dishBalance.qty;

        let quantityInCart = 0;
        cart.forEach(cartDish => {
            if (cartDish.selected) {
                const complexDishes = Object.values(cartDish.selected);
                if (complexDishes.find(dishId => dishId == id)) {
                    quantityInCart += cartDish.quantity;
                }
            } else if (cartDish.id == id) {
                quantityInCart += cartDish.quantity;
            }
        });
        return totalAvailable - quantityInCart;
    };

    if (selected) {
        let smallest: null | number = null;
        Object.values(selected).forEach(dishId => {
            const available = getAvailableById(`${dishId}`);
            if (available !== null && (smallest === null || smallest > available)) {
                smallest = available;
            }
        });
        return smallest;
    }

    return getAvailableById(parentDishId);
};
