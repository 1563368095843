import { Text } from 'components/text';
import { useNewComplex } from 'store/complexes/selectors';
import { resetNewComplex, updateNewComplex } from 'store/complexes/actions';
import { ComplexEditor } from 'components/pages/dishes/newComplexModal/complexEditor';
import { CategorySelect } from 'components/pages/categories/categorySelect';
import { EditModal } from 'components/common/editModal';
import { useDispatch } from 'react-redux';
import { useNewDishMutation, useUpdateDishMutation } from '../../../../api/dishes/endpoints';
import { useEffect } from 'react';

export const NewComplexModal = () => {
    const newComplex = useNewComplex();
    const dispatch = useDispatch();
    const [trigger, { isLoading, isSuccess, reset }] = useUpdateDishMutation();
    const [newTrigger, { isLoading: isNewLoading, isSuccess: isNewSuccess, reset: newReset }] = useNewDishMutation();

    useEffect(() => {
        if (isSuccess || isNewSuccess) {
            dispatch(resetNewComplex());
            reset();
            newReset();
        }
    }, [isSuccess, dispatch, reset, isNewSuccess, newReset]);

    const handleSave = () => {
        //@ts-ignore
        if (newComplex?.id) {
            trigger(newComplex);
        } else {
            newTrigger(newComplex);
        }
    };

    return (
        <EditModal
            reset={resetNewComplex}
            update={updateNewComplex}
            // @ts-ignore
            interceptSave={handleSave}
            loading={isLoading || isNewLoading}
            item={newComplex}
            url={'/dishes'}
            formId={'new-complex-form'}
            title={<Text k={`New Complex`} />}
            fieldIdPrefix={'complex'}
            fields={[
                {
                    type: 'multilingual',
                    key: 'title',
                    label: <Text k={`Complex Title`} />,
                },
                {
                    type: 'custom',
                    Component: CategorySelect,
                    key: 'category_id',
                    label: <Text k={`Complex Title`} />,
                },
                {
                    type: 'slot',
                    Component: ComplexEditor,
                },
                {
                    type: 'checkbox',
                    key: 'status',
                    label: <Text k={`Active`} />,
                },
                {
                    type: 'multilingual',
                    key: 'summary',
                    label: <Text k={`Complex Summary`} />,
                    props: {
                        textarea: true,
                    },
                },
                {
                    type: 'input',
                    key: 'position',
                    label: <Text k={`Position`} />,
                    props: {
                        type: 'number',
                    },
                    //@ts-ignore
                    shouldRender: item => item['id'],
                },
            ]}
        />
    );
};
