import { useDispatch } from 'react-redux';
import { useApi } from 'hooks/useApi';
import { useState, useEffect } from 'react';
import { useLocationId } from 'store/user/selectors';

export const useHistoryApi = (setter, menuId, orderId) => {
    const dispatch = useDispatch();
    const getHistory = useApi('/dishlists/history');
    const locationId = useLocationId();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const request = async () => {
            const res = await getHistory({
                route: `/${locationId}${menuId ? `/${menuId}` : ''}${
                    orderId ? `/${orderId}` : ''
                }`,
                params: { per_page: 'all' },
            });
            if (res.ok) {
                const data = await res.json();
                dispatch(setter(data));
            }
        };

        if (!loaded && locationId) {
            request();
            setLoaded(true);
        }
    }, [orderId, setter, dispatch, menuId, getHistory, locationId, loaded]);
};
