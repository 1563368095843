import styles from './header.module.css';
import { Button } from '../../common/button';
import { Text } from '../../text';
import { Feedback, Nav, Person } from '../../icons';
import { useDispatch } from 'react-redux';
import { setNavOpen } from 'store/nav/actions';
import { useUser } from 'store/user/selectors';
import { ProfileDropdown } from './profileDropdown';
import { LanguageSwitcher } from './languageSwitcher';
import { HeaderDropdown } from 'components/layout/headerDropdown';
import { useApi } from 'hooks/useApi';
import { Link } from 'react-router-dom';
import logo from 'images/logo.svg';
import { setReviewMessage, setReviewOpen } from 'store/review/actions';
import { Modal } from 'components/layout/modal';
import { useReviewsActive, useReviewsMessage } from 'store/review/selectors';
import { Form } from 'components/layout/form';
import { Input } from 'components/common/input';
import { FormEvent, useState } from 'react';

export const Header = () => {
    const { email } = useUser() as { email: string };
    const dispatch = useDispatch();
    const changeRole = useApi('/develop/switch-customer');
    const active = useReviewsActive();
    const message = useReviewsMessage();
    const sendReview = useApi(`/review/send`, {
        method: `post`,
    });
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setLoading(true);
        try {
            const res = await sendReview({ body: JSON.stringify({ message }) });
            if (res.ok) {
                dispatch(setReviewOpen(false));
                dispatch(setReviewMessage(''));
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <>
            <div className={styles.header}>
                <div className={styles.header__wing}>
                    <Button
                        className={styles.header__reviewAction}
                        secondary
                        small
                        icon={<Feedback />}
                        onClick={() => dispatch(setReviewOpen(true))}
                    >
                        <Text k={`Leave Review`} />
                    </Button>
                    <Button
                        className={styles.header__navAction}
                        secondary
                        iconOnly
                        small
                        icon={<Nav />}
                        onClick={() => {
                            dispatch(setNavOpen(true));
                        }}
                    />
                </div>
                <div className={`${styles.header__wing} ${styles.header__wing_center}`}>
                    <Link to={`/`}>
                        <img src={logo} alt={'Lunch.Work'} />
                    </Link>
                </div>
                <div className={`${styles.header__wing}`}>
                    <div
                        className={`${styles.header__wingItem} ${styles.header__wingItem_dev}`}
                        style={{ display: 'none' }}
                    >
                        <HeaderDropdown
                            items={[
                                {
                                    onClick: () =>
                                        changeRole({
                                            url: '/develop/switch-admin',
                                        }),
                                    label: <Text k={`Admin`} />,
                                },
                                {
                                    onClick: () =>
                                        changeRole({
                                            url: '/develop/switch-kitchen',
                                        }),
                                    label: <Text k={`Kitchen`} />,
                                },
                                {
                                    onClick: () =>
                                        changeRole({
                                            url: '/develop/switch-customer',
                                        }),
                                    label: <Text k={`User`} />,
                                },
                            ]}
                        >
                            <Button secondary small icon={<Person />}>
                                Change Role
                            </Button>
                        </HeaderDropdown>
                    </div>
                    {email ? (
                        <div className={`${styles.header__wingItem} ${styles.header__wingItem_mobileLast}`}>
                            <ProfileDropdown />
                        </div>
                    ) : (
                        ''
                    )}
                    <div className={`${styles.header__wingItem} ${styles.header__wingItem_desktop}`}>
                        <LanguageSwitcher />
                    </div>
                </div>
            </div>
            <Modal
                active={active}
                onClose={() => dispatch(setReviewOpen(false))}
                title={<Text k={`Leave Review`} />}
                actions={[
                    {
                        label: <Text k={`Cancel`} />,
                        secondary: true,
                        onClick: () => dispatch(setReviewOpen(false)),
                    },
                    {
                        type: 'submit',
                        form: `review_form`,
                        label: <Text k={`Submit`} />,
                        loading,
                        disabled: !message,
                    },
                ]}
            >
                <Form id={`review_form`} onSubmit={handleSubmit}>
                    <Input
                        label={<Text k={`Your Review`} />}
                        textarea
                        onChange={event => dispatch(setReviewMessage(event.target.value))}
                    />
                </Form>
            </Modal>
        </>
    );
};
