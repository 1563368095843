import { useActiveLang } from 'store/common/selectors';

export const useTranslator = () => {
    const activeLang = useActiveLang();

    return translationEntry => {
        if (translationEntry[activeLang]) {
            return translationEntry[activeLang];
        } else {
            const lang = Object.keys(translationEntry).find(
                lang => translationEntry[lang]
            );
            if (lang) {
                return translationEntry[lang];
            }
        }
    };
};
