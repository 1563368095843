import { SET_TEAM } from './constants';

const defaultState = {
    team: [],
    loading: true,
};

export const teamReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_TEAM: {
            return { ...state, loading: false, team: action.payload };
        }
        default: {
            return state;
        }
    }
};
