import { useTranslator } from 'hooks/useTranslator';
import { Button } from 'components/common/button';
import { Bin, Disabled, Edit, TickFilled } from 'components/icons';
import { Text } from 'components/text';
import { useDispatch } from 'react-redux';
import { setNewCategory, updateNewCategory } from 'store/categories/actions';
import { DataTable } from 'components/common/dataTable';
import { Loader } from 'components/common/loader';
import { useDeleteCategoriesMutation, useGetCategoriesQuery } from '../../../../api/categories/endpoints';
import { CustomPagination } from 'components/common/pagination/customPagination';
import { useSearchParams } from 'react-router-dom';

export const CategoriesTable = () => {
    const [searchParams] = useSearchParams();
    const pageNumber = searchParams.get('page') || 1;
    const dispatch = useDispatch();
    const translator = useTranslator();
    const [deleter] = useDeleteCategoriesMutation();
    const { data: categories, isFetching } = useGetCategoriesQuery({
        page: pageNumber,
    });

    if (isFetching || !categories) return <Loader />;

    return (
        <DataTable
            placeholderAction={
                <Button onClick={() => dispatch(updateNewCategory({ active: true }))}>
                    <Text k={`Try adding new Category`} />
                </Button>
            }
            fields={[
                {
                    label: <Text k={`Position`} />,
                    key: 'position',
                },
                {
                    label: <Text k={`Category Title`} />,
                    key: 'title',
                    processor: value => translator(value),
                },
                {
                    label: <Text k={`Active`} />,
                    key: 'status',
                    processor: value => (value ? <TickFilled /> : <Disabled />),
                },
            ]}
            itemActions={[
                {
                    icon: <Edit />,
                    onClick: item =>
                        dispatch(
                            setNewCategory({
                                ...item,
                                active: true,
                            })
                        ),
                },
                {
                    icon: <Bin />,
                    onClick: item => deleter(item.id),
                },
            ]}
            data={categories.data}
            perPage={'all'}
            customPagination={<CustomPagination current={categories.current_page} total={categories.last_page} />}
        />
    );
};
