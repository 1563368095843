import { createAction } from '@reduxjs/toolkit';

export const setReviewOpen = createAction('review/OPEN', (state: boolean) => {
    return {
        payload: state,
    };
});

export const setReviewMessage = createAction(
    'review/MESSAGE',
    (message: string) => {
        return {
            payload: message,
        };
    }
);
