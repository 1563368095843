import { useState } from 'react';
import { Text } from 'components/text';
import { Form } from 'components/layout/form';
import { Input } from 'components/common/input';
import { Button } from 'components/common/button';
import { constants } from '../../../../constants';

export const ResetPasswordForm = ({ hash }) => {
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);
        try {
            const res = await fetch(`${constants.apiPath}/app/password/reset`, {
                method: 'post',
                body: JSON.stringify({
                    email,
                    password,
                    password_confirmation,
                    token: hash,
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            const data = await res.json();
            if (!data.errors) {
                setErrors({});
                window.location.replace('/login?reset=true');
            } else {
                setErrors(data.errors);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };
    return (
        <Form onSubmit={handleSubmit}>
            <Input
                id={'create-account-email'}
                value={email}
                type={'email'}
                errors={errors.email}
                autoComplete="email"
                onChange={event => {
                    setErrors({ ...errors, email: null });
                    setEmail(event.target.value);
                }}
                label={<Text k={`Email`} />}
                required
            />
            <Input
                id={'create-account-password'}
                value={password}
                type={'password'}
                errors={errors.password}
                onChange={event => {
                    setErrors({ ...errors, password: null });
                    setPassword(event.target.value);
                }}
                label={<Text k={`New Password`} />}
                required
            />
            <Input
                id={'create-account-password_confirmation'}
                value={password_confirmation}
                type={'password'}
                errors={errors.password_confirmation}
                onChange={event => {
                    setErrors({ ...errors, password_confirmation: null });
                    setPasswordConfirmation(event.target.value);
                }}
                label={<Text k={`Confirm New Password`} />}
                required
            />
            <Button type={'submit'} loading={loading}>
                <Text k={'Submit'} />
            </Button>
        </Form>
    );
};
