import { useTranslation } from 'store/dictionary/selectors';
import { SearchableSelect, SearchableSelectProps } from 'components/common/searchableSelect';
import { FunctionComponent } from 'react';
import { LocationType } from 'store/locations/types';
import { useGetAllLocationsQuery } from '../../../../api/locations/endpoints';
import { Loader } from 'components/common/loader';

interface Props extends SearchableSelectProps {
    filter?: (value: LocationType, index: number, array: LocationType[]) => boolean;
}

export const LocationSelect: FunctionComponent<Props> = ({ value, filter, ...rest }) => {
    const { data: locations } = useGetAllLocationsQuery();

    const emptyLabel = useTranslation(`Select a location...`);
    if (!locations) return <Loader />;

    let filteredLocations = locations;
    if (filter) {
        filteredLocations = locations.filter(filter);
    }

    const options: { value: string; label: string }[] = filteredLocations.map(({ id, title }) => ({
        value: id,
        label: title,
    }));

    const option = !value ? value : options.find(option => option.value === value);

    return <SearchableSelect value={option} placeholder={emptyLabel} options={options} grow {...rest} />;
};
