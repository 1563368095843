import { useMenu } from 'store/menu/selectors';
import { CategoryMenu } from 'components/pages/newOrder/categoryMenu';
import { FunctionComponent } from 'react';

export const CurrentMenu: FunctionComponent = () => {
    const menu = useMenu();

    if (!menu) return null;
    return (
        <div>
            {menu.map((category, index) => {
                return <CategoryMenu category={category} key={index} />;
            })}
        </div>
    );
};
