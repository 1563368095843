import styles from './searchableSelect.module.css';
import Select, { Props as SelectProps } from 'react-select';
import { FunctionComponent, ReactNode } from 'react';

export interface SearchableSelectProps extends SelectProps {
    grow?: boolean;
    label?: string | ReactNode;
}

export const SearchableSelect: FunctionComponent<SearchableSelectProps> = ({
    grow,
    label,
    id,
    ...rest
}) => {
    return (
        <div className={styles.searchableSelect__wrapper}>
            {label ? (
                <label className={styles.searchableSelect__label} htmlFor={id}>
                    {label}
                </label>
            ) : null}
            <Select
                id={id}
                className={`searchableSelect ${
                    grow ? `searchableSelect_grow` : ''
                }`}
                classNamePrefix={`searchableSelect`}
                theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: 'var(--cThemeLightTransparent)',
                        primary50: 'var(--cThemeLightTransparent)',
                        primary75: 'var(--cThemeLightTransparent)',
                        primary: 'var(--cThemeLight)',
                    },
                })}
                {...rest}
            />
        </div>
    );
};
