import styles from './multifield.module.css';
import { Input } from 'components/common/input';
import { ErrorContainer } from 'components/common/errorContainer';
import { useModifiers } from 'hooks/useModifiers';

export const Multifield = ({
    legend,
    label,
    fields = [],
    id,
    changeHandler,
    errors,
    ...rest
}) => {
    const mods = useModifiers(styles, 'multifield', { errors });

    return (
        <fieldset className={`${styles.multifield} ${mods}`}>
            <legend className={styles.multifield__legend}>{legend || label}</legend>
            <div>
                <div className={styles.multifield__grid}>
                    {fields.map(field => {
                        return (
                            <Input
                                aria-label={field.key}
                                key={field.key}
                                value={field.value}
                                id={`${id}-${field.key}`}
                                onChange={e => changeHandler(e, field.key)}
                                {...rest}
                                tag={field.tag}
                            />
                        );
                    })}
                </div>
                <ErrorContainer errors={errors} />
            </div>
        </fieldset>
    );
};
