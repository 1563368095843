import { useDispatch } from 'react-redux';
import styles from './languageSwitcher.module.css';
import { setLang } from '../../../store/common/actions';
import { useActiveLang, useLanguages } from '../../../store/common/selectors';

const Button = ({ code }) => {
    const dispatch = useDispatch();
    const activeLang = useActiveLang();
    const isActive = activeLang === code;

    return (
        <button
            onClick={() => dispatch(setLang(code))}
            className={`${styles.languageSwitcher__button} ${
                isActive ? styles.languageSwitcher__button_active : ''
            }`}
        >
            {code}
        </button>
    );
};

export const LanguageSwitcher = () => {
    const languages = useLanguages();

    return (
        <ul className={styles.languageSwitcher}>
            {languages.map(({ code }) => {
                return (
                    <li key={code}>
                        <Button code={code} />
                    </li>
                );
            })}
        </ul>
    );
};
