import { Dish } from 'store/dishes/types';
import { Category } from 'store/categories/types';
import { DishType } from 'constants/dishType';

export enum PaymentTypes {
    Cash = 'cash',
    Card = 'card',
    Bank = 'bank',
}

export type DishlistType = {
    id: string;
    title: string;
    from: string;
    to: string;
    status: boolean;
    provider_id: string;
};

export type DishlistDetailsType = DishlistType & {
    categories: MenuCategory[];
};

export type UserDataType = {
    bonus: number | null;
    full_subsidy: boolean;
    subsidy: number | null;
};

export type PaymentMethodsType = {
    bankConsent?: string;
    cardConsent?: string;
    card?: boolean;
    banks?: {
        IsAccountLinkingSupported: number;
        code: string;
        name: string;
        imageUrl: string;
    }[];
    bankCommission?: number;
    cardCommission?: number;
};

export type DishlistResponseType = {
    dishlist: DishlistDetailsType;
    dishlistBalance: DishBalanceItem[];
    userData: UserDataType;
    paymentMethods: PaymentMethodsType;
};

export type CartItem = {
    id: string;
    category: string;
    quantity: number;
    price: number;
    type: DishType;
    selected?: {
        [x: string]: string | number;
    };
};

export type DishBalanceItem = {
    id: string;
    qty: number;
};

export interface MenuDish extends Dish {
    qty: null | number;
    priceFrom: number;
    complex?: ComplexCategory[];
}

export interface MenuCategory extends Category {
    simple: boolean;
    dishes: MenuDish[];
}

export interface ComplexCategory extends MenuCategory {
    optional: boolean;
}
