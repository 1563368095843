import styles from './sumCard.module.css';
import { Text } from 'components/text';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { usePriceFormatter } from 'hooks/useFormattedPrice';

export const SumCard = ({ cash, card, subsidy, bonus }) => {
    const priceFormatter = usePriceFormatter();

    return (
        <ContentCard tinyPadding>
            <dl className={styles.sumCard}>
                <div className={styles.sumCard__item}>
                    <dt>
                        <Text k={`Sum (cash)`} />
                    </dt>
                    <dd>
                        <b>{priceFormatter(cash)}</b>
                    </dd>
                </div>
                <div className={styles.sumCard__item}>
                    <dt>
                        <Text k={`Sum (card)`} />
                    </dt>
                    <dd>
                        <b>{priceFormatter(card)}</b>
                    </dd>
                </div>
                <div className={styles.sumCard__item}>
                    <dt>
                        <Text k={`Subsidy`} />
                    </dt>
                    <dd>
                        <b>{priceFormatter(subsidy)}</b>
                    </dd>
                </div>
                {bonus ? (
                    <div className={styles.sumCard__item}>
                        <dt>
                            <Text k={`Bonus`} />
                        </dt>
                        <dd>
                            <b>{priceFormatter(bonus)}</b>
                        </dd>
                    </div>
                ) : null}
            </dl>
        </ContentCard>
    );
};
