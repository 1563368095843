import styles from './categoryMenu.module.css';
import { useTranslator } from 'hooks/useTranslator';
import { DishCard } from 'components/pages/newOrder/dishCard';
import { useModifiers } from 'hooks/useModifiers';
import {
    useIsAllExpanded,
    useIsExpandedMenu,
} from 'store/expandedMenu/selectors';
import { useDispatch } from 'react-redux';
import { setExpanded } from 'store/expandedMenu/actions';
import { Expand } from 'components/icons';
import { FunctionComponent } from 'react';
import { MenuCategory } from 'store/menu/types';

export const CategoryMenu: FunctionComponent<{
    category: MenuCategory;
    display?: boolean;
}> = ({ category: { id, title, dishes }, display }) => {
    const translator = useTranslator();
    const dispatch = useDispatch();

    const expanded = useIsExpandedMenu(id);
    const allExpanded = useIsAllExpanded();

    const isExpanded = expanded === undefined ? allExpanded : expanded;

    const mods = useModifiers(styles, 'categoryMenu', {
        expanded: isExpanded,
    });

    return (
        <div className={`${styles.categoryMenu} ${mods}`}>
            <button
                className={styles.categoryMenu__title}
                onClick={() => dispatch(setExpanded(id))}
            >
                {translator(title)}

                <Expand />
            </button>
            {isExpanded ? (
                <div className={styles.categoryMenu__grid}>
                    {dishes.map((dish, index) => (
                        <DishCard
                            display={display}
                            dish={dish}
                            key={index}
                            //@ts-ignore
                            category={id}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};
