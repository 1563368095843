import styles from './actionGroup.module.css';
import { useModifiers } from 'hooks/useModifiers';
import {FunctionComponent, HTMLAttributes, PropsWithChildren} from 'react';

export const ActionGroupGrow: FunctionComponent<PropsWithChildren> = ({ children }) => {
    return (
        <div
            className={`${styles.actionGroup__item} ${styles.actionGroup__item_grow}`}
        >
            {children}
        </div>
    );
};

interface ActionGroupProps extends HTMLAttributes<HTMLDivElement> {

    center?: boolean;
    bottom?: boolean;
}

export const ActionGroup: FunctionComponent<ActionGroupProps> = ({ children, className = '', center, bottom, ...rest }) => {
    const mods = useModifiers(styles, 'actionGroup', { center, bottom });

    return (
        <div className={`${styles.actionGroup} ${className} ${mods}`} {...rest}>
            {children}
        </div>
    );
};
