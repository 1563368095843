import styles from './errorContainer.module.css';
import { FunctionComponent } from 'react';

export const ErrorContainer: FunctionComponent<{ errors?: string[] }> = ({
    errors,
}) => {
    if (!errors || !errors.length) return null;
    return (
        <div className={styles.errorContainer}>
            {errors.length === 1 ? (
                <p>{errors[0]}</p>
            ) : (
                <ul>
                    {errors.map((error, index) => (
                        <li key={index}>{error}</li>
                    ))}
                </ul>
            )}
        </div>
    );
};
