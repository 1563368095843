import { DataTable } from 'components/common/dataTable';
import { Button } from 'components/common/button';
import { Text } from 'components/text';
import { AddImage, Bin, Complex, Copy, Disabled, Edit, TickFilled } from 'components/icons';
import { useDispatch } from 'react-redux';
import { useTranslator } from 'hooks/useTranslator';
import { useSearchCategory, useSearchQuery } from 'store/dishes/selectors';
import { setDishImageEditing, setNewDish, updateNewDish } from 'store/dishes/actions';
import { useApi } from 'hooks/useApi';
import { setNewComplex } from 'store/complexes/actions';
import { Loader } from 'components/common/loader';
import { PriceTag } from 'components/common/priceTag/priceTag';
import format from 'date-fns/format';
import { Picture } from 'components/common/picture';
import { useDeleteDishMutation, useGetDishesQuery } from '../../../../api/dishes/endpoints';
import { CustomPagination } from 'components/common/pagination/customPagination';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { DishImageModal } from 'components/pages/dishes/dishImageModal';
import { useGetAllCategoriesQuery } from '../../../../api/categories/endpoints';

export const DishesTable = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const translator = useTranslator();
    const pageNumber = searchParams.get('page') || 1;
    const { data: categories, isFetching: categoriesFetching } = useGetAllCategoriesQuery({ activeOnly: true });
    const requestComplex = useApi('/dishes', { parsed: true });
    const category = useSearchCategory();
    const search = useSearchQuery();
    const { data: dishes, isFetching } = useGetDishesQuery({
        page: pageNumber,
        category: category,
        search,
    });
    const [deleter] = useDeleteDishMutation();

    useEffect(() => {
        setSearchParams({ page: 1 });
    }, [search, category, setSearchParams]);

    const handleEdit = async (item, options = {}) => {
        const { copy } = options;
        if (item.type === 'complex') {
            dispatch(
                setNewComplex({
                    waiting: true,
                    active: true,
                })
            );
            const { id, ...rest } = await requestComplex({
                route: `/${item.id}`,
            });
            const newComplex = {
                ...rest,
                waiting: false,
                active: true,
            };
            if (!copy) {
                newComplex.id = id;
            }
            dispatch(setNewComplex(newComplex));
        } else {
            dispatch(
                setNewDish({
                    ...item,
                    active: true,
                })
            );
        }
    };

    if (isFetching || categoriesFetching || !dishes) return <Loader />;

    return (
        <>
            <DataTable
                placeholderAction={
                    <Button onClick={() => dispatch(updateNewDish({ active: true }))}>
                        <Text k={`Try adding new Dish`} />
                    </Button>
                }
                fields={[
                    {
                        label: '',
                        key: 'id',
                        processor: (value, item) =>
                            item.has_image ? (
                                <Picture apiUrl={`/dishes/${value}/image`} size={{ width: 46, height: 46 }} alt="" />
                            ) : (
                                ''
                            ),
                    },
                    {
                        label: <Text k={`Dish Title`} />,
                        key: 'title',
                        sorter: (a, b, ascending) => {
                            const aTranslator = translator(a).toUpperCase();
                            const bTranslator = translator(b).toUpperCase();
                            if (aTranslator < bTranslator) return ascending ? -1 : 1;
                            if (aTranslator > bTranslator) return ascending ? 1 : -1;
                            return 0;
                        },
                        processor: value => translator(value),
                    },
                    {
                        label: <Text k={`Category`} />,
                        key: 'category_id',
                        processor: value => {
                            const category = categories.find(category => category.id == value);
                            if (!category) return '';
                            return translator(category.title);
                        },
                    },
                    {
                        label: <Text k={`Created at`} />,
                        key: 'created_at',
                        sorter: (a, b, ascending) => {
                            const aDate = new Date(a);
                            const bDate = new Date(b);
                            if (aDate < bDate) return !ascending ? -1 : 1;
                            if (aDate > bDate) return !ascending ? 1 : -1;
                            return 0;
                        },
                        processor: value => {
                            return format(new Date(value), 'dd.LL.yy HH:mm');
                        },
                    },
                    {
                        label: <Text k={`Price`} />,
                        key: 'price',
                        processor: (value, item) => {
                            return item.type === 'complex' ? <Complex /> : <PriceTag price={value} secondary />;
                        },
                    },
                    {
                        label: <Text k={`Active`} />,
                        key: 'status',
                        processor: value => (value ? <TickFilled /> : <Disabled />),
                    },
                ]}
                itemActions={[
                    {
                        icon: <Copy />,
                        onClick: item => handleEdit(item, { copy: true }),
                        active: item => item.type === 'complex',
                    },
                    {
                        icon: <AddImage />,
                        onClick: item => dispatch(setDishImageEditing(item.id)),
                    },
                    {
                        icon: <Edit />,
                        onClick: handleEdit,
                    },
                    {
                        icon: <Bin />,
                        onClick: item => deleter(item.id),
                    },
                ]}
                data={dishes.data}
                perPage={'all'}
                customPagination={<CustomPagination current={dishes.current_page} total={dishes.last_page} />}
            />

            <DishImageModal dishes={dishes.data} />
        </>
    );
};
