import { PageTitle } from 'components/layout/pageTitle';
import { Text } from 'components/text';
import { ActionGroup } from 'components/common/actionGroup';
import { Button } from 'components/common/button';
import { useApi } from 'hooks/useApi';
import { useFileDownloader } from 'hooks/useFileDownloader';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

export const OrdersByMenuPageTitle = () => {
    const { menuId } = useParams();
    const [fileLoading, setFileLoading] = useState(false);
    const downloadOrders = useApi(`/dishlists/${menuId}/print-orders`);
    const fileDownloader = useFileDownloader();

    const handleFileDownload = async () => {
        setFileLoading(true);
        const res = await downloadOrders();
        const blob = await res.blob();
        fileDownloader(blob, `orders-${menuId}.pdf`);
        setFileLoading(false);
    };
    return (
        <PageTitle
            title={<Text k={`Orders`} />}
            actions={
                <ActionGroup>
                    <Button
                        secondary
                        onClick={handleFileDownload}
                        loading={fileLoading}
                    >
                        <Text k={`Download Orders`} />
                    </Button>
                </ActionGroup>
            }
        />
    );
};
