import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Text } from 'components/text';
import { Button } from 'components/common/button';
import { useMenusApi } from 'hooks/api/useMenusApi';
import { MenusTable } from 'components/pages/menus/menusTable';
import { useState } from 'react';
import { useMenusUpdating } from 'store/menus/selectors';
import {ErrorBoundary} from "components/common/errorBoudry";

export const MenusPage = () => {
    useMenusApi();
    const updating = useMenusUpdating();
    const [processingDelete, setProcessingDelete] = useState(false);

    return (
        <ErrorBoundary boundary="menus">
            <ContentGrid>
                <ContentBlock>
                    <h1 className={`h1 h1_noMargin`}>
                        <Text k={`Menus`} />
                    </h1>
                </ContentBlock>
                <ContentBlock>
                    <ContentCard tinyPadding>
                        <Button secondary to={`/menu/new`}>
                            <Text k={`New Menu`} />
                        </Button>
                    </ContentCard>
                </ContentBlock>
                <ContentBlock>
                    <ContentCard loading={processingDelete || updating}>
                        <MenusTable onDelete={setProcessingDelete} />
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
        </ErrorBoundary>
    );
};
