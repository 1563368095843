import { useDispatch } from 'react-redux';
import styles from './complexEditor.module.css';
import { useNewComplex } from 'store/complexes/selectors';
import { CategorySelect } from 'components/pages/categories/categorySelect';
import { Add, Close } from 'components/icons';
import { Button } from 'components/common/button';
import { Text } from 'components/text';
import {
    addComplexDish,
    addComplexGroup,
    removeComplexDish,
    removeComplexGroup,
    updateComplexDish,
    updateComplexGroup,
} from 'store/complexes/actions';
import { ActionGroup } from 'components/common/actionGroup';
import { Checkbox } from 'components/common/checkbox';
import { DishSelect } from 'components/pages/dishes/dishSelect';
import { PriceTag } from 'components/common/priceTag/priceTag';
import { Input } from 'components/common/input';
import { useGetDishesByCategoryQuery } from '../../../../api/dishes/endpoints';
import { useMemo } from 'react';
import { DishType } from 'constants/dishType';

export const ComplexDishCard = ({ dish: { id, price }, index, categoryId, categoryIndex }) => {
    const dispatch = useDispatch();
    const { data: dishes } = useGetDishesByCategoryQuery({ category: categoryId, initial: true });

    const filteredDishes = useMemo(() => {
        if (!dishes) return [];
        return dishes.filter(dish => dish.type !== DishType.complex);
    }, [dishes]);

    const originalDish = filteredDishes.find(dish => dish.id === id);

    const originalPrice = originalDish ? originalDish.price : undefined;

    return (
        <div className={styles.editorConfig__dishCard}>
            <Button
                icon={<Close />}
                secondary
                type={'button'}
                aria-label={<Text k={`Close`} />}
                iconOnly
                onClick={() => dispatch(removeComplexDish(categoryIndex, index))}
            />
            <div className={styles.editorConfig__dishCardContainer}>
                <DishSelect
                    value={id}
                    dishOnly
                    onChange={option =>
                        dispatch(
                            updateComplexDish(categoryIndex, index, {
                                id: option.value,
                            })
                        )
                    }
                    category={categoryId}
                />
                {id ? (
                    <ActionGroup>
                        <PriceTag old={price && !(parseFloat(price) === parseFloat(originalPrice))}>
                            {originalPrice}
                        </PriceTag>
                        <Input
                            value={price}
                            id={`complex-editor-price-${categoryIndex}-${index}`}
                            aria-label="price"
                            type="number"
                            step={0.1}
                            min={0}
                            className={styles.editorConfig__priceInput}
                            placeholder={!price ? originalPrice : ''}
                            onChange={event =>
                                dispatch(
                                    updateComplexDish(categoryIndex, index, {
                                        price: event.target.value,
                                    })
                                )
                            }
                        />
                        {price ? (
                            <Button
                                secondary
                                type={'button'}
                                onClick={() =>
                                    dispatch(
                                        updateComplexDish(categoryIndex, index, {
                                            price: '',
                                        })
                                    )
                                }
                            >
                                Default
                            </Button>
                        ) : (
                            ''
                        )}
                    </ActionGroup>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export const ComplexGroupCard = ({ group: { id, dishes, optional }, index }) => {
    const dispatch = useDispatch();

    return (
        <div className={`${styles.editorConfig__groupCard} shadedBg`}>
            <ActionGroup className={styles.editorConfig__categoryHead}>
                <CategorySelect
                    className={styles.editorConfig__categorySelect}
                    id={`complex-editor-category-${index}`}
                    onChange={event =>
                        dispatch(
                            updateComplexGroup(index, {
                                id: event.target.value,
                            })
                        )
                    }
                    value={id}
                    aria-label={<Text k={`Category`} />}
                />
                <Button
                    icon={<Close />}
                    secondary
                    type={'button'}
                    aria-label={<Text k={`Close`} />}
                    iconOnly
                    onClick={() => dispatch(removeComplexGroup(index))}
                />
            </ActionGroup>
            {dishes.map((dish, dishIndex) => (
                <ComplexDishCard categoryId={id} dish={dish} categoryIndex={index} index={dishIndex} key={dishIndex} />
            ))}
            {id ? (
                <div className={styles.editorConfig__action}>
                    <Button icon={<Add />} secondary onClick={() => dispatch(addComplexDish(index))} type={'button'}>
                        <Text k={`Add a dish`} />
                    </Button>
                </div>
            ) : (
                ''
            )}
            <Checkbox
                id={`complex-editor-optional-${index}`}
                checked={optional}
                onChange={event =>
                    dispatch(
                        updateComplexGroup(index, {
                            optional: event.target.checked,
                        })
                    )
                }
            >
                <Text k={`Optional`} />
            </Checkbox>
        </div>
    );
};

export const ComplexEditor = () => {
    const dispatch = useDispatch();
    const { complex } = useNewComplex();

    return (
        <div className={styles.editorConfig}>
            <div className={styles.editorConfig__label}>
                <Text k={`Complex Groups`} />
            </div>
            {complex.map((group, index) => (
                <ComplexGroupCard group={group} key={index} index={index} />
            ))}
            <div className={styles.editorConfig__action}>
                <Button icon={<Add />} onClick={() => dispatch(addComplexGroup())} type={'button'}>
                    <Text k={`Add new group`} />
                </Button>
            </div>
        </div>
    );
};
