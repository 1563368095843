import { useMemo } from 'react';
import { Category } from 'store/categories/types';
import { NewMenuDish } from 'store/newMenu';
import { useGetAllCategoriesQuery } from '../api/categories/endpoints';

export type DishesByCategoryType = { category: Category; dishes: NewMenuDish[] }[];

export const useDishesByCategory = (list: NewMenuDish[]) => {
    const { data: categories } = useGetAllCategoriesQuery({});

    return useMemo(() => {
        const result: DishesByCategoryType = [];
        if (!categories) return result;
        list.forEach(dish => {
            const dishCategory = dish.category_id;
            const category = result.find(category => category.category.id == dishCategory);
            if (category) {
                category.dishes.push(dish);
            } else {
                const dishCategory = categories.find(category => category.id == dish.category_id);
                if (dishCategory) {
                    result.push({ category: dishCategory, dishes: [dish] });
                }
            }
        });
        return result;
    }, [categories, list]);
};
