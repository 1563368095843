import styles from './contentCard.module.css';
import { useModifiers } from 'hooks/useModifiers';
import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import { ThinLoader } from 'components/common/thinLoader';

interface ContentCardProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
    accent?: boolean;
    header?: boolean;
    tinyPadding?: boolean;
    title?: string | ReactNode;
    center?: boolean;
    loading?: boolean;
    smallPadding?: boolean;
    fixedHeight?: boolean;
    fixedContentHeight?: boolean;
    action?: ReactNode;
}

export const ContentCard: FunctionComponent<ContentCardProps> = ({
    children,
    className = '',
    accent,
    header,
    tinyPadding,
    title,
    center,
    loading,
    smallPadding,
    fixedHeight,
    fixedContentHeight,
    action,
    ...rest
}) => {
    const mods = useModifiers(styles, 'contentCard', {
        accent,
        header,
        tinyPadding,
        center,
        loading,
        smallPadding,
        fixedHeight,
        fixedContentHeight,
    });

    return (
        <div className={`${styles.contentCard} ${mods} ${className}`} {...rest}>
            <ThinLoader active={!!loading} card />
            <div className={styles.contentCard__wrapper}>
                {title || action ? (
                    <div className={styles.contentCard__titleWrapper}>
                        {title ? (
                            header ? (
                                <h1 className={`${styles.contentGrid__title} ${styles.contentCard__title} h1`}>
                                    {title}
                                </h1>
                            ) : (
                                <h2 className={`${styles.contentGrid__title}  ${styles.contentCard__title}`}>
                                    {title}
                                </h2>
                            )
                        ) : (
                            <div />
                        )}
                        {action || null}
                    </div>
                ) : null}
                <div className={styles.contentGrid__content}>{children}</div>
            </div>
        </div>
    );
};
