import { useDispatch } from 'react-redux';
import { HeaderDropdown } from '../headerDropdown';
import { Button } from '../../common/button';
import styles from './header.module.css';
import { setLang } from '../../../store/common/actions';
import { useActiveLang, useLanguages } from '../../../store/common/selectors';

export const LanguageSwitcher = () => {
    const dispatch = useDispatch();
    const languages = useLanguages();
    const activeLang = useActiveLang();

    return (
        <HeaderDropdown
            items={languages.map(language => ({
                onClick: () => dispatch(setLang(language.code)),
                label: language.code.toUpperCase(),
            }))}
        >
            <Button className={styles.header__reviewAction} secondary small>
                {activeLang.toUpperCase()}
            </Button>
        </HeaderDropdown>
    );
};
