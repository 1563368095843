import { createReducer } from '@reduxjs/toolkit';
import { resetKDS, setKDS, setKDSUpdating } from 'store/kds/actions';
import { DishRecord, KDSDishGroup } from 'store/kds/types';

const initialState: {
    data: {
        dishes: KDSDishGroup[];
        initials: DishRecord[][];
    } | null;
    updating: boolean;
} = {
    data: null,
    updating: false,
};

export const kdsReducer = createReducer(initialState, builder => {
    builder.addCase(setKDS, (state, { payload }) => {
        state.data = payload;
        state.updating = false;
    });
    builder.addCase(setKDSUpdating, (state, { payload }) => {
        state.updating = payload;
    });
    builder.addCase(resetKDS, state => {
        state.data = null;
    });
});
