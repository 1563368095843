import { createReducer } from '@reduxjs/toolkit';
import { setAllExpanded, setExpanded } from 'store/expandedMenu/actions';

export const expandedMenuInitialState: {
    allExpanded: boolean;
    expanded: { [id: string]: boolean };
} = {
    allExpanded: false,
    expanded: {},
};

export const expandedMenuReducer = createReducer(
    expandedMenuInitialState,
    builder => {
        builder.addCase(setExpanded, (state, { payload: id }) => {
            state.expanded[id] =
                state.expanded[id] === undefined
                    ? !state.allExpanded
                    : !state.expanded[id];
        });
        builder.addCase(setAllExpanded, state => {
            state.allExpanded = !state.allExpanded;
            state.expanded = {};
        });
    }
);
