import { useEffect, useState } from 'react';
import { DataTable } from 'components/common/dataTable';
import { Text } from 'components/text';
import { Copy, Demonetize, Disabled, Edit, ForwardMail, Monetize, TickFilled } from 'components/icons';
import { PriceTag } from 'components/common/priceTag/priceTag';
import { useDispatch } from 'react-redux';
import { useApi } from 'hooks/useApi';
import { useTranslation } from 'store/dictionary/selectors';
import { useCopyToClipboard } from 'hooks/useCoppyToClipboard';
import { useSearchParams } from 'react-router-dom';
import { CustomPagination } from 'components/common/pagination/customPagination';
import { useGetLocationsQuery } from '../../../../api/locations/endpoints';
import { Loader } from 'components/common/loader';
import { locationActions } from 'store/locations/slice';
import { useLocationsSearchQuery } from 'store/locations/selectors';

export const LocationsTable = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const pageNumber = searchParams.get('page') || 1;
    const [loading, setLoading] = useState([]);
    const copyTitle = useTranslation(`Copy location invite link`);
    const origin = window.location.origin;
    const clipboardCopier = useCopyToClipboard();
    const search = useLocationsSearchQuery();

    useEffect(() => {
        setSearchParams({ page: 1 });
    }, [search]);

    const { data: locations, isFetching } = useGetLocationsQuery({
        page: pageNumber,
        search,
    });

    const requestReconfirm = useApi('/locations');

    const updateLoading = (active, id) => {
        if (!active) {
            setLoading(loading.filter(item => item !== id));
        } else {
            setLoading([...loading, id]);
        }
    };

    const reconfirm = id => {
        const request = async () => {
            updateLoading(true, id);
            try {
                await requestReconfirm({
                    route: `/${id}/reconfirm`,
                });
            } catch (error) {
                console.error(error);
            }
            updateLoading(false, id);
        };
        request();
    };

    console.log(locations);
    if (isFetching || !locations) return <Loader />;
    return (
        <DataTable
            fields={[
                {
                    label: <Text k={`Location`} />,
                    key: 'title',
                    processor: (value, item) => (
                        <div>
                            <b>{value}</b>
                            <div>{item.address}</div>
                        </div>
                    ),
                },
                {
                    label: <Text k={`Active`} />,
                    key: 'status',
                    processor: value => (value ? <TickFilled /> : <Disabled />),
                },
                {
                    label: <Text k={`Profit`} />,
                    key: 'profit',
                    processor: value => (value ? <Monetize /> : <Demonetize />),
                },
                {
                    label: <Text k={`Confirmed`} />,
                    key: 'unconfirmed',
                    processor: value => (!value ? <TickFilled /> : <Disabled />),
                },
                {
                    label: <Text k={`Subsidy`} />,
                    key: 'subsidy',
                    processor: (value, item) =>
                        item.full_subsidy ? <Text k={'Full'} /> : <PriceTag secondary price={value || 0} />,
                },
            ]}
            itemActions={[
                {
                    icon: <ForwardMail />,
                    onClick: item => {
                        reconfirm(item.id);
                    },
                    loading: item => loading.includes(item.id),
                    active: item => item.unconfirmed,
                },
                {
                    icon: <Copy />,
                    title: copyTitle,
                    onClick: item => clipboardCopier(`${origin}/user/register-invited/${item.token}`),
                },
                {
                    icon: <Edit />,
                    onClick: item =>
                        dispatch(
                            locationActions.setNewLocation({
                                ...item,
                                active: true,
                            })
                        ),
                },
            ]}
            data={locations.data}
            perPage={'all'}
            customPagination={<CustomPagination current={locations.current_page} total={locations.last_page} />}
        />
    );
};
