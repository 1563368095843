import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { FunctionComponent, useEffect } from 'react';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Text } from 'components/text';
import { LapsModal } from 'components/pages/laps/lapsModal';
import { useMenuLapApi } from 'hooks/api/useMenuLapApi';
import { LapDishes } from 'components/pages/lap/lapDishes';
import { LapLocations } from 'components/pages/lap/lapLocations';
import { useLapTotals } from 'store/lap/selectors';
import { Loader } from 'components/common/loader';
import { useDispatch } from 'react-redux';
import { resetLap } from 'store/lap/actions';
import { LapLocationsTitle } from 'components/pages/lap/lapLocationsTitle';
import { ErrorBoundary } from 'components/common/errorBoudry';
import { useParams } from 'react-router-dom';

export const MenuLapPage: FunctionComponent = () => {
    const dispatch = useDispatch();
    const { menuId = '', lapId = '' } = useParams();
    useMenuLapApi(menuId, lapId);
    const total = useLapTotals();

    useEffect(() => {
        dispatch(resetLap());
    }, [dispatch]);

    return (
        <ErrorBoundary boundary="menu-lap">
            <ContentGrid>
                <ContentBlock>
                    <h1 className={`h1 h1_noMargin`}>
                        <Text k={`Lap`} />
                    </h1>
                </ContentBlock>
                <ContentBlock>
                    <ContentCard>
                        {total ? (
                            <LapDishes dishes={total.dishes} />
                        ) : (
                            <Loader />
                        )}
                        <LapLocationsTitle />
                        <LapLocations />
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
            <LapsModal />
        </ErrorBoundary>
    );
};
