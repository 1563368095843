import { createAction } from '@reduxjs/toolkit';
import {Order} from "store/types";

export const setOrdersByMenuLoading = createAction(
    'ordersByMenu/SET_ORDERS_LOADING',
    (loading: boolean) => {
        return {
            payload: loading,
        };
    }
);

export const setOrdersByMenuUpdating = createAction(
    'ordersByMenu/SET_ORDERS_UPDATING',
    (updating: boolean) => {
        return {
            payload: updating,
        };
    }
);

export const setOrdersByMenu = createAction(
    'ordersByMenu/SET_ORDERS',
    (orders: Order[]) => {
        return {
            payload: orders,
        };
    }
);

export const updateOrdersByMenuSingle = createAction(
    'ordersByMenu/UPDATE_ORDER',
    (locationId: string, orderId: string, order: Order | null) => {
        return {
            payload: { locationId, orderId, order },
        };
    }
);
