import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Loader } from 'components/common/loader';
import {
    useOrdersByMenuExist,
    useOrdersByMenuLoading,
} from 'store/ordersByMenu/selectors';
import { OrderList } from 'components/pages/ordersByMenu/orderList';
import { PageControls } from 'components/pages/ordersByMenu/pageControls';
import { OrdersByMenuPageTitle } from 'components/pages/ordersByMenu/pageTitle';
import { ErrorBoundary } from 'components/common/errorBoudry';

export const OrdersByMenuPage = () => {
    const loading = useOrdersByMenuLoading();
    const ordersExist = useOrdersByMenuExist();

    return (
        <ErrorBoundary boundary="orders-by-menu">
            <ContentGrid>
                <ContentBlock>
                    <OrdersByMenuPageTitle />
                </ContentBlock>
                <PageControls />
                {ordersExist && !loading ? (
                    <OrderList />
                ) : (
                    <ContentBlock>
                        <ContentCard>
                            <Loader />
                        </ContentCard>
                    </ContentBlock>
                )}
            </ContentGrid>
        </ErrorBoundary>
    );
};
