import {
    RESET_NEW_PROVIDER,
    SET_PROVIDERS,
    SET_NEW_PROVIDER,
    UPDATE_NEW_PROVIDER,
} from 'store/providers/constants';

const initialState = {
    new: {
        title: '',
        phone: '',
        email: '',
        status: true,
    },
    active: null,
};

export const providersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NEW_PROVIDER: {
            return { ...state, new: action.payload };
        }
        case UPDATE_NEW_PROVIDER: {
            return { ...state, new: { ...state.new, ...action.payload } };
        }
        case RESET_NEW_PROVIDER: {
            return { ...state, new: initialState.new };
        }
        case SET_PROVIDERS: {
            const { data } = action.payload;
            if (data && data.length) {
                return { ...state, ...action.payload, active: data[0].id };
            }
            return state;
        }
        default: {
            return state;
        }
    }
};
