import { useEffect } from 'react';
import { useNewMenu, useSelectedDishes } from 'store/newMenu/selectors';
import { NewMenuDishCard } from 'components/pages/newMenu/newMenuDishCard';
import { useDispatch } from 'react-redux';
import {removeSelectedDish, resetNewMenu} from 'store/newMenu/actions';
import { Form } from 'components/pages/newMenu/newMenu/form';
import { DishesByCategory } from 'components/menu/dishesByCategory';

export const NewMenu = () => {
    const dispatch = useDispatch();
    const selected = useSelectedDishes();

    const { id } = useNewMenu();

    useEffect(() => {
        return () => {
            if (id) {
                dispatch(resetNewMenu());
            }
        };
    }, [id, dispatch]);

    return (
        <div>
            <Form />
            <DishesByCategory
                list={selected}
                renderDish={dish => (
                    <NewMenuDishCard
                        key={dish.id}
                        dish={dish}
                        onRemove={() => dispatch(removeSelectedDish(dish.id))}
                    />
                )}
            />
        </div>
    );
};
