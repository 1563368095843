import { useApiData } from '../useApiData';
import { setTeam } from 'store/team/actions';
import { useDispatch } from 'react-redux';
import { useApi } from 'hooks/useApi';

export const useTeamApi = () => {
    useApiData('/me/team', setTeam);
};

export const useTeamRefresh = () => {
    const dispatch = useDispatch();
    const getTeam = useApi('/me/team');

    return async () => {
        const res = await getTeam();
        dispatch(setTeam(await res.json()));
    };
};
