import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { Text } from 'components/text';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { useHistoryApi } from 'hooks/api/useHistoryApi';
import { useHistory } from 'store/history/selectors';
import { Loader } from 'components/common/loader';
import { setHistory } from 'store/history/actions';
import {HistoryTable} from "components/pages/history/historyTable";
import {ErrorBoundary} from "components/common/errorBoudry";

export const HistoryPage = () => {
    useHistoryApi(setHistory);
    const history = useHistory();

    return (
        <ErrorBoundary boundary="history">
            <ContentGrid>
                <ContentBlock>
                    <h1 className={`h1 h1_noMargin`}>
                        <Text k={`History`} />
                    </h1>
                </ContentBlock>
                <ContentBlock>
                    {history && history.length ? (
                        <ContentCard>
                            <HistoryTable />
                        </ContentCard>
                    ) : (
                        <ContentBlock>
                            <ContentCard>
                                <Loader />
                            </ContentCard>
                        </ContentBlock>
                    )}
                </ContentBlock>
            </ContentGrid>
        </ErrorBoundary>
    );
};
