import { createReducer } from '@reduxjs/toolkit';
import {
    editSelectedComplexDishPrice,
    editSelectedDishPrice,
    enableEditSelectedComplex,
    removeSelectedDish,
    resetNewMenu,
    resetSelectedComplexDish,
    resetSelectedDish,
    setNewMenu,
    toggleSelectedDish,
    updateNewMenu,
} from 'store/newMenu/actions';
import { DishBalanceItem } from 'store/menu/types';

export interface NewMenuDish extends Omit<DishBalanceItem, 'qty'> {
    complex?: Omit<NewMenuDish, 'complex'>[];
    price?: string | number;
    category_id?: string;
}

const initialState: {
    id?: string;
    from: string;
    to: string;
    title: string;
    status: boolean;
    selectedDishes: NewMenuDish[];
} = {
    from: '',
    to: '',
    title: '',
    status: false,
    selectedDishes: [],
};

export const newMenuReducer = createReducer(initialState, builder => {
    builder.addCase(updateNewMenu, (state, { payload }) => {
        return { ...state, ...payload };
    });
    builder.addCase(resetNewMenu, () => {
        return initialState;
    });
    builder.addCase(setNewMenu, (state, { payload }) => {
        return payload;
    });
    builder.addCase(toggleSelectedDish, (state, { payload: dishToToggle }) => {
        if (state.selectedDishes.some(item => item.id === dishToToggle.id)) {
            state.selectedDishes = state.selectedDishes.filter(item => item.id !== dishToToggle.id);
        } else {
            state.selectedDishes = [
                ...state.selectedDishes,
                { id: dishToToggle.id, category_id: dishToToggle.category_id },
            ];
        }
    });
    builder.addCase(removeSelectedDish, (state, { payload: dishId }) => {
        if (state.selectedDishes.some(item => item.id === dishId)) {
            state.selectedDishes = state.selectedDishes.filter(item => item.id !== dishId);
        }
    });
    builder.addCase(editSelectedDishPrice, (state, { payload: { dishId, price } }) => {
        const dishIndex = state.selectedDishes.findIndex(item => item.id === dishId);
        if (dishIndex >= 0) {
            state.selectedDishes[dishIndex].price = price;
        }
    });
    builder.addCase(editSelectedComplexDishPrice, (state, { payload: { complexId, dishId, price } }) => {
        const complexIndex = state.selectedDishes.findIndex(item => item.id === complexId);
        if (complexIndex < 0) return state;
        const complex = state.selectedDishes[complexIndex].complex;
        if (!complex) return state;
        const dishIndex = complex.findIndex(item => dishId === item.id);
        if (dishIndex < 0) {
            complex.push({ id: dishId, price: price });
        } else {
            complex[dishIndex] = { ...complex[dishIndex], price };
        }
    });
    builder.addCase(resetSelectedComplexDish, (state, { payload: { complexId, dishId } }) => {
        const complexIndex = state.selectedDishes.findIndex(item => item.id === complexId);
        if (complexIndex < 0) return state;
        const complex = state.selectedDishes[complexIndex].complex;
        if (!complex) return state;
        const dishIndex = complex.findIndex(item => dishId === item.id);
        if (dishIndex < 0) return state;
        const dish = complex[dishIndex];
        complex[dishIndex] = { id: dish.id, category_id: dish.category_id };
    });
    builder.addCase(enableEditSelectedComplex, (state, { payload: dishId }) => {
        const dishIndex = state.selectedDishes.findIndex(item => item.id === dishId);
        if (dishIndex < 0) return state;
        const dish = state.selectedDishes[dishIndex];
        if (dish.complex) return state;
        dish.complex = [];
    });
    builder.addCase(resetSelectedDish, (state, { payload: dishId }) => {
        const dishIndex = state.selectedDishes.findIndex(item => item.id === dishId);
        if (dishIndex < 0) return state;
        const dish = state.selectedDishes[dishIndex];
        if (dish.complex) {
            state.selectedDishes[dishIndex] = { id: dish.id, category_id: dish.category_id, complex: [] };
        } else {
            state.selectedDishes[dishIndex] = { id: dish.id, category_id: dish.category_id };
        }
    });
});

// export const newMenuReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case UPDATE_NEW_MENU: {
//             return { ...state, ...action.payload };
//         }
//         case RESET_NEW_MENU: {
//             return { ...initialState };
//         }
//         case SET_NEW_MENU: {
//             return { ...action.payload };
//         }
//         case NEW_MENU_TOGGLE_SELECTED_DISH: {
//             const idToToggle = action.payload;
//             const { selectedDishes } = state;
//             if (selectedDishes.some(item => item.id === idToToggle)) {
//                 return {
//                     ...state,
//                     selectedDishes: selectedDishes.filter(
//                         item => item.id !== idToToggle
//                     ),
//                 };
//             } else {
//                 return {
//                     ...state,
//                     selectedDishes: [...selectedDishes, { id: idToToggle }],
//                 };
//             }
//         }
//         case NEW_MENU_EDIT_SELECTED_DISH_PRICE: {
//             const { dishId, price } = action.payload;
//             const selectedDishes = [...state.selectedDishes];
//             const dishIndex = selectedDishes.findIndex(
//                 item => item.id == dishId
//             );
//             if (dishIndex < 0) return state;
//             selectedDishes[dishIndex] = { ...selectedDishes[dishIndex], price };
//             return { ...state, selectedDishes: selectedDishes };
//         }
//         case NEW_MENU_EDIT_SELECTED_COMPLEX_DISH_PRICE: {
//             const { complexId, dishId, price } = action.payload;
//             const selectedDishes = [...state.selectedDishes];
//             const complexIndex = selectedDishes.findIndex(
//                 item => item.id == complexId
//             );
//             if (complexIndex < 0 || !selectedDishes[complexIndex].complex)
//                 return state;
//             const dishes = [...selectedDishes[complexIndex].complex];
//             const dishIndex = dishes.findIndex(item => dishId === item.id);
//             if (dishIndex < 0) {
//                 dishes.push({ id: dishId, price: price });
//             } else {
//                 dishes[dishIndex] = { ...dishes[dishIndex], price };
//             }
//             return { ...state, selectedDishes: selectedDishes };
//         }
//         case NEW_MENU_RESET_SELECTED_COMPLEX_DISH: {
//             const { complexId, dishId } = action.payload;
//             const selectedDishes = [...state.selectedDishes];
//             const complexIndex = selectedDishes.findIndex(
//                 item => item.id == complexId
//             );
//             if (complexIndex < 0 || !selectedDishes[complexIndex].complex)
//                 return state;
//             const dishes = selectedDishes[complexIndex].complex;
//             const dishIndex = dishes.findIndex(item => dishId === item.id);
//             if (dishIndex < 0) return state;
//             dishes[dishIndex] = { id: dishId };
//             return { ...state, selectedDishes: selectedDishes };
//         }
//         case NEW_MENU_ENABLE_EDIT_SELECTED_COMPLEX: {
//             const dishId = action.payload;
//             const selectedDishes = [...state.selectedDishes];
//             const dishIndex = selectedDishes.findIndex(
//                 item => item.id == dishId
//             );
//             if (dishIndex < 0 || selectedDishes[dishIndex].complex)
//                 return state;
//             selectedDishes[dishIndex] = {
//                 ...selectedDishes[dishIndex],
//                 complex: [],
//             };
//             return { ...state, selectedDishes: selectedDishes };
//         }
//         case NEW_MENU_RESET_SELECTED_DISH: {
//             const dishId = action.payload;
//             const selectedDishes = [...state.selectedDishes];
//             const dishIndex = selectedDishes.findIndex(
//                 item => item.id == dishId
//             );
//             if (dishIndex < 0) return state;
//             if (selectedDishes[dishIndex].complex) {
//                 selectedDishes[dishIndex] = { id: dishId, complex: [] };
//             } else {
//                 selectedDishes[dishIndex] = { id: dishId };
//             }
//             return { ...state, selectedDishes: selectedDishes };
//         }
//         default: {
//             return state;
//         }
//     }
// };
