import { useMaintenance } from 'store/common/selectors';
import { Navigate } from 'react-router-dom';
import { Text } from 'components/text';
import { PublicCard } from 'components/layout/publicCard';
import {ErrorBoundary} from "components/common/errorBoudry";

export const MaintenancePage = () => {
    const maintenance = useMaintenance();
    return (
        <ErrorBoundary boundary="maintenance">
            {!maintenance ? <Navigate to={'/'} /> : ''}
            <PublicCard title={<Text k={`Website is under maintenance`} />} />
        </ErrorBoundary>
    );
};
