import {
    RESET_NEW_PROVIDER,
    SET_PROVIDERS,
    SET_NEW_PROVIDER,
    UPDATE_NEW_PROVIDER,
} from 'store/providers/constants';

export const setProviders = locations => {
    return {
        type: SET_PROVIDERS,
        payload: locations,
    };
};

export const setNewProvider = newCategory => {
    return {
        type: SET_NEW_PROVIDER,
        payload: newCategory,
    };
};

export const updateNewProvider = subset => {
    return {
        type: UPDATE_NEW_PROVIDER,
        payload: subset,
    };
};

export const resetNewProvider = () => {
    return {
        type: RESET_NEW_PROVIDER,
    };
};
