import { api } from '../api';
import { LocationType } from 'store/locations/types';
import { NewLocationType } from 'store/locations/slice';

const locationsApi = api.injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
        getAllLocations: builder.query<LocationType[], void>({
            query: () => ({
                url: '/app/locations',
                params: {
                    per_page: 'all',
                },
            }),
        }),
        getLocations: builder.query<LocationType[], { pageNumber: number; perPage: number; search?: string }>({
            query: ({ pageNumber, perPage = 50, search }) => {
                const params: Record<string, string | number | boolean> = {
                    per_page: perPage,
                    page: pageNumber,
                };
                if (search) {
                    params['search'] = search;
                } else {
                    params['sort[created_at]'] = 'desc';
                }
                return {
                    url: '/app/locations',
                    params,
                };
            },
            providesTags: ['Locations'],
        }),
        updateLocation: builder.mutation<void, NewLocationType | LocationType>({
            // @ts-ignore
            query: ({ id, ...item }) => {
                return {
                    body: item,
                    method: 'PUT',
                    url: `/app/locations/${id}`,
                };
            },
            invalidatesTags: ['Locations'],
        }),
        newLocation: builder.mutation<void, NewLocationType | LocationType>({
            query: item => {
                return {
                    body: item,
                    method: 'POST',
                    url: `/app/locations`,
                };
            },
            invalidatesTags: ['Locations'],
        }),
    }),
});

export const { useGetLocationsQuery, useGetAllLocationsQuery, useNewLocationMutation, useUpdateLocationMutation } =
    locationsApi;
