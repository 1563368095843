import { SET_DICTIONARY } from './constants';

export const dictionaryReducer = (
    state = { dictionary: {}, languages: [] },
    action
) => {
    switch (action.type) {
        case SET_DICTIONARY: {
            return action.payload.dictionary;
        }
        default: {
            return state;
        }
    }
};
