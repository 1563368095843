import { useState } from 'react';
import styles from './loginForm.module.css';
import { ErrorBlock } from 'components/common/errorBlock';
import { Input } from 'components/common/input';
import { Button } from 'components/common/button';
import { useDispatch } from 'react-redux';
import { useLoginFields } from 'store/login/selectors';
import { setLoginField } from 'store/login/actions';
import { constants } from '../../../../constants';
import { setToken } from 'store/common/actions';
import { Link } from 'react-router-dom';
import {useSearchParam} from "hooks/useSearchParam";
import {InfoTag} from "components/common/infoTag";
import {Text} from "components/text";

export const LoginForm = () => {
    const dispatch = useDispatch();
    const fields = useLoginFields();
    const [error, setError] = useState(null);
    const reset = useSearchParam('reset')

    const handleChange = (name, value) => {
        dispatch(setLoginField({ name, value }));
    };

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            const res = await fetch(`${constants.apiPath}/app/login`, {
                method: 'post',
                body: JSON.stringify(fields),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            const data = await res.json();
            if (!data.errors) {
                setError(null);
                dispatch(setToken(data.token));
            } else {
                setError(data.errors.email[0]);
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <form className={styles.loginForm} onSubmit={handleSubmit}>
            {reset ? <InfoTag><Text k={`Your password has been successfully reset`}/></InfoTag> : ''}
            {error ? <ErrorBlock>{error}</ErrorBlock> : ''}
            <Input
                id={'email'}
                label={'Email'}
                type="email"
                autoComplete="email"
                inputMode="email"
                value={fields['email']}
                onChange={e => handleChange('email', e.target.value)}
            />
            <Input
                id={'password'}
                label={'Password'}
                type="password"
                value={fields['password']}
                onChange={e => handleChange('password', e.target.value)}
            />
            <Button type="submit" className={styles.loginForm__action}>
                Log in
            </Button>
            <div className={styles.loginForm__action}>
                <Link
                    className={styles.loginForm__link}
                    to={`/password/request`}
                >
                    Forgot password?
                </Link>
            </div>
        </form>
    );
};
