import { useSelectedDishes } from 'store/newMenu/selectors';
import { useDispatch } from 'react-redux';
import { toggleSelectedDish } from 'store/newMenu/actions';
import { DishSelector } from 'components/pages/dishes/dishSelector';

export const NewMenuForm = ({ onLoading }) => {
    const dispatch = useDispatch();
    const selected = useSelectedDishes();

    const handleSelect = item => {
        dispatch(toggleSelectedDish(item));
    };

    const checkSelected = item => {
        return selected.some(selectedItem => selectedItem.id === item.id);
    };

    return (
        <>
            <DishSelector
                onSelect={handleSelect}
                isSelected={checkSelected}
                onLoading={onLoading}
            />
        </>
    );
};
