import { useAppSelector } from 'store/index';

export const useLaps = () => {
    return useAppSelector(({ laps }) => {
        return laps.laps;
    });
};

export const useNewLap = () => {
    return useAppSelector(({ laps }) => {
        return laps.new;
    });
};

export const useLapsUpdating = () => {
    return useAppSelector(({ laps }) => {
        return laps.updating;
    });
};
