import React, {
    AnchorHTMLAttributes,
    ButtonHTMLAttributes,
    FunctionComponent,
    PropsWithChildren,
    ReactNode,
} from 'react';
import styles from './button.module.css';
import { useModifiers } from 'hooks/useModifiers';
import { Link, LinkProps } from 'react-router-dom';
import { Loader } from 'components/common/loader';

interface ButtonWrapperLinkProps extends Omit<LinkProps, 'to'> {
    href: never;
    to?: string;
    disabled?: never;
}

interface ButtonWrapperAnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    to: never;
    href?: string;
    disabled?: never;
}

interface ButtonWrapperButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    to: never;
    href: never;
}

type ButtonWrapperProps = ButtonWrapperLinkProps | ButtonWrapperAnchorProps | ButtonWrapperButtonProps;

const Wrapper: FunctionComponent<ButtonWrapperProps> = ({ children, href, to, disabled, ...rest }) => {
    if (to || href) {
        if (disabled) return <span {...rest}>{children}</span>;
        if (to) {
            return (
                // @ts-ignore
                <Link to={to} {...rest}>
                    {children}
                </Link>
            );
        }
        return (
            // @ts-ignore
            <a href={href} {...rest}>
                {children}
            </a>
        );
    } else {
        return React.createElement(
            href ? 'a' : 'button',
            // @ts-ignore
            href ? rest : { type: 'button', disabled, ...rest },
            children
        );
    }
};

interface ButtonProps extends PropsWithChildren {
    className?: string;
    icon?: ReactNode;
    to?: string;
    href?: string;
    title?: string;
    onClick?: (event?: any) => any;
    type?: string;
    disabled?: boolean;
    form?: string;
    download?: boolean | string;

    small?: boolean;
    danger?: boolean;
    inverse?: boolean;
    loading?: boolean;
    iconOnly?: boolean;
    secondary?: boolean;
}

export const Button: FunctionComponent<ButtonProps> = ({
    children,
    className = '',
    icon,
    disabled,

    small,
    danger,
    inverse,
    loading,
    iconOnly,
    secondary,
    ...rest
}) => {
    const mods = useModifiers(styles, 'button', {
        icon,
        disabled,

        small,
        danger,
        inverse,
        loading,
        iconOnly: iconOnly || !children,
        secondary,
    });

    return (
        //@ts-ignore
        <Wrapper className={`${styles.button} ${mods} ${className}`} disabled={disabled || loading} {...rest}>
            <span className={`${styles.button__content}`}>
                {icon ? <span className={styles.button__icon}>{icon}</span> : ''}
                {children ? <span>{children}</span> : ''}
            </span>
            <span className={styles.button__loader}>
                <Loader />
            </span>
        </Wrapper>
    );
};
