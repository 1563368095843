import { api } from '../api';
import { Paginatable } from '../types';
import { Category, NewCategory } from 'store/categories/types';

const categoriesApi = api.injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
        getCategories: builder.query<
            Paginatable<Category[]>,
            {
                page: number;
                perPage?: number;
            }
        >({
            query: ({ page, perPage = 50 }) => {
                const params: Record<string, string | number | boolean> = {
                    'per_page': perPage,
                    page,
                    'sort[created_at]': 'desc',
                };
                return {
                    url: '/app/categories',
                    params,
                };
            },
            providesTags: ['Categories'],
        }),
        getAllCategories: builder.query<Category[], { activeOnly?: boolean }>({
            query: ({ activeOnly = true }) => {
                const params: Record<string, string | number | boolean> = {
                    'per_page': 'all',
                    'sort[created_at]': 'desc',
                };
                if (activeOnly) {
                    params['filter[status]'] = '1';
                }
                return {
                    url: '/app/categories',
                    params,
                };
            },
            providesTags: ['Categories'],
        }),
        updateCategory: builder.mutation<void, NewCategory>({
            query: ({ id, ...item }) => {
                return {
                    body: item,
                    method: 'PUT',
                    url: `/app/categories/${id}`,
                };
            },
            invalidatesTags: ['Categories'],
        }),
        newCategory: builder.mutation<void, NewCategory>({
            query: ({ id, ...item }) => {
                return {
                    body: item,
                    method: 'POST',
                    url: `/app/categories`,
                };
            },
            invalidatesTags: ['Categories'],
        }),
        deleteCategories: builder.mutation<void, string>({
            query: id => {
                return {
                    method: 'DELETE',
                    url: `/app/categories/${id}`,
                };
            },
            invalidatesTags: ['Categories'],
        }),
    }),
});

export const {
    useGetCategoriesQuery,
    useGetAllCategoriesQuery,
    useUpdateCategoryMutation,
    useNewCategoryMutation,
    useDeleteCategoriesMutation,
} = categoriesApi;
