import styles from './title.module.css';
import { Text } from 'components/text';
import {
    useMenuHistory,
    useMenuHistoryMenu,
} from 'store/menuHistory/selectors';
import { TimeBlock } from 'components/order/timeBlock/timeBlock';
import { SumCard } from 'components/common/sumCard';
import { useMemo, useState } from 'react';
import { Button } from 'components/common/button';
import { useApi } from 'hooks/useApi';
import { useFileDownloader } from 'hooks/useFileDownloader';

export const MenuHistoryTitle = ({ menuId }) => {
    const historyMenu = useMenuHistoryMenu();
    const menuHistory = useMenuHistory();
    const [fileLoading, setFileLoading] = useState(false);
    const downloadOrders = useApi(`/dishlists/${menuId}/print-orders`);
    const fileDownloader = useFileDownloader();

    const sum = useMemo(() => {
        const sum = { cash: 0, card: 0, subsidy: 0, bonus: 0 };
        if (menuHistory) {
            menuHistory.forEach(order => {
                sum.subsidy += order.subsidy;
                sum.bonus += order.bonus;
                switch (order.payment_type) {
                    case 'cash': {
                        sum.cash += order.amount_to_pay;
                        break;
                    }
                    case 'card': {
                        sum.card += order.amount_to_pay;
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });
        }
        return sum;
    }, [menuHistory]);

    const handleFileDownload = async () => {
        setFileLoading(true);
        const res = await downloadOrders();
        const blob = await res.blob();
        fileDownloader(blob, `orders-${menuId}.pdf`);
        setFileLoading(false);
    };

    return (
        <div className={`${styles.title} shaded`}>
            <div className={styles.title__wing}>
                <div className={styles.title__content}>
                    <div className={styles.title__text}>
                        <h1 className={` h1 h1_noMargin`}>
                            <Text k={`History`} />
                        </h1>
                    </div>
                    <div className={styles.title__time}>
                        {historyMenu ? (
                            <TimeBlock
                                noTime
                                to={historyMenu.to}
                                from={historyMenu.from}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                {menuHistory ? <SumCard {...sum} /> : null}
            </div>
            <div className={styles.title__wing}>
                <Button
                    secondary
                    onClick={handleFileDownload}
                    loading={fileLoading}
                >
                    <Text k={`Download Report`} />
                </Button>
            </div>
        </div>
    );
};
