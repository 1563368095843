import { Modal, ModalProps } from 'components/layout/modal';
import { Text } from 'components/text';
import { FunctionComponent, ReactNode } from 'react';

interface ConfirmModalProps extends ModalProps {
    callback?: null | (() => void);
    label: string | ReactNode;
}

export const ConfirmModal: FunctionComponent<ConfirmModalProps> = ({
    callback,
    label,
    onClose,
    ...rest
}) => {
    return (
        <Modal
            actions={[
                {
                    label: <Text k={`Cancel`} />,
                    secondary: true,
                    action: 'cancel',
                },
                {
                    label: label,
                    action: () => {
                        if (callback) callback();
                        onClose();
                    },
                },
            ]}
            onClose={onClose}
            {...rest}
        />
    );
};
