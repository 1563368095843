import { useMenuUserData } from 'store/menu/selectors';
import styles from './cartTotal.module.css';
import { Text } from 'components/text';
import { usePriceFormatter } from 'hooks/useFormattedPrice';
import { useTotalPrice } from 'hooks/useTotalPrice';
import { useSubtotalPrice } from 'hooks/useSubtotalPrice';
import { FunctionComponent } from 'react';

export const CartTotal: FunctionComponent<{ commission?: number }> = ({ commission }) => {
    const userData = useMenuUserData();
    const priceFormatter = usePriceFormatter();
    const total = useTotalPrice();
    const subtotal = useSubtotalPrice();

    if (!userData) return null;

    const { subsidy, full_subsidy, bonus } = userData;

    return (
        <div className={styles.cartTotal}>
            {bonus || subsidy || full_subsidy ? (
                <div className={`${styles.cartTotal__row} ${styles.cartTotal__row_subtotal}`}>
                    <div>
                        <Text k={`Total`} />:
                    </div>
                    <div>{priceFormatter(subtotal)}</div>
                </div>
            ) : null}
            {!full_subsidy && bonus ? (
                <div className={styles.cartTotal__row}>
                    <div>
                        <Text k={`Bonus`} />:
                    </div>
                    <div className={styles.cartTotal__discount}>-{priceFormatter(bonus)}</div>
                </div>
            ) : (
                ''
            )}
            {subsidy || full_subsidy ? (
                <div className={styles.cartTotal__row}>
                    <div>
                        <Text k={`Subsidy`} />:
                    </div>
                    {subsidy ? (
                        <div className={styles.cartTotal__discount}>-{priceFormatter(subsidy)}</div>
                    ) : (
                        <div className={styles.cartTotal__discount}>
                            <Text k={`Full`} />
                        </div>
                    )}
                </div>
            ) : (
                ''
            )}
            <div className={`${styles.cartTotal__row} ${styles.cartTotal__row_bold}`}>
                <div>
                    <Text k={`To pay`} />:
                </div>
                <div>{priceFormatter(total + (commission || 0))}</div>
            </div>
        </div>
    );
};
