import styles from './loader.module.css';
import { FunctionComponent } from 'react';

export const Loader: FunctionComponent = () => {
    return (
        <span className={styles.loader__container}>
            <span className={styles.loader}>
                <span />
                <span />
                <span />
                <span />
            </span>
        </span>
    );
};
