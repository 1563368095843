import { useState } from 'react';
import { ActionGroup } from 'components/common/actionGroup';
import { Input } from 'components/common/input';
import { useDispatch } from 'react-redux';
import { setSearchCategory, setSearchQuery } from 'store/dishes/actions';
import { useTranslation } from 'store/dictionary/selectors';
import { CategorySelect } from 'components/pages/categories/categorySelect';
import { FilterCard } from 'components/common/filterCard';
import { useSearchCategory, useSearchQuery } from 'store/dishes/selectors';

export const DishFilterCard = () => {
    const dispatch = useDispatch();
    // const [category, setCategory] = useState('');
    // const [searchQuery, setSearchQuery] = useState('');
    const [loading] = useState(false);
    const filterPlaceholder = useTranslation(`Filter...`);

    const search = useSearchQuery();
    const category = useSearchCategory();

    return (
        <FilterCard
            tinyPadding
            loading={loading}
            isSet={search || category}
            onReset={() => {
                dispatch(setSearchQuery(''));
                dispatch(setSearchCategory(''));
            }}
        >
            <div>
                <ActionGroup>
                    <Input
                        id={'dishes-search'}
                        placeholder={filterPlaceholder}
                        value={search}
                        onChange={event =>
                            dispatch(setSearchQuery(event.target.value))
                        }
                    />
                    <CategorySelect
                        id={`dish-filter-category`}
                        value={category}
                        onChange={event =>
                            dispatch(setSearchCategory(event.target.value))
                        }
                    />
                </ActionGroup>
            </div>
        </FilterCard>
    );
};
