import { Button } from 'components/common/button';
import { Dashboard, Network, View } from 'components/icons';
import { Text } from 'components/text';
import { DataTable } from 'components/common/dataTable';
import { Loader } from 'components/common/loader';
import { useMenus, useMenusLoading } from 'store/menus/selectors';
import format from 'date-fns/format';

export const MenusTable = () => {
    const menus = useMenus();
    const loading = useMenusLoading();

    if (loading) return <Loader />;
    return (
        <DataTable
            key={'ordersTable'}
            placeholderAction={
                <Button to={'/menu/new'}>
                    <Text k={`Try adding new Menu`} />
                </Button>
            }
            fields={[
                {
                    label: <Text k={`Title`} />,
                    key: 'title',
                },
                {
                    label: <Text k={`From`} />,
                    key: 'from',
                    processor: value => {
                        return format(new Date(value), 'dd.LL.yy HH:mm');
                    },
                },
                {
                    label: <Text k={`To`} />,
                    key: 'to',
                    processor: value => {
                        return format(new Date(value), 'dd.LL.yy HH:mm');
                    },
                },
            ]}
            itemActions={[
                {
                    icon: <View />,
                    to: (item: { id: string }) => `/orders/${item.id}?accepted=0`,
                },
                {
                    icon: <Dashboard />,
                    to: (item: { id: string }) => `/kds/${item.id}`,
                },
                {
                    icon: <Network />,
                    to: (item: { id: string }) => `/menu-laps/${item.id}`,
                },
            ]}
            data={menus}
        />
    );
};
