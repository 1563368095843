import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { FunctionComponent } from 'react';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { useLapsApi } from 'hooks/api/useLapsApi';
import { Text } from 'components/text';
import { LapsTable } from 'components/pages/laps/lapsTable';
import { Button } from 'components/common/button';
import { useDispatch } from 'react-redux';
import { setLapEditing } from 'store/laps/actions';
import { LapsModal } from 'components/pages/laps/lapsModal';
import { useLapsUpdating } from 'store/laps/selectors';
import { LapUnassignedList } from 'components/pages/laps/lapUnassignedList';
import { ErrorBoundary } from 'components/common/errorBoudry';

export const LapsPage: FunctionComponent = () => {
    const dispatch = useDispatch();
    useLapsApi();
    const updating = useLapsUpdating();

    return (
        <ErrorBoundary boundary="laps">
            <ContentGrid>
                <ContentBlock>
                    <h1 className={`h1 h1_noMargin`}>
                        <Text k={`Laps`} />
                    </h1>
                </ContentBlock>
                <ContentBlock>
                    <ContentCard tinyPadding>
                        <Button
                            secondary
                            onClick={() => {
                                dispatch(setLapEditing());
                            }}
                        >
                            <Text k={`New Lap`} />
                        </Button>
                    </ContentCard>
                </ContentBlock>
                <ContentBlock>
                    <ContentCard loading={updating}>
                        <LapsTable />
                    </ContentCard>
                </ContentBlock>
                <ContentBlock>
                    <h1 className={`h1 h1_noMargin`}>
                        <Text k={`Unsigned`} />
                    </h1>
                </ContentBlock>
                <ContentBlock>
                    <ContentCard>
                        <LapUnassignedList />
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
            <LapsModal />
        </ErrorBoundary>
    );
};
