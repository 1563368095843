import { SearchableSelect, SearchableSelectProps } from 'components/common/searchableSelect';
import { Text } from 'components/text';
import { useUserList } from 'store/userList/selectors';
import { useTranslation } from 'store/dictionary/selectors';

interface UserSelectProps<Value extends string> extends Omit<SearchableSelectProps, 'value'> {
    value: Value;
}

export const UserSelect = <Value extends string>({ value, ...rest }: UserSelectProps<Value>) => {
    const userList = useUserList();
    const emptyLabel = useTranslation(`Select a user...`);

    if (!userList || !Array.isArray(userList)) return null;

    const users: { value: string; label: string }[] = userList.map(({ id, name, phone, location }) => ({
        value: id,
        label: `${name}, ${phone}, ${location}`,
    }));

    const user = users.find(user => user.value === value);

    return (
        <SearchableSelect
            label={<Text k={`On behalf of`} />}
            value={user}
            grow
            placeholder={emptyLabel}
            options={users}
            {...rest}
        />
    );
};
