import { SET_NAV_OPEN, SET_NAV_STRUCTURE } from './constants';

export const setNavOpen = open => {
    return {
        type: SET_NAV_OPEN,
        payload: open,
    };
};

export const setNavStructure = structure => {
    return {
        type: SET_NAV_STRUCTURE,
        payload: structure,
    };
};
