import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Text } from 'components/text';
import { constants } from '../../../constants';
import { connect } from 'react-redux';
import { RootState } from 'store/index';

interface Props {
    children: ReactNode;
    boundary: string;
    token: string | null;
}

interface State {
    hasError: boolean;
}

class ErrorBoundaryComponent extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        const log = async () => {
            if (this.props.token) {
                const res = await fetch(
                    `${constants.apiPath}/app/frontend-exception`,
                    {
                        method: 'POST',
                        body: JSON.stringify({
                            environment: process.env.NODE_ENV,
                            boundary: this.props.boundary,
                            error,
                            errorInfo,
                        }),
                        headers: {
                            'Authorization': `Bearer ${this.props.token}`,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }
                );
                console.log(res);
            }
        };

        console.error(
            `Uncaught error in ${this.props.boundary}:`,
            error,
            errorInfo
        );
        log();
    }

    public render() {
        if (this.state.hasError) {
            return <Text k={`Something went wrong...`} />;
        }

        return this.props.children;
    }
}

const mapStateToProps = (state: RootState) => {
    return { token: state.common.token };
};

export const ErrorBoundary = connect(mapStateToProps)(ErrorBoundaryComponent);
