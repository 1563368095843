import { createAction } from '@reduxjs/toolkit';
import { PublicMenuReducer } from 'store/publicMenu/index';

export const setPublicMenu = createAction(
    'public-menu/SET',
    (menu: PublicMenuReducer['dishlist']) => {
        return {
            payload: menu,
        };
    }
);
