import { SET_LOGIN_FIELD } from './constants';

export const loginReducer = (
    state = {
        email: '',
        password: '',
        rememberme: true,
    },
    action
) => {
    switch (action.type) {
        case SET_LOGIN_FIELD: {
            const { name, value } = action.payload;
            return { ...state, [name]: value };
        }
        default: {
            return state;
        }
    }
};
