import PropTypes from 'prop-types';
import { Select } from 'components/common/select';
import { useTranslator } from 'hooks/useTranslator';
import { useTranslation } from 'store/dictionary/selectors';
import { useGetAllCategoriesQuery } from '../../../../api/categories/endpoints';
import { Loader } from 'components/common/loader';

const CategorySelect = ({ id, ...rest }) => {
    const { data: categories, isFetching } = useGetAllCategoriesQuery({});
    const translator = useTranslator();
    const emptyLabel = useTranslation(`Select a category...`);

    if (isFetching || !categories) return <Loader />;
    return (
        <Select
            id={id}
            emptyLabel={emptyLabel}
            options={categories.map(({ id, title }) => ({
                key: id,
                label: translator(title),
            }))}
            {...rest}
        />
    );
};

CategorySelect.propTypes = {
    id: PropTypes.string.isRequired,
};

export { CategorySelect };
