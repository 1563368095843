import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { Text } from 'components/text';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Loader } from 'components/common/loader';
import { useMenusApi } from 'hooks/api/useMenusApi';
import { useMenus, useMenusUpdating } from 'store/menus/selectors';
import { MenusTable } from 'components/pages/orders/menusTable';
import { ErrorBoundary } from 'components/common/errorBoudry';

export const OrdersPage = () => {
    useMenusApi({ params: { 'filter[status]': 1, 'per_page': 'all' } });
    const updating = useMenusUpdating();
    const menus = useMenus();

    return (
        <ErrorBoundary boundary="orders">
            <ContentGrid>
                <ContentBlock>
                    <h1 className={`h1 h1_noMargin`}>
                        <Text k={`Orders`} />
                    </h1>
                </ContentBlock>
                <ContentBlock>
                    <ContentCard loading={updating}>
                        {menus && menus.length ? <MenusTable /> : <Loader />}
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
        </ErrorBoundary>
    );
};
