import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Text } from 'components/text';
import { Button } from 'components/common/button';
import { useDispatch } from 'react-redux';
import { ActionGroup } from 'components/common/actionGroup';
import { updateNewProvider } from 'store/providers/actions';
import { useProvidersApi } from 'hooks/api/useProvidersApi';
import { ProvidersTable } from 'components/pages/providers/providersTable';
import { NewProviderModal } from 'components/pages/providers/newProviderModal';
import { ErrorBoundary } from 'components/common/errorBoudry';

export const ProvidersPage = () => {
    const dispatch = useDispatch();
    useProvidersApi();

    return (
        <ErrorBoundary boundary="providers">
            <ContentGrid>
                <ContentBlock>
                    <h1 className={`h1 h1_noMargin`}>
                        <Text k={`Providers`} />
                    </h1>
                </ContentBlock>
                <ContentBlock>
                    <ContentCard tinyPadding>
                        <ActionGroup>
                            <Button
                                secondary
                                onClick={() =>
                                    dispatch(
                                        updateNewProvider({ active: true })
                                    )
                                }
                            >
                                <Text k={`New Provider`} />
                            </Button>
                        </ActionGroup>
                    </ContentCard>
                </ContentBlock>
                <ContentBlock>
                    <ContentCard>
                        <ProvidersTable />
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
            <NewProviderModal />
        </ErrorBoundary>
    );
};
